import React from 'react';
import { Text, View, Linking } from 'react-native';
import { Button, Agreements, DefaultImage, ScreenContentScroll, UserIcon } from './ui';
import { signOut } from '../basics/fbutil';
import { watchCurrentUserName, getCurrentUserName, getCurrentUser, releaseWatchers } from './data';
import { NavClickable } from '../components/basics';


export class HeaderProfile extends React.Component {
  state = {name: null}
  componentDidMount() {
    watchCurrentUserName(this, name => this.setState({name}));
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }
  render() {    
    const {name} = this.state;
    if (!name) return null;
    return (
      <NavClickable navAction='account'>
        <UserIcon userName={name} user={getCurrentUser()} size={30} style={{marginHorizontal: 8}} />
      </NavClickable>
    )
  }
}

export class AccountScreen extends React.Component {
  state = {name: null}
  async componentDidMount() {
    const name = await getCurrentUserName();
    this.setState({name});
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }
  render() {
    const {name} = this.state;
    return (
      <ScreenContentScroll statusdark>        
        <View style={{backgroundColor: 'white', padding: 16, alignItems: 'center'}}>
          <DefaultImage colorKey={getCurrentUser()} name={name} size={80} />
          <Text style={{marginTop: 16, fontSize: 30, fontWeight: 'bold'}}>{name || '?'}</Text>
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-around', marginVertical: 16}}>
          <Button onPress={()=>signOut()}>Sign out</Button>        
        </View>
        <Agreements />

      </ScreenContentScroll>
    )
  }
}


