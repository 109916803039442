import React from 'react';
import { StyleSheet, View, Text, Platform, Image } from 'react-native';
import { KeyboardSafeView } from '../components/KeyboardSafeView';
import { BottomFlatList } from '../components/Scrollers';
import { MessageEntryBox } from '../components/MessageEntryBox';
import { releaseWatchers, getCurrentUser, markAppUsed, watchGroupUsers, watchGroupInvited, markUserChatRead, watchUserMessages, watchUserName, addMessage, watchPostReads, getUrlForImage, watchHelpCleared, watchNotifs } from '../slowgroup/data';
import { sortKeysBy, getFirstName } from '../slowgroup/util';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import _ from 'lodash';
import { LoadingScreen, LinkText, DefaultImage, Button, baseBackgroundColor, PostIcon, textColor, unreadColor } from '../slowgroup/ui';
import { FixedTouchable, NavClickable } from '../components/basics';
import { NonMemberGroupScreen } from '../slowgroup/NonMemberGroup';
import { NotifPreview } from './NotifList';
import { formatTimeAgo, filterObject } from '../basics/util';
import { chooseFirstHelpPrompt, Help, HelpUserChoosePosts } from './HelpPrompt';


export class UserHeader extends React.Component {
  state = {name: ''};
  async componentDidMount() {
    const {user} = this.props;
    watchUserName(this, user, name => this.setState({name}))
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }
  render () {
    const {user} = this.props;
    const {name} = this.state;
    // const color = Platform.OS == 'web' ? 'black' : 'white';
    return (
      // <View style={{alignItems: Platform.OS == 'web' ? undefined : 'center'}}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <DefaultImage name={name} colorKey={user} size={30} />
          <Text numberOfLines={1} style={{fontSize: 20, marginLeft: 8}}>
            {name}
          </Text>
        </View>
      // </View>
    )
  } 
}

function fixPronouns(action, name) {
  if (!action) return ''
  const firstName = getFirstName(name);
  return action.replace('your',firstName + "'s").replace('you', firstName);
}

// function NotifMessage({message, messageInfo, userName, inverted=false}) {
//   const mine = messageInfo.from == getCurrentUser();
//   const name = mine ? 'You' : userName;
//   const action = mine ? fixPronouns(_.get(messageInfo,'action'), userName) : _.get(messageInfo,'action');
//   const type = _.get(messageInfo, 'type');
//   const groupAction = (type == 'asktojoin' || type == 'addtogroup');

//   if (type == 'publish' || type == 'likereply' || type == 'publishpost' || type == 'invite' || type == 'addtogroup' || type == 'follow') {
//     return <NavClickable navAction={(messageInfo.post && !groupAction) ? 'post' : 'group'} navProps={{post: messageInfo.post, author: messageInfo.postAuthor, group: messageInfo.group, shownReply: messageInfo.reply}} >
//       <View style={{marginHorizontal: 12, marginVertical: 4, maxWidth: 200}}>
//         <Text numberOfLines={1} style={{color: '#444', fontSize: 12}}>
//           {action} {messageInfo.groupName}
//         </Text>
//         {!messageInfo.text ? null :
//           <Text style={{fontSize: 12, color: '#444'}} numberOfLines={1}>{'"' + messageInfo.text + '"'}</Text>      
//         }
//       </View>
//     </NavClickable>
//   }
//   return (
//     <View style={{flexShrink: 0, width: 250, marginVertical: 4}}>
//       <Text numberOfLines={1} style={{flexShrink: 0, marginLeft: 4, marginBottom: 2, fontSize: 12, color: '#444'}}>
//           {action} {messageInfo.groupName}
//       </Text>
//       <NavClickable navAction={groupAction ? 'group' : 'post'} navProps={{post: messageInfo.post, author: messageInfo.postAuthor, group: messageInfo.group, shownReply: messageInfo.reply}} >
//         <View style={{flexDirection: 'row', flex: 1, alignItems: 'center', backgroundColor: 'white', marginHorizontal: 4, maxWidth: 300}}>
//           <View style={{flex: 1, padding: 8}}>
//             <Text style={{color: '#444'}} numberOfLines={4}>{messageInfo.text}</Text>      
//           </View>
//           <Feather name='chevrons-right' color={inverted ? 'white' : '#666'} size={16} style={{marginRight: 4}} />
//         </View>
//       </NavClickable>
//     </View>
//   )
// }

function ObjectMessage({message, messageInfo, userName, inverted=false, postReads}) {
  // return null;

  const mine = messageInfo.from == getCurrentUser();
  const action = mine ? fixPronouns(_.get(messageInfo,'action'), userName) : _.get(messageInfo,'action');
  const unread = _.get(postReads, messageInfo.post, 0) < messageInfo.time;
  if (messageInfo.reply) {
    return (
      <View style={{flex: 1, maxWidth: 300}}>
        {messageInfo.type == 'reply' ? null : 
          <Text style={{color: '#444', marginBottom: 2}}>
            {action} {messageInfo.groupName}
          </Text>
        }
        <View style={{flex: 1, paddingHorizontal: 8, paddingVertical: 4, backgroundColor: 'white'}}>
          <NavClickable navAction='post' navProps={{post: messageInfo.post, 
              author: messageInfo.postAuthor, group: messageInfo.group, shownReply: messageInfo.reply}}>
            <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
              <Entypo name='reply' size={30} color='#666'/>
              <View style={{flex: 1, marginLeft: 8}}>
                <Text numberOfLines={1} style={{fontWeight: unread ? 'bold' : null}}>{messageInfo.text}</Text>
              </View>
            </View>
          </NavClickable>
        </View>
      </View>    
    )    
  } else if (messageInfo.post && (messageInfo.text || messageInfo.title)) {
    return (
      <View style={{flex: 1, maxWidth: 400}}>
        <Text style={{color: '#444', marginBottom: 2}}>
          {action} {messageInfo.groupName}
        </Text>
        <View style={{flex: 1, padding: 8, backgroundColor: 'white'}}>
          <NavClickable navAction='post' navProps={{post: messageInfo.post, 
              author: messageInfo.postAuthor, group: messageInfo.group, shownReply: messageInfo.reply}}>
            <View style={{flex: 1, flexDirection: 'row', alignItems: 'flex-start'}}>
              <Entypo name='text-document' size={30} color='#666' />

              {/* <PostIcon user={messageInfo.from} groupName={messageInfo.groupName} group={messageInfo.group} userName={userName} size={30} /> */}
              <View style={{flex: 1, marginLeft: 8}}>
                {!(messageInfo.title || !messageInfo.text) ? null : 
                  <Text numberOfLines={2} style={{fontSize: 15, fontWeight: '500'}}>
                    {messageInfo.title || messageInfo.groupName}
                  </Text>
                }
                {messageInfo.image ? 
                  <Image style={{width: 250, height: 250, marginTop: 8}} source={{uri: getUrlForImage({image: messageInfo.image})}}/>
                :
                  <Text numberOfLines={4} style={{color: textColor, marginTop: 2}}>
                    {messageInfo.text}
                  </Text>
                }
              </View>
            </View>
          </NavClickable>
        </View>
      </View>
    )
  } else if (messageInfo.group) {
    return (
      <View style={{flex: 1, maxWidth: 300}}>
        <Text style={{color: '#444', marginBottom: 2}}>
          {action} {messageInfo.groupName}
        </Text>
        <View style={{flex: 1, padding: 8, backgroundColor: 'white'}}>
          <NavClickable navAction='group' navProps={{group: messageInfo.group}}>
            <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
              <DefaultImage name={messageInfo.groupName} size={30} colorKey={messageInfo.group} radiusFactor={4} />
              <View style={{marginLeft: 8}}>
                <Text style={{fontSize: 15, fontWeight: '500'}}>
                  {messageInfo.groupName}
                </Text>
                <Text style={{color: textColor, marginTop: 2}}>
                  {action}
                </Text>
              </View>
            </View>
          </NavClickable>
        </View>
      </View>
    )
  } else {
    return <Text>Message</Text>
  }
}


const sChatMessage = StyleSheet.create({
  themMessage: {
    backgroundColor: 'white',
    borderRadius: 16,
    paddingHorizontal: 12,
    paddingVertical: 6,
    // margin: 4,
    marginLeft: 4,
    maxWidth: 250,
  },
  sameHorizLeft: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginHorizontal: 4,
    marginBottom: 2,
    marginRight: 100,
    // paddingVertical: 8
  },
  horizLeft: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginHorizontal: 4,
    marginBottom: 8,
    marginRight: 50,
    // paddingVertical: 8
  },
  horizRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginHorizontal: 4,
    marginBottom: 8,
    flex: 1,
    marginLeft: 50,
  },
  sameHorizRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginHorizontal: 4,
    marginBottom: 2,
    flex: 1,
    marginLeft: 100
  },
  meMessage: {
    backgroundColor: 'rgb(0, 153, 255)',
    // backgroundColor: '#0084ff',
    borderRadius: 18,
    paddingHorizontal: 12,
    paddingVertical: 6,
    maxWidth: 250,
  },
  meText: {
    color: '#fff',
    fontSize: 16,
  },
  themText: {
    fontSize: 16,
  },
  meTextWeb: {
    color: '#fff',
    fontFamily: 'Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif',
    fontSize: 14,
    lineHeight: 18,
  },
  themTextWeb: {
    color: 'black',
    fontFamily: 'Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif',
    fontSize: 14,
    lineHeight: 18,
  },
  userName: {
    fontSize: 12,
    color: '#666',
    marginLeft: 38,
    marginBottom: 2
  }
})

const hourMillis = 1000 * 60 * 60;
const dateoptions = {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minutes: 'numeric'}
function TimePrompt({messageInfo, prevMessageInfo}) {
  if (messageInfo && prevMessageInfo && messageInfo.time - prevMessageInfo.time > hourMillis) {
    return (
      <View style={{flexDirection: 'row', justifyContent: 'space-around', marginVertical: 16}}>
        <Text style={{color: '#666'}}>{new Date(messageInfo.time).toLocaleDateString('en-US', dateoptions).toUpperCase()}</Text>
      </View>
    )
  } else {
    return null;
  }
}

export function ChatMessage({message, messageInfo, nextMessageInfo, prevMessageInfo, postReads, userName}) {
  const sameUser = _.get(nextMessageInfo, 'from') == messageInfo.from; 
  const sameType = _.get(nextMessageInfo, 'type','') == (messageInfo.type || '');
  const sameBoth = sameUser && sameType && !messageInfo.type;
  if (messageInfo.from == getCurrentUser()) {
    return (
      <View>
        <TimePrompt messageInfo={messageInfo} prevMessageInfo={prevMessageInfo} />
        <View style={sameBoth ? sChatMessage.sameHorizRight : sChatMessage.horizRight} >
          {messageInfo.type ? 
            <ObjectMessage message={message} messageInfo={messageInfo} userName={userName} postReads={postReads} />
          :
            <View style={sChatMessage.meMessage}>
              <LinkText style={Platform.OS == 'web' ? sChatMessage.meTextWeb : sChatMessage.meText} text={messageInfo.text} />
            </View>
          }
        </View>
      </View>
    )
  } else if (messageInfo.type) {
    return (
      <View>
        <TimePrompt messageInfo={messageInfo} prevMessageInfo={prevMessageInfo} />
        <View style={sChatMessage.horizLeft}>
          <ObjectMessage message={message} messageInfo={messageInfo} userName={userName} postReads={postReads} />
        </View>
      </View>
    ) 
  } else {
    return (
      <View>
        <TimePrompt messageInfo={messageInfo} prevMessageInfo={prevMessageInfo} />
        <View>
          <View style={sameBoth ? sChatMessage.sameHorizLeft : sChatMessage.horizLeft}>
            {sameUser ? 
              <View style={{width: 20}} />
             : 
              <DefaultImage name={userName} colorKey={messageInfo.from} size={20} />
            }
            <View style={sChatMessage.themMessage} >
              <LinkText style={Platform.OS == 'web' ? sChatMessage.themTextWeb : sChatMessage.themText} text={messageInfo.text} />
            </View>
          </View>
        </View>
      </View>
    );
  }
}


function MoreMessages({onPress}) {
  return (
    <View style={{flexDirection: 'row', justifyContent: 'space-around', marginVertical: 16}}>
      <FixedTouchable onPress={onPress}>
        <View style={{backgroundColor: 'white', padding: 8, borderRadius: 16}}>
          <Text>
            Show more messages
          </Text>
        </View>
      </FixedTouchable>
    </View>
  )
}


export class UserScreen extends React.Component {
  state = {notifs: null, messages: null, postReads: null, userName: null, replyMessage: null, replyMessageInfo: null, isPrivate: false, count: 32}

  componentDidMount() {
    const {user, replyGroup, replyMessage} = this.props;
    watchUserMessages(this, user, messages => this.setState({messages}))
    watchUserName(this, user, userName => this.setState({userName}))
    watchNotifs(this, notifs => this.setState({notifs}));
    // watchUserChatRead(this, user, readTime => this.setState({readTime});
    markUserChatRead(user);
    watchPostReads(this, postReads => this.setState({postReads}));
    watchHelpCleared(this, cleared => this.setState({cleared}));
    markAppUsed();
  }

  componentWillUnmount(){releaseWatchers(this)}

  render() {
    const {user} = this.props;
    const {cleared, count, notifs, messages, userName, readTime, replyMessage, replyMessageInfo, isPrivate, postReads} = this.state;

    if (notifs == null || messages == null || userName == null || postReads == null || cleared == null) {
      return <LoadingScreen />
    }
    const hasMore = Object.keys(messages).length > count;
    const basicMessages = filterObject(messages, message => !message.type);
    const notifsFromUser = filterObject(notifs, notif => notif.from == user && notif.type);
    const things = {...basicMessages, ...notifsFromUser};

    const sortedThings = sortKeysBy(things, thing=>thing.time).slice(-count);

    const help = chooseFirstHelpPrompt(cleared, [Help.UserChoosePosts]);

    // console.log('ChatScreen', {messages});

    return (
      <KeyboardSafeView style={{flex: 1}}>
      <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
        <View style={{flex: 1, maxWidth: 500, alignItems: 'stretch', backgroundColor: baseBackgroundColor}}>
          <BottomFlatList data={[
            {key: '_spacer', value: () => <View style={{height: 8}} />},
            {key: '_more', value: () => !hasMore ? null :   
              <MoreMessages onPress={() => this.setState({count: count + 64})} />
            },
            ...sortedThings.map((m,k) => ({key: m, value: () => 
             ( messages[m] ?
                <ChatMessage message={m} messageInfo={messages[m]} userName={userName} 
                    prevMessageInfo={messages[sortedThings[k-1]]} 
                    nextMessageInfo={messages[sortedThings[k+1]]} postReads={postReads}
                />
                :
                <NotifPreview notif={m} notifInfo={notifs[m]} inChat />
              )
              // <Text>{_.get(messages, [m,'text'])}</Text>
            })),
          ]} />
          <MessageEntryBox part='entry' user={user} userName={userName} help={help}
              replyMessageInfo={replyMessageInfo}
              isPrivate={isPrivate} onAllowed={allowed => this.setState({isPrivate: !allowed})} 
              publisherName={userName}
              onTextEntered={text => addMessage({user, text, replyMessage, replyMessageInfo})} 
              placeholder='Write a private message' />
        </View>
      </View>
    </KeyboardSafeView>
    )
  }
}

