import React from 'react';
import { TouchableOpacity, Text, View, Platform, SafeAreaView, StyleSheet, ScrollView, FlatList} from 'react-native';
import { reloadIfVersionChanged } from '../basics/versioncheck';
import { watchMyGroups, releaseWatchers, watchAllUserNames, watchLastMessages, watchAllUserChatReads, watchPostFollows, watchPostReads, getCurrentUser, watchPostLastPrivate, watchGroupLastPrivate, watchAllGroupsLastPrivate, watchHelpCleared, watchNotifs } from './data';
import { LoadingScreen, DefaultImage, Button, HelpBubble, HeaderProfile, HeaderProfileBare, NotifsBanner, PostIcon, GroupIcon, UserIcon, baseColor, KeyboardLessScreenContentFlatList } from './ui';
import { sortKeysBy, doesTextMatchPrefix, filterObject } from './util';
import _ from 'lodash';
import { Notifications } from 'expo';
import { NotifPromo, EnableNotifsBanner } from './NotifPermission';
import { NavClickable, FixedTouchable } from '../components/basics';
import { formatTimeAgo } from '../basics/util';
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { unreadColor, ScreenContentFlatList } from './ui';
import { HelpSearchPeople, chooseFirstHelpPrompt, Help, HelpWelcome, HelpWritePost } from './HelpPrompt';
import { SearchBox } from '../components/SearchBox';
import Modal from 'react-native-modal';
import { ModalButton } from '../components/ActionMenu';
import { Catcher } from '../basics/catcher';

export function MiniHeader() {
  const androidPadding = (Platform.OS == 'android' ? 25 : 0);
  const extraPadding = (Platform.OS == 'ios' && parseInt(Platform.Version) < 11) ? 16 : androidPadding;
  console.log('padding: ', extraPadding);
  return (
    <SafeAreaView style={{backgroundColor: 'white', height: extraPadding}}>      
    </SafeAreaView>
  )
}

export function GroupListHeader({navigation}) {
  const androidPadding = (Platform.OS == 'android' ? 25 : 0);
  const extraPadding = (Platform.OS == 'ios' && parseInt(Platform.Version) < 11) ? 16 : androidPadding;
  return (
    <SafeAreaView style={{backgroundColor: 'white'}}>
      <View >
        <View style={{backgroundColor: 'white', flexDirection: 'row', 
            justifyContent: 'space-between', alignItems: 'center', paddingTop: 12 + extraPadding, paddingBottom: 8}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', flex: 1, alignItems: 'center'}}>
            <Text numberOfLines={1} textAlign='left' style={{marginLeft: 16, fontWeight: 'bold', fontSize: 30}}>
              Groups
            </Text>
            <HeaderProfileBare navigation={navigation}/>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

export function PeopleListHeader({navigation}) {
  const androidPadding = (Platform.OS == 'android' ? 25 : 0);
  const extraPadding = (Platform.OS == 'ios' && parseInt(Platform.Version) < 11) ? 16 : androidPadding;
  return (
    <SafeAreaView style={{backgroundColor: 'white'}}>
      <View>
        <View style={{backgroundColor: 'white', flexDirection: 'row', 
            justifyContent: 'space-between', alignItems: 'center', paddingTop: 12 + extraPadding, paddingBottom: 8}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', flex: 1, alignItems: 'center'}}>
            <Text numberOfLines={1} textAlign='left' style={{marginLeft: 16, fontWeight: 'bold', fontSize: 30}}>
              People
            </Text>
            {/* <HeaderProfileBare navigation={navigation}/> */}
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}



export function FollowedPostPreview({post, postFollow, postLast, postReadTime = 0}) {
  const unread = !(postReadTime >= _.get(postFollow, 'time', 0)) || _.get(postLast,'unread');
  const toShow = ((postFollow.time || 0) > _.get(postLast,'time', -1)) ? postFollow : _.get(postLast,'replyInfo');
  return (
    <View style={{flex: 1, paddingHorizontal: 16, paddingVertical: 8, 
        backgroundColor: unread ? unreadColor : 'white'}} >
      <NavClickable navAction='post' navProps={{collapsed: true, author: postFollow.user, group:postFollow.group, post}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          <PostIcon user={postFollow.user} userName={postFollow.userName} group={postFollow.group} groupName={postFollow.groupName} />
          <View style={{flex: 1, marginLeft: 10}}>
            {/* <Text numberOfLines={1}> */}
            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
              <Text numberOfLines={1} style={{fontSize: 16, color: unread ? 'black' : '#666', fontWeight: unread ? 'bold' : undefined}}>
                {postFollow.title || (postFollow.userName +  ' in ' + postFollow.groupName)} </Text>
              <Text style={{flexShrink: 0, color: 'rgb(101, 119, 134)', fontSize: 12}}>
                {' - '}{formatTimeAgo(Math.max(postFollow.time || 0, _.get(postLast,'time',0)), 'twitter')} 
              </Text>
            </View>
            {/* </Text> */}
            <Text style={{color: unread ? 'black' : '#666', fontWeight: unread ? '500' : undefined, marginTop: 4}} numberOfLines={1}>
              {!toShow.name ? null : toShow.name + ': '}
              {_.get(toShow,'text')}
            </Text>
          </View>
        </View>
      </NavClickable>
    </View>
  )
}

function UserPreview({user, userName, userLast, userReadTime = 0}) {
  const unread = userLast && !(userReadTime > _.get(userLast, 'time', 0));
  return (
    <View style={{flex: 1, paddingHorizontal: 16, paddingVertical: 8, 
        backgroundColor: unread ? unreadColor : 'white'}} >
      <NavClickable navAction='user' navProps={{user}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          <UserIcon userName={userName} user={user} size={50} />
          <View style={{flex: 1, marginLeft: 10}}>
            <Text>
              <Text style={{fontSize: 16, fontWeight: unread ? 'bold' : undefined, color: unread ? 'black' : '#666'}}>
                {userName} 
              </Text>
              {!userLast ? null :
                <Text style={{color: 'rgb(101, 119, 134)', fontSize: 12}}>
                  - {formatTimeAgo(userLast.time, 'twitter')} 
                </Text>
              }
            </Text>
            <Text style={{color: unread ? 'black' : '#666', fontWeight: unread ? '500' : undefined, marginTop: 4}} numberOfLines={1}>
              {(userLast && (userLast.from == getCurrentUser())) ? 'You: ' : null}
              {(userLast && userLast.action) ? (userLast.action + ' ' + 
                ((userLast.group ? (userLast.groupName || '') : ''))) : null}
              {userLast && userLast.action && userLast.text ? ':' : ''}
              {_.get(userLast,'text')}
            </Text>
          </View>
        </View>
      </NavClickable>
    </View>
  ) 
}

export function GroupSummary({group, groupInfo}) {
  const {name, intro} = groupInfo;
  return (
    <View style={{flex: 1, paddingHorizontal: 16, paddingVertical: 8, 
        backgroundColor: 'white'}} >
      <NavClickable navAction='group' navProps={{group}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          <GroupIcon groupName={name} group={group} size={50} />
          <View style={{flex: 1, marginLeft: 10}}>
            <Text>
              <Text style={{fontSize: 16, fontWeight: '500'}}>{name} </Text>
            </Text>
            <Text style={{color: '#666', marginTop: 4}} numberOfLines={1}>
              {intro}
            </Text>              
          </View>
        </View>
      </NavClickable>
    </View>
  ) 
}


export function GroupPreview({group, groupStatus, groupLast}) {
  const {name, lastTime = 0, lastUserName, lastText, lastRead = 0, lastChat} = groupStatus;
  const privateTime = _.get(groupLast, 'time', 0);
  const maxTime = Math.max(lastTime, privateTime);
  const unread = !(lastRead >= lastTime) || _.get(groupLast,'unread');
  const showPrivate = privateTime > lastTime;
  const privateText = _.get(groupLast, ['messageInfo', 'text']);

  return (
    <View style={{flex: 1, paddingHorizontal: 16, paddingVertical: 8, 
        backgroundColor: unread ? unreadColor : 'white'}} >
      <NavClickable navAction='group' navProps={{group, member:(!groupStatus.requested)}}>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          <GroupIcon groupName={name} group={group} size={50} />
          <View style={{flex: 1, marginLeft: 10}}>
            <Text>
              <Text style={{fontSize: 16, fontWeight: unread ? 'bold' : undefined, color: unread ? 'black' : '#666'}}>
                {name} 
              </Text>
              <Text style={{color: 'rgb(101, 119, 134)', fontSize: 12}}>
               {' - ' + formatTimeAgo(maxTime, 'twitter')} 
              </Text>              
            </Text>
            {showPrivate ?
              <Text style={{color: unread ? 'black' : '#666', fontWeight: unread ? '500' : undefined, marginTop: 4}} numberOfLines={1}>
                {_.get(groupLast,['messageInfo', 'name'])}: {privateText && privateText.trim()}
              </Text>                  
            : 
              <Text style={{color: unread ? 'black' : '#666', fontWeight: unread ? '500' : undefined, marginTop: 4}} numberOfLines={1}>
                {lastUserName}: {lastText && lastText.trim()}
              </Text>        
            }      
            {/* } */}
          </View>
        </View>
      </NavClickable>
    </View>
  ) 
}

function NewThingButtons() {
  return (
    <View style={{flexDirection: 'row', margin: 8, justifyContent: 'flex-end'}}>
      <Button navAction='newGroup'>New Group</Button>
      <Button navAction='newPost' style={{marginLeft: 8}}>New Post</Button>

    </View>
  )
}

function JoinInvitedGroupPromo() {
  return (
    <HelpBubble style={{marginBottom: 10}}
      title='Did someone give you a link to a group?'
    >
      <Text>
        Go back and click on that link to join the group in Talkful.
      </Text>
    </HelpBubble>
  )
}

// function ThingPreview({thing, groups, userNames, userLasts, userReads, followedPosts, postReads}) {
//   if (groups[thing]) {
//     return <GroupPreview ({group: thing, groupStatus: groups[thing]})
//   } else if (followedPosts[thing]) {
//     return FollowedPostPreview({post: thing, postFollow: followedPosts[thing], postReadTime: postReads[thing]});
//   } else {
//     return UserPreview({user: thing, userName: userNames[thing], 
//       userReadTime: userReads[thing], userLast: userLasts[thing]});
//   }
// }

function getThingTime(thing, key, postLasts, groupLasts, userNotifTimes = {}) {
  // console.log('thingTime', key, thing);
  // console.log('nt', userNotifTimes[key]);
  return _.max([thing.time || 0, thing.lastTime || 0, _.get(userNotifTimes, key, 0), _.get(postLasts,[key,'time']), _.get(groupLasts,[key,'time'])]);
}

function getThingName({thing, userNames, followedPosts, groups}) {
  return userNames[thing] || _.get(followedPosts,[thing,'title']) || _.get(groups,[thing, 'name']) || '?'
}

function IconForThing({thing, userNames, followedPosts, groups}) {
  const name = getThingName({thing, userNames, followedPosts, groups});
  if (userNames[thing]) {
    return <UserIcon size={50} user={thing} userName={name} style={{margin: 8}} />
  } else if (groups[thing]) {
    return <GroupIcon size={50} group={thing} groupName={name} style={{margin: 8}} />
  } else {
    return null;
  }
}

function RecomendBar({recommendThings, userNames, followedPosts, groups}) {
  if (recommendThings.length < 3) {
    return null;
  }
  return (
    <ScrollView horizontal style={{marginBottom: 8, height: 100}}>
      <View style={{width: 16}} />
      {recommendThings.map(t => 
        <NavClickable key={t} navAction={userNames[t] ? 'user' : 'group'} navProps={{[(userNames[t] ? 'user' : 'group')]: t}} > 
          <View key={t} style={{alignItems: 'center', marginHorizontal: 2}}>
            <IconForThing thing={t} userNames={userNames} followedPosts={followedPosts} groups={groups} />
            <Text numberOfLines={2} style={{width: 60, textAlign: 'center', fontSize: 10, color: '#666'}}>{getThingName({thing: t, userNames, followedPosts, groups})}</Text>
          </View>
        </NavClickable>
      )}
    </ScrollView>
  )
}


class NewThingButton extends React.Component {
  state = {showModal: false}
  onShowModal() {
    this.setState({showModal: true});
  }
  render() {
    const {showModal} = this.state;

    return (
      // <View style={{zIndex: 1}}>
      <View>
        <View style={{position: 'absolute', right: 16, bottom: 16, zIndex: 1}}>
          <TouchableOpacity onPress={()=>this.onShowModal()} style={{zIndex: 1}}>
            <View style={{
                width: 60, height: 60, borderRadius: 50, backgroundColor: 'white', 
                shadowColor: 'gray',
                shadowOffset: {width: 0, height: 4},
                shadowOpacity: 0.8,
                shadowRadius: 4,
                alignItems: 'center', justifyContent: 'center'}}>
              <Entypo name='plus' size={45} color={baseColor} zIndex={1} style={{zIndex: 1}}/>
            </View>
          </TouchableOpacity>
        </View>
        <Modal style={{justifyContent: 'flex-end'}} 
            isVisible={showModal} onBackdropPress={()=>this.setState({showModal: false})}>
          <ModalButton title='New Group' navAction='newGroup' onNav={()=>this.setState({showModal: false})} />
          <ModalButton title='New Post' navAction='newPost' onNav={()=>this.setState({showModal: false})} />
          <ModalButton title='Cancel' style={{marginTop: 16}} onPress={()=>this.setState({showModal: false})} />
        </Modal>
      </View>
    )
  }
}



// const weekMillis = 1000 * 60 * 60 * 24 * 7;
const dayMillis = 1000 * 60 * 60 * 24;

export var global_clearsearch_callback = null;

export class GroupList extends React.Component {
  state = {help: null, groups: null, groupLasts: null, postLasts: null, people: null, search: null, userNames: {}, userLasts: null, userReads: null, followedPosts: null, postReads: null}

  async componentDidMount() {
    reloadIfVersionChanged();
    // console.log('mounted - loading');
    watchMyGroups(this, groups => this.setState({groups}))
    watchAllUserNames(this, userNames => this.setState({userNames}));
    watchLastMessages(this, userLasts => this.setState({userLasts}));
    watchAllUserChatReads(this, userReads => this.setState({userReads}));
    watchPostFollows(this, followedPosts => this.setState({followedPosts}));
    watchPostReads(this, postReads => this.setState({postReads}));
    watchPostLastPrivate(this, postLasts => this.setState({postLasts}));
    watchAllGroupsLastPrivate(this, groupLasts => this.setState({groupLasts}));
    watchHelpCleared(this, cleared => this.setState({cleared}));

    global_clearsearch_callback = () => this.setState({search:null});
  }
  componentWillUnmount() {releaseWatchers(this)}

  getRandomRecs() {
    const {postLasts, userLasts, groups} = this.state;
    // const things = {...userLasts, ...groups};
    const things = groups;
    if (!this.randomRecs) {
      const filteredOld = filterObject(things, (t,k) => k != getCurrentUser() && getThingTime(t,k,postLasts) < (Date.now() - dayMillis))
      this.randomRecs = _.sortBy(Object.keys(filteredOld), () => Math.random());
    }
    return this.randomRecs;
  }

  thingPreview(thing) {
    const {postLasts, groupLasts, groups, userNames, userLasts, userReads, followedPosts, postReads} = this.state;
    if (groups[thing]) {
      return <GroupPreview group={thing} groupStatus={groups[thing]} groupLast={_.get(groupLasts,thing)} />
    } else if (followedPosts[thing]) {
      return <FollowedPostPreview post={thing} postLast={_.get(postLasts,thing)} 
                postFollow={followedPosts[thing]} postReadTime={postReads[thing]} />
    } else {
      return <UserPreview user={thing} userName={userNames[thing]} 
        userReadTime={userReads[thing]} userLast={userLasts[thing]} />
    }
  }

  render () {
    const {cleared, groupLasts, postLasts, groups, userNames, search, userLasts, userReads, followedPosts, postReads} = this.state;

    if (cleared == null || groupLasts == null || postLasts == null || groups == null || userLasts == null || userReads == null || followedPosts == null || postReads == null) return <LoadingScreen />
    var shownThings;
    if (search) {
      // const myThings = {...groups, ...userLasts, ...followedPosts};
      // const myThings = groups;
      const myThings = {...groups, ...followedPosts};
      const filteredMyThings = filterObject(myThings, (t,k) => doesTextMatchPrefix(_.get(userNames, k) || t.title || t.name, search, k));
      const sortedMyThings = sortKeysBy(filteredMyThings || {}, (t,k) => getThingTime(t,k,postLasts,groupLasts)).reverse();
      const filteredAllUsers = filterObject(userNames, (u,k) => doesTextMatchPrefix(u, search) && !userLasts[k]);
      const sortedAllUsers = sortKeysBy(filteredAllUsers || {}, u => userNames[u]);

      shownThings = [...sortedMyThings, ...sortedAllUsers];
      // console.log('search', {myThings, filteredMyThings, sortedMyThings, shownThings, userNames});
    } else {
      // const things = {...groups, ...userLasts, ...followedPosts}
      const things = groups;
      // const sortedThings = sortKeysBy(things, g => (_.max([g.time || 0, g.lastTime || 0, g.lastRead || 0, _.get(g.lastChat, 'time',0)]))).reverse();
      // const sortedThings = sortKeysBy(things, t => Math.max(t.time || 0, t.lastTime || 0)).reverse();
      shownThings = sortKeysBy(things, (t,k) => getThingTime(t,k,postLasts, groupLasts)).reverse();
      // shownThings = sortedThings; 
    }

    const recomendThings = this.getRandomRecs(); 

    const unreadGroups = Object.keys(groups).filter(g => (groups[g].lastRead || 0) < getThingTime(groups[g], g, postLasts, groupLasts)); 
    const unreadPeople = Object.keys(userLasts).filter(u => userLasts[u].time > userReads[u]);
    const unreadPosts = Object.keys(followedPosts).filter(p => _.get(postLasts,[p,'unread']));
    // console.log('unread', {groups, userLasts, userReads, unreadPeople, unreadGroups, unreadPosts});
    // if (Platform.OS != 'web') {
    //   Notifications.setBadgeNumberAsync(unreadGroups.length + unreadPeople.length + unreadPosts.length);
    // }  

    const help = chooseFirstHelpPrompt(cleared, [Help.Welcome, Help.SearchPeople /*, Help.WritePost */]);
    // console.log('help', help);

    // console.log('Groups shownThings', shownThings);


    return (
      <View style={{flex: 1}}>
        <KeyboardLessScreenContentFlatList statusdark style={{backgroundColor: 'white'}} data={[
          // {key: '_notifpromo', value: () => <NotifPromo />},
          {key: '_newgroup', value: () => Platform.OS == 'web' ? <NewThingButtons /> : null},
          {key: '_welcome', value: () => <HelpWelcome help={help} />},
          // {key: '_helpwrite', value: () => <HelpWritePost help={help} />},
          {key: '_notifbanner', value: () => <EnableNotifsBanner />},
          {key: '_search', value: () => 
              <SearchBox part='search' help={help} search={search} onChangeText={search => this.setState({search})} placeholder='Search' />},
          {key: '_recommend', value: () => search ? null :
            <RecomendBar recommendThings={recomendThings} userNames={userNames} followedPosts={followedPosts} groups={groups} />
          },
          // {key: '_joinpromo', value: () => Object.keys(groups).length <= 2 ? <JoinInvitedGroupPromo /> : null},
          ...shownThings.map(t => ({key: t, value: () => 
            this.thingPreview(t)
            // <ThingPreview thing={t} groups={groups} userNames={userNames} userLasts={userLasts} userReads={userReads} followedPosts={followedPosts} postReads={postReads} />
          })),
          // {key: 'newpromo', value: () => <JoinInvitedGroupPromo() />}
        ]} />
        {Platform.OS == 'web' ? null : <NewThingButton />}
      </View>
    )
  }
}


function getLastUserNotifTimes({notifs}) {
  var userNotifTimes = {};
  Object.keys(notifs).forEach(n => {
    const notifInfo = notifs[n];
    const lastTime = userNotifTimes[notifInfo.from] || 0;
    if (lastTime < notifInfo.time) {
      userNotifTimes[notifInfo.from] = notifInfo.time;
    }
  })
  return userNotifTimes;
}

function basicRenderItem ({item: {key, item, value}}) {
  return <Catcher>{item || value()}</Catcher>;
}

export class PeopleList extends React.Component {
  state = {notifs: null, help: null, groups: null, groupLasts: null, postLasts: null, people: null, search: null, userNames: {}, userLasts: null, userReads: null, followedPosts: null, postReads: null}

  async componentDidMount() {
    reloadIfVersionChanged();
    // console.log('mounted - loading');
    watchNotifs(this, notifs => this.setState({notifs}));
    watchMyGroups(this, groups => this.setState({groups}))
    watchAllUserNames(this, userNames => this.setState({userNames}));
    watchLastMessages(this, userLasts => this.setState({userLasts}));
    watchAllUserChatReads(this, userReads => this.setState({userReads}));
    watchPostFollows(this, followedPosts => this.setState({followedPosts}));
    watchPostReads(this, postReads => this.setState({postReads}));
    watchPostLastPrivate(this, postLasts => this.setState({postLasts}));
    watchAllGroupsLastPrivate(this, groupLasts => this.setState({groupLasts}));
    watchHelpCleared(this, cleared => this.setState({cleared}));

    global_clearsearch_callback = () => this.setState({search:null});

    if (Platform.OS != 'web') {
      this.props.navigation.addListener ('willFocus', () => {
        console.log('showing people list - forcing re-render');
        this.setState({date: Date.now()})
      });
    }
  }
  componentWillUnmount() {releaseWatchers(this)}

  getRandomRecs() {
    const {postLasts, userLasts, groups} = this.state;
    const things = {...userLasts};
    // const things = groups;
    if (!this.randomRecs) {
      const filteredOld = filterObject(things, (t,k) => k != getCurrentUser() && getThingTime(t,k,postLasts) < (Date.now() - dayMillis))
      this.randomRecs = _.sortBy(Object.keys(filteredOld), () => Math.random());
    }
    return this.randomRecs;
  }

  thingPreview(thing) {
    const {postLasts, groupLasts, groups, userNames, userLasts, userReads, followedPosts, postReads} = this.state;
    if (groups[thing]) {
      return <GroupPreview group={thing} groupStatus={groups[thing]} groupLast={_.get(groupLasts,thing)} />
    } else if (followedPosts[thing]) {
      return <FollowedPostPreview post={thing} postLast={_.get(postLasts,thing)} 
                postFollow={followedPosts[thing]} postReadTime={postReads[thing]} />
    } else {
      return <UserPreview user={thing} userName={userNames[thing]} 
        userReadTime={userReads[thing]} userLast={userLasts[thing]} />
    }
  }

  render () {
    console.log('render users');

    const {notifs, cleared, groupLasts, postLasts, groups, userNames, search, userLasts, userReads, followedPosts, postReads} = this.state;

    if (notifs == null || cleared == null || groupLasts == null || postLasts == null || groups == null || userLasts == null || userReads == null || followedPosts == null || postReads == null) return <LoadingScreen />
    var shownThings;
    const userNotifTimes = getLastUserNotifTimes({notifs});
    // console.log('userNotifTimes', userNotifTimes);
    if (search) {
      // const myThings = {...groups, ...userLasts, ...followedPosts};
      // const myThings = groups;
      const myThings = {...userLasts};
      const filteredMyThings = filterObject(myThings, (t,k) => doesTextMatchPrefix(_.get(userNames, k) || t.title || t.name, search, k));
      const sortedMyThings = sortKeysBy(filteredMyThings || {}, (t,k) => getThingTime(t,k,postLasts,groupLasts, userNotifTimes)).reverse();
      const filteredAllUsers = filterObject(userNames, (u,k) => doesTextMatchPrefix(u, search) && !userLasts[k]);
      const sortedAllUsers = sortKeysBy(filteredAllUsers || {}, u => userNames[u]);

      shownThings = [...sortedMyThings, ...sortedAllUsers];
      // console.log('search', {myThings, filteredMyThings, sortedMyThings, shownThings, userNames});
    } else {
      // const things = {...groups, ...userLasts, ...followedPosts}
      const things = userLasts;
      // const sortedThings = sortKeysBy(things, g => (_.max([g.time || 0, g.lastTime || 0, g.lastRead || 0, _.get(g.lastChat, 'time',0)]))).reverse();
      // const sortedThings = sortKeysBy(things, t => Math.max(t.time || 0, t.lastTime || 0)).reverse();
      shownThings = sortKeysBy(things, (t,k) => getThingTime(t,k,postLasts, groupLasts, userNotifTimes)).reverse();
      // shownThings = sortedThings; 
    }

    // console.log('Users shownThings', shownThings);

    const recomendThings = this.getRandomRecs(); 

    // const unreadGroups = Object.keys(groups).filter(g => (groups[g].lastRead || 0) < getThingTime(groups[g], g, postLasts, groupLasts)); 
    // const unreadPeople = Object.keys(userLasts).filter(u => userLasts[u].time > userReads[u]);
    // const unreadPosts = Object.keys(followedPosts).filter(p => _.get(postLasts,[p,'unread']));
    // console.log('unread', {groups, userLasts, userReads, unreadPeople, unreadGroups, unreadPosts});
    // if (Platform.OS != 'web') {
    //   Notifications.setBadgeNumberAsync(unreadGroups.length + unreadPeople.length + unreadPosts.length);
    // }  

    // const help = chooseFirstHelpPrompt(cleared, [Help.Welcome, Help.SearchPeople /*, Help.WritePost */]);
    // console.log('help', help);

    return (
      <View style={{flex: 1}}>
        <KeyboardLessScreenContentFlatList key='people' statusdark style={{backgroundColor: 'white'}} data={[
          // {key: '_notifpromo', value: () => <NotifPromo />},
          {key: '_newgroup', value: () => Platform.OS == 'web' ? <NewThingButtons /> : null},
          // {key: '_welcome', value: () => <HelpWelcome help={help} />},
          // {key: '_helpwrite', value: () => <HelpWritePost help={help} />},
          {key: '_notifbanner', value: () => <EnableNotifsBanner />},
          {key: '_search', value: () => 
              <SearchBox part='search' search={search} onChangeText={search => this.setState({search})} placeholder='Search' />},
          {key: '_recommend', value: () => search ? null :
            <RecomendBar recommendThings={recomendThings} userNames={userNames} followedPosts={followedPosts} groups={groups} />
          },
          // {key: '_joinpromo', value: () => Object.keys(groups).length <= 2 ? <JoinInvitedGroupPromo /> : null},
          ...shownThings.map(t => ({key: t, value: () => 
            this.thingPreview(t)
            // <ThingPreview thing={t} groups={groups} userNames={userNames} userLasts={userLasts} userReads={userReads} followedPosts={followedPosts} postReads={postReads} />
          })),
          // {key: 'newpromo', value: () => <JoinInvitedGroupPromo() />}
        ]} />
      </View>
    )
  }
}

