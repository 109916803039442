import React from 'react';
import { GroupList, GroupListHeader, PeopleList, PeopleListHeader, MiniHeader } from "./GroupList";
import { nameColor } from "./ui";
import Ionicons from 'react-native-vector-icons/Ionicons';
import { GroupScreen, GroupHeader, ResponseHeader, GroupRedirectScreen } from './Group';
import { SignInScreen } from './Signin';
import { NewPostHeader, NewPostButton, EditPostScreen, EditReplyScreen, PostButton, EditActionRightHeader, CancelActionLeftHeader } from './NewPost';
import { ReportAbuseScreen } from './ReportAbuse';
import { AccountScreen, HeaderProfile } from './Account';
import { NewGroupScreen } from './NewGroup';
import { InviteScreen, InviteHeader } from './Invite';
import { SetNameScreen } from './SetName';
import { GroupInfoScreen, GroupInfoHeader } from './GroupInfo';
import { TagPeopleScreen } from './TagPeople';
import { EnableNotifsScreen } from './NotifPermission';
import { TalkTree, TalkTreeHeader } from './TalkTree';
import { WebScreen, SafariButton } from './Web';
import { NotifListScreen, NotifListHeader, NotifCountIcon, PeopleCountIcon } from './NotifList';
import { UserScreen, UserHeader } from './User';
import { ChoosePostsScreen } from './ChoosePosts';
import { ImageScreen } from './Image';
import { LogViewScreen } from './LogView';

/* eslint-disable react/display-name */

export const screens = {
  account: {
    screen: AccountScreen,
    title: () => 'Your Account'
  },
  home: {
    screen: GroupList,
    headerTitle: GroupListHeader,
    // headerRight: () => <HeaderProfile />,
    // header: () => <MiniHeader />,
    // title: () => 'Groups',
    bigHead: true,
    // header: navigation => <GroupListHeader navigation={navigation} />
    headerStyle: {
      borderBottomWidth: 0,
      height: 60,
    }
  },
  feed: {
    screen: GroupList,
    headerTitle: GroupListHeader,
    // headerLeft: () => <HeaderProfile />,
  },
  people: {
    screen: PeopleList,
    // title: () => 'People',
    headerTitle: navigation => <PeopleListHeader navigation={navigation} />,
    // headerRight: () => <HeaderProfile />,
    headerStyle: {
      borderBottomWidth: 0,
      height: 60,
    }
  },
  group: {
    screen: GroupRedirectScreen,
    headerTitle: GroupHeader,
    headerRight: ({group}) => <NewPostButton group={group} />,
    // background: ({group}) => nameColor({name: group}),
    path: 'group/:group'
  },
  user: {
    screen: UserScreen,
    headerTitle: UserHeader,
    actionMenu: true,
    path: 'user/:user'
  },
  groupInfo: {
    screen: GroupInfoScreen,
    headerTitle: GroupInfoHeader,
    // background: ({group}) => nameColor({name: group}),
    actionMenu: true
  },
  tagPeople: {
    screen: TagPeopleScreen,
    title: () => 'Share Post',
    // background: ({group}) => nameColor({name: group}) 
  },
  choosePosts: {
    screen: ChoosePostsScreen,
    title: () => 'Choose Posts'
  },
  reportAbuse: {
    screen: ReportAbuseScreen,
    title: () => 'Report Abuse'
  },
  newPost: {
    screen: EditPostScreen,
    // title: () => 'New Post',
    headerTitle: NewPostHeader,
    noSwipe: true,
    headerRight: () => <EditActionRightHeader />,
    headerLeft: ({navigation}) => <CancelActionLeftHeader navigation={navigation} />,


    // background: ({group}) => nameColor({name: group}),
    // headerRight: ({post}) => <PostButton post={post} />
  },
  // choosePostGroup: {
  //   screen: ChoosePostGroupScreen,
  //   title: () => 'Choose a Group'
  // },
  editPost: {
    screen: EditPostScreen,
    title: () => 'Edit Post',
    noSwipe: true,
    headerRight: ({post}) => <EditActionRightHeader update={post} />,
    headerLeft: ({navigation}) => <CancelActionLeftHeader navigation={navigation} edit />,

    // background: ({group}) => nameColor({name: group}),
    // headerRight: ({post}) => <PostButton post={post} />
  },
  editReply: {
    screen: EditReplyScreen,
    title: () => 'Edit Reply',
    noSwipe: true,
    headerRight: ({reply}) => <EditActionRightHeader update={reply} />,
    // background: ({group}) => nameColor({name: group})
    headerLeft: ({navigation}) => <CancelActionLeftHeader navigation={navigation} edit reply />,

  },
  image: {
    screen: ImageScreen,
    title: () => 'Image',
    background: () => 'black'
  },
  newGroup: {
    screen: NewGroupScreen,
    title: () => 'New Group',
    headerLeft: ({navigation}) => <CancelActionLeftHeader navigation={navigation} group />,
  },
  editGroup: {
    screen: NewGroupScreen,
    title: () => 'Edit Group',
    headerLeft: ({navigation}) => <CancelActionLeftHeader navigation={navigation} edit group />,
  },
  signin: {
    screen: SignInScreen,
    title: () => 'Sign In'
  },
  post: {
    screen: TalkTree, 
    headerTitle: TalkTreeHeader,
    actionMenu: true,
    // background: ({group}) => nameColor({name: group})
  },
  newbroadcasts: {
    screen: TalkTree,
    headerTitle: TalkTreeHeader,
    background: ({group}) => nameColor({name: group})
  },
  broadcasts: {
    screen: TalkTree,
    headerTitle: TalkTreeHeader,
    background: ({group}) => nameColor({name: group})
  },
  enableNotifs: {
    screen: EnableNotifsScreen,
    title: () => 'Enable Notifications'
  },
  setname: {
    screen: SetNameScreen,
    title: () => 'Welcome'
  },
  groupInvite: {
    screen: InviteScreen,
    headerTitle: InviteHeader,
    background: ({group}) => nameColor({name: group})
  },
  web: {
    screen: WebScreen,
    title: ({title}) => title || 'Web Page',
    headerRight: ({uri}) => <SafariButton uri={uri} />
  },
  notifs: { 
    screen: NotifListScreen,
    // title: () => 'Notifications'
    header: navigation => <NotifListHeader navigation={navigation} />,
    headerStyle: {
      borderBottomWidth: 0,
      height: 60,
    }

  },
  adminLogs: {
    screen: LogViewScreen,
    title: () => 'Logs'
  }
}

export const tabs = [
  {
    route: 'Groups',
    screen: 'home',
    name: 'Groups',
    icon: tintColor => <Ionicons name='ios-home' size={25} color={tintColor} />
  },
  {
    route: 'People',
    screen: 'people',
    name: 'People',
    icon: tintColor => <PeopleCountIcon tintColor={tintColor} />
    // icon: tintColor => <Ionicons name='ios-mail' size={25} color={tintColor} />
  },
  {
    route: 'Notifs',
    screen: 'notifs',
    name: 'Notifs',
    icon: tintColor => <NotifCountIcon tintColor={tintColor} />
  }
]
