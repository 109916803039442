import React from 'react';

var webNavigation = null;
export function setWebNavigator(navigation) {
  webNavigation = navigation;
}

/* eslint-disable react/display-name */
export function withNav(Component) { 
  return props => <Component {...props} navigation={webNavigation} />   
}
