import React from 'react';
import { Text, View, Platform } from 'react-native';
import { defaultBlockParse } from 'simple-markdown';
import { withNav } from '../basics/provider';


const urlExpr = /((http:\/\/)|(https:\/\/))?[-a-zA-Z0-9.-]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
// const markLinkExpr = /(\[.*\])


// const linkeColor = 'rgb(29,161,242)'
const linkColor = 'rgb(50, 104, 145)'


function MDLinkWithoutNav({title, url, children, inverted, navigation, safari=false}) {
  if (Platform.OS == 'web') {
    return <a target='_blank' style={{color: inverted ? 'white' : linkColor}} rel='noopener noreferrer' href={url}>{children}</a>
  } else {
    return (
      <Text style={{color: inverted ? 'white' : linkColor, textDecorationLine: inverted ? 'underline' : undefined}} onPress={()=>navigation.push('web', {title, uri: url})}>{children}</Text>
    )
  }
}
export const MDLink = withNav(MDLinkWithoutNav)



export function OldRobMarkdown({text, style}) {
  const lines = text.split('\n');
  return (
    <Text style={style}>
      {lines.map((line,k) => <Text key={k}>{line + '\n'}</Text>)}
    </Text> 
  )
}

function mdRenderItem(nodes, style=null, inverted) {
  return <Text>{nodes.map((node,k2) => mdRender(node,k2,true, style, inverted))} {'\n\n'}</Text>
  
  // return <Text>Item: {JSON.stringify(item[0])}</Text>
}

function mdRender(node,k, inline=true, style=null, inverted, topLevel = false) {
  switch (node.type) {
    case 'text': return <Text key={k} style={style}>{node.content}</Text>
    case 'strong': return <Text key={k} url={node.target}>{node.content.map((node,k2) => 
      mdRender(node,k2,true, {...style, fontWeight: 'bold'}, inverted))
    }</Text>
    case 'em': return <Text key={k} url={node.target}>{node.content.map((node,k2) => 
      mdRender(node,k2,true, {...style, fontStyle: 'italic'}, inverted))
    }</Text>
    case 'paragraph': return <Text key={k}>{node.content.map((node,k2) => mdRender(node,k2,true, style, inverted))} {topLevel ? '\n\n' : null}</Text>
    case 'blockQuote': 
      if (inline) {
        return <Text key={k} style={{fontStyle: 'italic'}}>{node.content.map((node,k2) => mdRender(node,k2,true,style, inverted, topLevel))} {'\n\n'}</Text>
      } else {
        return <View key={k} style={{paddingHorizontal: 20, paddingTop: 12, marginBottom: 16, borderLeftWidth: 1, borderLeftColor: '#666'}}>
          {node.content.map((node,k2) => mdRender(node,k2,inline, {...style, fontStyle: 'italic'},inverted, topLevel))}
        </View>
      }
    case 'link': return <MDLink key={k} url={node.target}>{node.content.map((node,k2) => 
        mdRender(node,k2,true, {...style, color: inverted ? 'white' : linkColor}, inverted))
      }</MDLink>
    case 'list':
      if (inline) {
        // return <Text>{JSON.stringify(node)}</Text>
        return (
          <Text style={style} key={k}>
            {node.items.map((item,k2) => <Text key={k2} style={style}>{(k2+1) + '.'} {mdRenderItem(item,null,true,style,inverted)}</Text>)}
          </Text>
        )
      } else {
        return <View key={k}>
            {node.items.map((item,k2) => <View key={k2}><Text style={style}>{(k2 + 1) + '.'} {mdRenderItem(item,null,true,style,inverted)}</Text></View>)}
          </View>
      }
    default: return <Text key={k} style={{color: 'red'}}>Node: {node.type}</Text>
  }

}

export function RobMarkdown({text, style, inline=true, inverted}) {
  // console.log('RobMarkdown', text.slice(0,100));
  const nodes = defaultBlockParse(text);
  // console.log('Parsed', nodes);
  if (inline) {
    return (
      <Text style={style}>
        {nodes.map((node,k) => mdRender(node,k,inline, style, inverted, true))}
      </Text>
    )
  } else {
    return (
      <View>
        {nodes.map((node,k) => mdRender(node,k,inline, style, inverted, true))}
      </View>
    )

  }
}