import React from 'react';
import { TouchableOpacity, Button, View, Text, StyleSheet} from 'react-native';
import { firstMatchingObjectKey, removeKey, addKey } from '../basics/util';
import Entypo from 'react-native-vector-icons/Entypo';

export function ToggleButton({text, color, toggled, onToggle}) {
  const onStyle = {
    box: {
      backgroundColor: color,
      borderRadius: 8,
      margin: 4,
      flexDirection: 'row',
      padding: 8,
      borderColor: color,
      borderWidth: 1,
      // flex: 1
    },
    text: {
     color: "#fff",
     marginLeft: 4,
     marginRight: 4
    },
    iconName: 'check',
    iconColor: '#fff'
  }
  const offStyle = {
    box: {
      borderRadius: 8,
      margin: 4,
      flexDirection: 'row',
      borderColor: color,
      borderWidth: 1,
      padding: 8,
      // flex: 1
    },
    text: {
      color: color,
      marginLeft: 4,
      marginRight: 4
    },
    iconName: 'plus',
    iconColor: color,
  }
  const style = toggled ? onStyle : offStyle;
  return (
    <TouchableOpacity part='button' onPress={()=>onToggle(!toggled)}> 
      <View style={{flexDirection: 'row'}} >
        <View style={style.box}>
          <Entypo style={style.icon} name={style.iconName} size={16} color={style.iconColor} />
          <Text style={style.text}>{text}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );  
}

export function ToggleGroup({toggledItems, onToggledChanged, children}) {
  return (
    <View>
      {children.map((child) => 
        React.cloneElement(child, {
          toggled: toggledItems[child.props.value],
          onToggle: (toggled) => {
            if (toggled) {
              onToggledChanged(addKey(toggledItems, child.props.value));
            } else {
              onToggledChanged(removeKey(toggledItems, child.props.value));
            }
          } 
        })
      )} 
    </View>
  )
}

const styles = StyleSheet.create({
  boxBox: {
    flexDirection: 'row'
  }
})