import React from 'react';
import { AppState, PanResponder, StatusBar, Platform, Dimensions, Linking, SafeAreaView, View, Text, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { FixedTouchable, NavClickable } from '../components/basics';
import { watchCurrentUserName, getCurrentUserName, getCurrentUser, watchNetworkConnected, releaseWatchers } from './data';
import IonIcons from 'react-native-vector-icons/Ionicons';
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Feather from 'react-native-vector-icons/Feather';
import { withNav } from '../basics/provider';
import { TopFlatList, BasicScroller, BottomFlatList } from '../components/Scrollers';
import { Notifications } from 'expo';
import _ from 'lodash';
import { TextBox, isTest } from '../components/testable';
import { getFirstName } from './util';
import { KeyboardSafeView } from '../components/KeyboardSafeView';
// import Markdown from 'react-native-markdown-renderer';
import { RobMarkdown } from './markdown';

export const baseColor = 'rgb(29,161,242)';
export const darkBaseColor = 'hsl(202.8, 89.1%, 13%)';
export const baseBackgroundColor = '#e6ecf0';
export const unreadColor = '#e7f4fd'
export const unpublishedColor = '#e7fdf4'
export const linkColor = '#385898'
export const timeColor = 'rgb(101, 119, 134)'
// export const textColor = 'rgb(20, 23, 26)'
export const textColor = '#333';
export const meMessageColor = '#0084ff'

// export const appName = 'Multitalk';
export const appName = 'Talkful';
// export const appIcon = 'https://talkful.org/talkful_web_icon.png'
export const appIcon = 'https://talkful.org/icon_web.png'
export const appDomain = 'https://talkful.org'
// export const appDomain = 'https://multitalk.net'

function BrokenStatusBar() {
  // return null;
  return <StatusBar barStyle='dark-content' />
}

export function getNodeColor({broadcastInfo}) {
  if (broadcastInfo.unpublished || broadcastInfo.isPrivate) {
    if (broadcastInfo.isPrivate) {
      return '#666'
    } else {
      return '#337F3E'
    }
  } else {
    return 'white';
  }
}

export function getNodeVerb({broadcastInfo}) {
  if (broadcastInfo.unpublished || broadcastInfo.isPrivate){
    if (broadcastInfo.isPrivate) {
      return 'private'
    } else {
      return 'not yet published'
    }
  } else {
    return null;
  }
}

function LinkWithoutNav({title, url, children, inverted, navigation, safari=false}) {
  if (Platform.OS == 'web') {
    return <a target='_blank' style={{color: inverted ? 'white' : 'rgb(29,161,242)'}} rel='noopener noreferrer' href={url}>{children}</a>
  } else {
    return (
      <Text style={{color: inverted ? 'white' : 'rgb(29,161,242)', textDecorationLine: inverted ? 'underline' : undefined}} onPress={()=>navigation.push('web', {title, uri: url})}>{children}</Text>
    )
  }
}
export const Link = withNav(LinkWithoutNav)

// const urlExpr = /((http://)|(https://))?[a-zA-Z0-9_\.]{3,256}\.[a-z]{2,4}\//gi
// const urlExpr = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
const urlExpr = /((http:\/\/)|(https:\/\/))?[-a-zA-Z0-9.-]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;

export const urlRegex = new RegExp(urlExpr);

function trimUrl(url) {
  if (url.length > 40) {
    return url.slice(0,40)+'...'
  } else {
    return url;
  }
}

export function LinkText({text, style, inline, inverted}) {
  return <RobMarkdown text={text} style={style} inline={inline} inverted={inverted} />
  // return <Markdown>{text}</Markdown>
}

export function OLDLinkText({text, style, inverted}) {
  if (!text) return null;
  const m = text.match(urlRegex);
  if (m && m.length > 0) {
    const url = m[0];
    const start = text.lastIndexOf(url);
    const before = text.slice(0,start);
    const after = text.slice(start + url.length);
    return (
      <Text style={style}>
        {before}
        <Link url={url} inverted={inverted}>{trimUrl(url)}</Link>
        <LinkText style={style} text={after} />
        {/* {after} */}
      </Text>
    )
  } else {
    return <Text style={style}>{text}</Text>
  }
}


function hash(str) {
  var hash = 5381,
      i    = str.length;
  while(i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  return hash >>> 0;
}

export function nameColor({name}) {
  if (!name) {
    return 'grey'
  }
  const nameFloat = (hash(name) % 64) / 64.0 
  const hue = 360 * nameFloat
  const color = 'hsl('+Math.floor(hue)+',100%, 30%)';
  return color;
}

export function DefaultImage({name, colorKey, size, style, radiusFactor = 2}) {
  const firstLetter = name ? name[0] : '?';
  const color = nameColor({name: colorKey});
  // const nameFloat = (hash(colorKey) % 64) / 64.0 
  // const hue = 360 * nameFloat
  // const color = 'hsl('+hue+',100%, 32%)';
  return (
    <View style={[{width: size, height: size, borderRadius: size/radiusFactor, backgroundColor: color, alignItems: 'center', justifyContent: 'center'},style]}>
      <Text style={{fontSize: size*0.6, color: 'white'}}>{firstLetter}</Text>
    </View>
  )
}

export class LoadingScreen extends React.Component {
  state = {waited: false}
  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({waited: true}), 1000); 
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  render() {
    const {waited} = this.state;
    if (!waited) return null;
    return (
      <View> 
        <OfflineWarning />
        <Text style={{marginTop: 100, textAlign: 'center'}}>Loading</Text>
      </View>
    )  
  }
}


export function WideButton({onPress, children, style}){
  return (
    <FixedTouchable onPress={onPress}>
      <View style={[{padding: 8, borderRadius: 8, backgroundColor: baseColor, margin: 16, alignItems: 'center'},style]}>
        <Text style={{color: 'white', fontWeight: 'bold'}}>
          {children}
        </Text>
      </View>
    </FixedTouchable>
  )
}


export function Button({children, disabled=false, signin=false, onPress, navAction, navProps, style = null, textStyle = null}) {
  const borderRadius = 0;
  if (disabled) {
    return (
      <View style={{padding: 8, borderRadius, borderColor: '#aaa', borderWidth: 1, ...style}}>
        <Text style={{color: '#aaa'}}>{children}</Text>
      </View>
    )
  } else if (navAction || (signin && !getCurrentUser())) {
    return (
      <NavClickable navAction={navAction || 'signin'} navProps={navProps}>
        <View style={{padding: 8, borderWidth: 1, borderColor: baseColor, borderRadius, backgroundColor: baseColor, ...style}}>
          <Text style={{color: 'white', ...textStyle}}>{children}</Text>
        </View>
      </NavClickable>
    )
  } else {
    return (
      <FixedTouchable onPress={onPress}>
        <View style={{padding: 8, borderWidth: 1, borderColor: baseColor, borderRadius, backgroundColor: baseColor, ...style}}>
          <Text style={{color: 'white', ...textStyle}}>{children}</Text>
        </View>
      </FixedTouchable>
    )
  }
}

export class HeaderProfileBare extends React.Component {
  state = {name: null}
  componentDidMount() {
    watchCurrentUserName(this, name => this.setState({name}));
  }
  render() {    
    const {name} = this.state;
    const {navigation} = this.props;
    if (!name) return null;
    return (
      <FixedTouchable onPress={()=>navigation.push('account')} >
        <UserIcon userName={name} user={getCurrentUser()} size={30} style={{marginHorizontal: 12}} />
      </FixedTouchable>
    )
  }
}


export function Agreements(){
  return (
    <Text style={{textAlign: 'center', color: '#666'}}>
      Use of {appName} is governed by the {appName} <Link title='License' url={appDomain + '/license.html'}>license
      </Link>, <Link title='Community Standards' url={appDomain + '/standards.html'}>community standards
      </Link>, and <Link title='Privacy Policy' url={appDomain + '/privacy.html'}>privacy policy</Link>.
    </Text>
  )
}

export function HelpBubble({title, children, style}) {
  return (
    <View style={{flexDirection: 'row', backgroundColor: '#ffffc5', borderWidth: 1, borderColor: '#ddd', paddingHorizontal: 12, paddingVertical: 8, ...style}}>
      <IonIcons name='md-help-circle' size={30} color='#666' style={{marginRight: 12}} />
      <View style={{flex:1}}>
        {!title ? null : 
          <Text style={{fontWeight: 'bold', marginBottom: 2}}>
            {title}
          </Text>
        }
        <Text style={{flex: 1, color: '#666'}}>
          {children}
        </Text>
      </View>
    </View>
  )
}

export class OfflineWarning extends React.Component {
  state = {connected: true, waited: false};
  componentDidMount() {
    this.onConnected = watchNetworkConnected(this, connected => {
      this.setState({connected, waited: false})
      if (!connected && AppState.currentState == 'active') {
        this.timeout = setTimeout(() => this.setState({waited: true}), 5000);
      }
    });
  }
  componentWillUnmount() {
    releaseWatchers(this);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  render() {
    const {connected, waited} = this.state;
    if (connected || !waited) {
      return null;
    } else {
      return (
        <View style={{
            flexDirection: 'row', justifyContent: 'space-around',
            alignItems: 'stretch', padding: 8, backgroundColor: 'white', 
            borderColor: '#ddd', borderBottomWidth: StyleSheet.hairlineWidth,
            borderTopWidth: StyleSheet.hairlineWidth}}>
          <Text style={{fontWeight: 'bold', color: 'red', flex: 1, flexShrink: 0, textAlign: 'center'}}>No internet connection</Text>
        </View>
      )
    }
  }
}

export class NotifsBanner extends React.Component {
  state = {notification: null}
  componentDidMount() {
    if (Platform.OS == 'web') return null;
    this.notifListener = Notifications.addListener(notification => {
      // console.log('banner got notif', notification);
      if (notification && notification.origin == 'received') {
        this.setState({notification});
        if (!isTest) {
          this.timeout = setTimeout(() => this.setState({notification: null}), 5000);  
        }
      }
    });
  }
  componentWillUnmount() {
    if (Platform.OS == 'web') return null;
    this.notifListener.remove();
    if (this.timeout) {
      clearTimeout(this.timetout);
    }
  }
  render () {
    const {notification} = this.state;
    const {onClickNotif} = this.props;
    // console.log('render notif', notification);
    if (Platform.OS == 'web') return null;
    if (!notification) return null;
    const width = Dimensions.get('window').width - 16;
    return (
      <View style={{position: 'absolute', left: 0, top: 0, width: 300, flex: 1}}>
        <SafeAreaView>
          <View style={{flexDirection: 'row', justifyContent: 'space-around', flex: 1}}>
            <View style={{ flex: 1,
              position: 'absolute', left: 0, top: 0, width,
              margin: 8,
              padding: 8, backgroundColor: 'white', borderColor: '#ddd', 
              borderBottomWidth: StyleSheet.hairlineWidth,
              alignItems: 'stretch',
              flexDirection: 'row', justifyContent: 'space-around',
              shadowColor: '#000', shadowOffset: {width: 0, height: 1}, 
              shadowOpacity: 0.6, shadowRadius: 6}}>
              <FixedTouchable onPress={() => {onClickNotif(notification); this.setState({notification:null})}}>
                <Text numberOfLines={1} style={{width: 300, flex: 1, flexShrink: 0, flexGrow: 1}}>
                  <Text style={{fontWeight: 'bold'}}>{_.get(notification,['data','name'])}</Text>
                  <Text style={{flexGrow: 1, color: '#222'}}> {_.get(notification,['data','text'])}</Text>
                </Text> 
              </FixedTouchable>  
            </View>
          </View>
        </SafeAreaView>
      </View>
    )
  }
}

export function KeyboardLessScreenContentFlatList({data, maxWidth, statuslight=false, initialNumToRender=20, style, innerStyle}) {
  return (
    <View style={[{flex: 1}, style]}>
      <NotifsBanner />
      <OfflineWarning />
      <BrokenStatusBar />
      <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}} >
        <View style={[{flex: 1, maxWidth: maxWidth || 500, alignItems: 'stretch'}, innerStyle]}>
          <TopFlatList renderItem={({item}) => item.value()} data={data} 
            initialNumToRender={initialNumToRender}/>
        </View>
      </View>
     </View>
  )
}


export function ScreenContentFlatList({data, maxWidth, statuslight=false, initialNumToRender=20, style, innerStyle}) {
  return (
    <KeyboardSafeView style={[{flex: 1}, style]}>
      {/* <NotifsBanner /> */}
      <OfflineWarning />
      <BrokenStatusBar />
      <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}} >
        <View style={[{flex: 1, maxWidth: maxWidth || 500, alignItems: 'stretch'}, innerStyle]}>
          <TopFlatList renderItem={({item}) => item.value()} data={data} 
            initialNumToRender={initialNumToRender}/>
        </View>
      </View>
    </KeyboardSafeView>
  )
}

export function ScreenContentBottomFlatList({data, statuslight=false, initialNumToRender=20, style, innerStyle}) {
  return (
    <View style={[{flex: 1}, style]}>
      <OfflineWarning />
      <BrokenStatusBar />
      <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}} >
        <View style={[{flex: 1, maxWidth: 500, alignItems: 'stretch'}, innerStyle]}>
          <BottomFlatList renderItem={({item}) => item.value()} data={data} initialNumToRender={initialNumToRender}/>
        </View>
      </View>
    </View>
  )
}

export function ScreenContentScroll({children, white, statuslight = false}) {
  return (
    <View style={{flex: 1, backgroundColor: white ? 'white' : baseBackgroundColor}}>
      <OfflineWarning />
      <BrokenStatusBar />
      <BasicScroller>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
          <View style={{maxWidth: 500, paddingBottom: 200, flex: 1, alignItems: 'stretch'}}>
            {children}
          </View>
        </View>
      </BasicScroller>
    </View>
  )
}

export function ActionButton({onPress, small, navAction, navProps, label, iconFamily=FontAwesome, iconName, inverted=false}) {
  return (
    <NavClickable onPress={onPress} navAction={navAction} navProps={navProps} signIn >
      <View style={{flexDirection: 'row', paddingVertical: small ? 6 : 10, paddingHorizontal: 16, alignItems: 'center'}}>
        {React.createElement(iconFamily, {name: iconName, size: small ? 12 : 14, color: inverted ? 'white' : '#666'})}
        <Text style={{color: inverted ? 'white' :  '#666', fontSize: small ? 12 : null, marginLeft: 4}}>{label}</Text>
      </View>              
    </NavClickable>
  )
}

export class OldNonScrollingTextBox extends React.Component {
  state = {events: 'none'}
  render () {
    const {events} = this.state;
    // return <TextBox {...this.props} />
    return (
      <TouchableOpacity pointerEvents={events}
          onPress={()=>{this.setState({events: 'auto'}); this.nextInput.focus()}}>
        <TextInput {...this.props} ref={r => this.textInput = r} onBlur={()=>this.setState({events: 'none'})}/>
      </TouchableOpacity>
    )
  }
}

export class NonScrollingTextBox extends React.Component {
  componentDidMount() {
    if (Platform.OS === 'ios') {
      this.panResponder = PanResponder.create({
        onStartShouldSetPanResponderCapture: () => true,
      });
    }
  }

  render() {
    return (
      <View {...this.panResponder ? this.panResponder.panHandlers : {}}>
        <TextInput {...this.props} />
      </View>
    )
  }
}


export function FormInput({textContentType, autoCompleteType, textAlign, placeholder, keyboardType, defaultValue, onChangeText, style}) {
  const textBoxStyle = {
    backgroundColor: 'white',
    padding: 8,
    borderColor: '#ddd',
    borderRadius: 8,
    borderWidth: 1,
    margin: 4,
    flex: 1,
    marginHorizontal: 16
  }
  if (Platform.OS == 'web') {
    return <TextBox placeholder={placeholder} style={style || textBoxStyle}
      defaultValue = {defaultValue}
      // textAlign = {textAlign}
      onChangeText={onChangeText}/>
  } else {
    return <TextBox placeholder={placeholder} style={style || textBoxStyle}
      textContentType={textContentType}
      keyboardType={keyboardType}
      autoCompleteType={autoCompleteType}
      underlineColorAndroid='transparent'
      textAlign = {textAlign}
      defaultValue = {defaultValue}
      onChangeText={onChangeText}/>
  }
}

export function getName(user, users) {
  if (user == getCurrentUser()) {
    return 'You'
  } else {
    return _.get(users, [user, 'name'], '?');
  }
}

export function CollapsedNode({nodeInfo, topLevel, inFeed=false, translucent = false}) {
  const inverted = nodeInfo.unpublished || nodeInfo.isPrivate;
  const name = nodeInfo.name || '?' //getName(nodeInfo.author || nodeInfo.from, users);
  return (
    <View style={{padding: 8, marginVertical: 4, borderRadius: 16, 
      backgroundColor: getNodeColor({broadcastInfo:nodeInfo}), //broadcastInfo.unpublished ? privateColor : 'white',
      borderTopLeftRadius: topLevel ? 16 : 0,
      opacity: translucent ? 0.6 : undefined,
      maxWidth: 500,
      flexDirection: 'row'
      }}>
      <Text numberOfLines={1} style={{flex: 1, fontSize: 12, marginBottom: 2, color: inverted ? 'white' : 'black'}}>
        <Text style={{fontWeight: 'bold'}}>{inFeed ? name : getFirstName(name)}: </Text>
        <Text style={{color: inverted ? 'white' : '#222'}}>{nodeInfo.text}</Text>
      </Text>
      <Feather name='chevrons-down' color={inverted ? 'white' : '#666'} size={16} />

      {/* <FontAwesome name='expand' color={inverted ? 'white' : '#666'} size={16} /> */}
    </View>
  )
}

export function NewPost({group}) {
  return (
    <View style={{flexDirection: 'row', flex: 1}}>
      <NavClickable navAction='choosePosts' navProps={{group}}>
        <View style={{borderRadius: 16, marginLeft: 8, backgroundColor: baseColor, flexDirection: 'row', 
            // borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,
            marginVertical: 10, padding: 10, flex: 0, alignItems: 'center'}}>
          <Text style={{color: 'white', fontSize: 16}}>Choose</Text>
        </View>
      </NavClickable>
      <View style={{flex: 1}}>
        <NavClickable navAction='newPost' navProps={{group}}>      
          <View style={{borderRadius: 16, backgroundColor: 'white', flexDirection: 'row', 
              borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, flex: 1,
              marginHorizontal: 10, marginVertical: 10, padding: 10, alignItems: 'center'}}>
            <Text style={{color: '#666', fontSize: 16, marginLeft: 10}}>Write a post...</Text>
          </View>
        </NavClickable>
      </View>
    </View>
  )
}

export function PostIcon({user, userName, size=50}) {
  return (
    <View style={{width: size, height: size}}>
      {/* {group ? 
        <DefaultImage style={{position: 'absolute', bottom: 0, right: 0}} size={size*0.6} 
          name={groupName} colorKey={group} radiusFactor={4} /> 
      :  */}
        <Entypo name='text-document' size={size * 0.8} color='#bbb' style={{position: 'absolute', bottom: 0, right: 0}} />
      {/* } */}
      <DefaultImage style={{position: 'absolute', top: 0, left: 0, borderColor: 'white', borderWidth: 1}} 
          name={userName} colorKey={user} size={size*0.5} />
    </View>
  )
}

export function GroupIcon({group, groupName, size=50, style}) {
  return <DefaultImage name={groupName} colorKey={group} style={style} size={size} radiusFactor={8} />
}

export function UserIcon({user, userName, size=50, style}) {
  return <DefaultImage name={userName} colorKey={user} style={style} size={size} />
}

