import React from 'react';
import { FixedTouchable } from '../components/basics';
import { Image, Linking, Platform, Text, View } from 'react-native';
import { getUserAgent, getCurrentPath } from '../basics/web';
import { Button, ScreenContentScroll, appName } from './ui';

const iOSUrl = 'https://apps.apple.com/us/app/multitalk/id1468577195?ls=1';
const playUrl = 'https://play.google.com/store/apps/details?id=org.grouphut';
// const playUrl = 'https://play.google.com/store/apps/details?id=org.talkbeat';

function IOSAppButton(){
  return (
    <FixedTouchable onPress={() => Linking.openURL(iOSUrl)} >
      <Image
        style={{width: 153, height: 45, marginBottom: 16}}
        source={{uri:'https://talkbeat.org/app_store.png'}} />
    </FixedTouchable>
  )
}

function AndroidAppButton(){
  return (
    <FixedTouchable onPress={() => Linking.openURL(playUrl)} >
      <Image
        style={{width: 153, height: 45, marginBottom: 16}}
        source={{uri:'https://talkbeat.org/google_play.png'}} />
    </FixedTouchable>
  )
}

function getAppUrl() {
  if (isIOSBrowser()) {
    return iOSUrl;
  } else {
    return playUrl;
  }
}

function AppButton() {
  if (isIOSBrowser()) {
    return <IOSAppButton />
  } else {
    return <AndroidAppButton />
  }
}

export function AppRedirectScreen() {
  const isIOS = isIOSBrowser();
  var path = getCurrentPath();
  if (path == '' || path == '/') {
    path = '/feed'
  }
  const appUrl = 'grouphut:/' + path;
  return (
    <ScreenContentScroll>
      <View style={{backgroundColor: 'white', padding: 16}}>
        <Text style={{marginBottom: 10, fontWeight: 'bold', textAlign: 'center'}}>
          <a href={getAppUrl()} style={{color: 'rgb(29,161,242)', textDecoration: 'none'}}>Install the {appName} app</a> to use {appName} on {isIOS ? 'iOS' : 'Android'}.
        </Text>
        <Text style={{textAlign: 'center'}}>
          The {appName} app works on iOS, Android, and Desktop Web.
        </Text>
      </View>
      <View style={{marginTop: 16, flexDirection: 'row', justifyContent: 'space-around'}}>
        <AppButton />
      </View>
      <Text style={{marginLeft: 16, marginBottom: 4}}>Already have the app installed?</Text>
      <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <Button onPress={()=>Linking.openURL(appUrl)}>Open in {appName}</Button>
      </View>
      <iframe src={appUrl} style={{display: 'none'}}/>
    </ScreenContentScroll>
  )
}

function isIOSBrowser() {
  return /iphone|ipod|ipad/.test( getUserAgent() );
}

function isAndroidBrowser() {
  return /android/.test( getUserAgent() );
}

export function isMobileBrowser() {
  return Platform.OS == 'web' && (isAndroidBrowser() || isIOSBrowser());
}
