import React from 'react';
import { Platform, Text, View, StyleSheet } from 'react-native';
import { reloadIfVersionChanged } from '../basics/versioncheck';
import { watchGroupPosts, watchGroupUsers, watchGroupBroadcasts, watchGroupPostBroadcasts, watchPostInfo, watchGroupChats, likePost, watchGroupLikes, getSingleGroup, markGroupRead, watchGroupInfo, approveGroupMember, releaseWatchers, addReply, watchGroupPostLastReads, watchGroupBoosts, watchGroupPeopleTagged, watchGroupFollows, watchGroupLastPrivate, watchGroupLastPublic, watchGroupRequest, markAppUsed, watchGroupPostLikes } from './data';
import { chatText, ScreenContentFlatList, baseColor, LoadingScreen, Button, DefaultImage, linkColor, timeColor, textColor, unreadColor, HelpBubble, NotifsBanner, ActionButton, isBroadcastVisibleTopLevel, NewPost, baseBackgroundColor, GroupIcon, UserIcon } from './ui';
import { sortKeysBy, plural, getPostVerb } from './util';
import { NavClickable, FixedTouchable, CommaSep } from '../components/basics';
import Entypo from 'react-native-vector-icons/Entypo';
import _ from 'lodash';
import { getCurrentUser, getLoadedCurrentUserName } from '../slowgroup/data';
import { NonMemberGroupScreen } from './NonMemberGroup';
import { PostPreview } from './Post';
import { TalkTree } from './TalkTree';

export class GroupHeader extends React.Component {
  state = {name: '', users: null};
  async componentDidMount() {
    const {group} = this.props;
    watchGroupUsers(this, group, users => this.setState({users: users || {}}));
    watchGroupInfo(this, group, groupInfo => this.setState({name: _.get(groupInfo,'name', 'Group')}));
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }
  render () {
    const {group} = this.props;
    const {name, users} = this.state;
    // const color = Platform.OS == 'web' ? 'black' : 'white';
    // const color = 'white';
    const color = 'black'
    return (
      <NavClickable navAction='groupInfo' navProps={{group}}>
        <View style={{alignItems: Platform.OS == 'web' ? undefined : 'center'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <GroupIcon group={group} groupName={name} size={30} />
            <Text numberOfLines={1} style={{color, fontSize: 20, marginLeft: 8}}>
              {name}
            </Text>
          </View>
        </View>
        {/* <NotifsBanner /> */}
      </NavClickable>
    )
  } 
}

function RecentHeader() {
  return (
    <Text style={{marginTop: 16, marginLeft: 10, marginBottom: 4, color: '#666'}}>Recent Activity</Text>
  )
}



function GroupInfoHeader({groupInfo, group, users}) {
  const sortedUsers = Object.keys(users);
  if (groupInfo.allusers || groupInfo.isPublic) {
    return (
      <NavClickable navAction='groupInfo' navProps={{group}}>
        <View style={{backgroundColor: 'white', paddingHorizontal: 10, paddingVertical: 8,
            borderColor: '#ddd', borderBottomWidth: 1, marginBottom: 8}}>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Text numberOfLines={1} style={{color: '#666', marginRight: 4, fontSize: 12}}>Public Group - Visible to everyone</Text>
            <View style={{flexDirection: 'row', backgroundColor: baseColor, borderRadius: 16, paddingRight: 8, paddingLeft: 4, paddingVertical: 2}}>
              <Text style={{color: 'white', fontSize: 12}}>About this group</Text>
            </View>
          </View>
        </View>
      </NavClickable>
    )
  }
  return (
    <NavClickable navAction='groupInfo' navProps={{group}}>
      <View style={{backgroundColor: 'white', paddingHorizontal: 10, paddingVertical: 8, 
          borderColor: '#ddd', borderBottomWidth: 1, marginBottom: 8}}>
        {/* <Text numberOfLines={1} style={{color:textColor}}>{groupInfo.intro}</Text>       */}
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
          <Text style={{color: '#666', marginRight: 4, fontSize: 12}}>{sortedUsers.length} MEMBERS:</Text>
          <View style={{flexDirection: 'row', flex: 1, flexShrink: 1, marginRight: 4, overflow: 'hidden'}}>
            {sortedUsers.map(user => 
              <UserIcon key={user} user={user} userName={users[user].name} size={16} style={{marginRight: 2}}/> 
            )}
          </View>
          <View style={{flexDirection: 'row', backgroundColor: baseColor, borderRadius: 16, paddingRight: 8, paddingLeft: 4, paddingVertical: 2}}>
            <Entypo name='plus' size={12} color='white'/>
            <Text style={{color: 'white', fontSize: 12}}>Invite</Text>
          </View>
        </View>
      </View>
    </NavClickable>
  )
}

function canUserSeePost({mode, post, userWasTagged}) {
  // console.log('post', post, mode);
  if (!post) {
    return false;
  } 
  if (post.from == getCurrentUser()) {
    return true;
  } else if (userWasTagged) {
    return true;
  } else if (mode == 'requested') {
    return false;
  } else if (mode == 'admin') {
    return true;
  } else if (post.type == 'asktojoin') {
    return false;
  } else if (post.type == 'submission') {
    return false;
  } else if (post.unpublished || post.isPrivate) {
    return false;
  } else {
    return true;
  }
}

function RequestedHelpBubble() {
  return (
    <HelpBubble>
      You asked to join this group but have not yet been granted access. 
      Currently the only post you can see is your post introducing yourself to the group.
    </HelpBubble>
  )
}

function getPostTime({post, postInfo, lastPrivate, lastPublic, follows}) {
  // if (_.get(follows, [post, getCurrentUser()])){
  //   // console.log('following', post, postInfo);
  //   return _.max([
  //     _.get(postInfo, 'time', 0), 
  //     _.get(lastPrivate, [post, 'time'], 0),
  //     _.get(lastPublic, [post, 'time'], 0),
  //   ]);
  // } else {
    return _.get(postInfo, 'time', 0);
  // }
}

export class GroupScreen extends React.Component {
  state = {posts: null, users: null, broadcasts: null, likes: null, 
    groupInfo: null, lastReads: null, tagged: null, follows: null, 
    lastPrivate: null, lastPublic: null,
  }

  async componentDidMount() {
    const {group} = this.props;
    reloadIfVersionChanged();
    watchGroupPosts(this, group, posts => this.setState({posts: posts || {}}));
    watchGroupUsers(this, group, users => this.setState({users: users || {}}));
    // watchGroupBroadcasts(this, group, broadcasts => this.setState({broadcasts: broadcasts || {}}));
    // watchGroupBoosts(this, group, boosts => this.setState({boosts: boosts || {}}));
    // watchGroupChats(this, group, chats => this.setState({chats: chats || {}}));
    // watchGroupLikes(this, group, likes => this.setState({likes: likes || {}}));
    watchGroupPostLikes(this, group, likes => this.setState({likes}));
    watchGroupFollows(this, group, follows => this.setState({follows}));
    watchGroupInfo(this, group, groupInfo => this.setState({groupInfo: groupInfo || {}}));
    watchGroupPostLastReads(this, {group}, lastReads => this.setState({lastReads}));
    watchGroupPeopleTagged(this, group, tagged => this.setState({tagged}));
    watchGroupLastPrivate(this, group, lastPrivate => this.setState({lastPrivate}));
    watchGroupLastPublic(this, group, lastPublic => this.setState({lastPublic}));

    markGroupRead(group); 
    markAppUsed();
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }

  render() {
    const {posts, users, broadcasts, likes, groupInfo, lastReads, 
      tagged, follows, lastPrivate, lastPublic} = this.state;
    const {group, navigation} = this.props;
    if (tagged == null || lastReads == null || posts == null || users == null || 
      likes == null || groupInfo == null || lastPublic == null || lastPrivate == null) return <LoadingScreen />

    const myStatus = users[getCurrentUser()] || {mode: 'guest'};
    if (!myStatus && !groupInfo.isPublic) {
      return <NonMemberGroupScreen group={group} navigation={navigation} />
    }

    // const userBroadcasts = _.mapValues(broadcasts, bp => _.mapValues(bp, b => ({...b, userInfo: users[b.from]})));
    const sortedPosts = sortKeysBy(posts || {}, (postInfo, post) => getPostTime({post,postInfo,lastPrivate, lastPublic, follows})).reverse();     
      // (p,k) => Math.max(p.time || 0, _.get(boosts,[k,'time'],0))).reverse();
    // const sortedPosts1 = sortKeysBy(posts || {}, (p,k) => Math.max(p.time || 0, _.get(boosts,[k,'time'],0))).reverse();
    const filteredPosts = _.filter(sortedPosts, p => canUserSeePost({mode: myStatus.mode, post:posts[p], userWasTagged:_.get(tagged,[p,getCurrentUser()])}));

    return (
      <ScreenContentFlatList statuslight style={{backgroundColor: baseBackgroundColor}} data={[
        {key: 'info', value: () => <GroupInfoHeader group={group} groupInfo={groupInfo} users={users} />},
        {key: 'newpost', value: () => <NewPost group={group} /> },
        {key: 'recenthead', value: () => Platform.OS == 'web' ? <RecentHeader /> : null},
        ...filteredPosts.map(post => ({key: post, value: () =>  
          <PostPreview part={post} post={post} group={group} users={users} navigation={navigation}
              userInfo={users[posts[post].from]} inGroup={true}
              mode={myStatus.mode} lastRead={_.get(lastReads, post)}
              lastPrivate={lastPrivate[post]} lastPublic={lastPublic[post]}
              collapsed follows={_.get(follows, post)}
              likes={likes[post]} postStatus={posts[post]} />              
        })),
        {key: 'requesthelp', value: () => myStatus.mode != 'requested' ? null : <RequestedHelpBubble />},
        // {key: '_spacer', value: () => 
        //   <View style={{height: 400}} />
        // }
      ]} />
    )
  } 
}


export class GroupRedirectScreen extends React.Component {
  state = {users: null, request: null, groupInfo: null};
  componentDidMount() {
    const {group} = this.props;
    watchGroupUsers(this, group, users => this.setState({users: users || {}}));
    watchGroupRequest(this, group, request => this.setState({request}));
    watchGroupInfo(this, group, groupInfo => this.setState({groupInfo}));
    markGroupRead(group); 
  }
  componentWillUnmount() {releaseWatchers(this);}

  render() {
    const {group, member, navigation} = this.props;
    const {users, request, groupInfo} = this.state;

    const mode = _.get(users,[getCurrentUser(),'mode'],'').toLowerCase()

    if (member || _.get(groupInfo,'isPublic')) {
      return <GroupScreen group={group} navigation={navigation} />
    } else if (users == null || request == null || groupInfo == null) {
      return <LoadingScreen />
    } else if (mode == 'member' || mode == 'admin') {
      return <GroupScreen group={group} navigation={navigation} />
    } else if (request && request.post) {
      return <TalkTree author={getCurrentUser()} group={group} post={request.post} requestPost/>
    } else {
      return <NonMemberGroupScreen group={group} navigation={navigation} />
    }
  }
}
