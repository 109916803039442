import { appDomain } from "./ui";
import firebase from '../basics/firebase';
const fetch = require('node-fetch');

export async function requestLoginCode({email}) {
  const fetchUrl = appDomain + '/sendLoginCode?email=' + encodeURIComponent(email.toLowerCase());
  const response = await fetch(fetchUrl);
  const data = await response.json();
  return data;
}

export async function signinWithLoginCode({email, code, onError}) {
  const fetchUrl = appDomain + '/getLoginToken?email=' + encodeURIComponent(email.toLowerCase()) + '&code=' + code;
  const response = await fetch(fetchUrl);
  const data = await response.json();
  if (data.success == true) {
    firebase.auth().signInWithCustomToken(data.token).catch(error => {
      onError(error.message);
    })
  } else {
    onError(data.message);
  }
}
