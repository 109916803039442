import React from 'react';
import { Alert, Image, View, Text, TextInput, StyleSheet, Platform, ScrollView} from 'react-native';
import { KeyboardSafeView } from '../components/KeyboardSafeView';
import { Button, LoadingScreen, ScreenContentScroll, NonScrollingTextBox, ScreenContentFlatList, DefaultImage, baseColor } from './ui';
import { addPost, getSingleGroup, releaseWatchers, watchGroupPostInfo, editPost, watchGroupPostBroadcasts, editReply, addReply, watchGroupUsers, getCurrentUser, publishPost, publishBroadcast, watchAuthorPostInfo, watchAuthorPostBroadcasts, publishReply, getUserName, watchMyGroups, getUrlForImage } from './data';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { NavClickable, FixedTouchable } from '../components/basics';
import { TextBox } from '../components/testable';
import { PublicationToggle, CompactResponsePreview, MiniResponsePreview, MiniPostPreview, Toggle } from './Post';
import { MiniTalkNode } from './TalkTree';
import _ from 'lodash';
import { sortKeysBy } from './util';
import { pickImage, uploadImage } from './photo';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview'


export class NewPostHeader extends React.Component {
  state = {groupName: 'Group', userName: 'User'};
  async componentDidMount() {
    // const {group, user} = this.props;
    if (this.props && this.props.group) {
      const groupInfo = await getSingleGroup(this.props.group);    
      this.setState({groupName: groupInfo.name});
    }
  }
  componentWillUnmount() {releaseWatchers(this)}

  render () {
    const {group} = this.props;
    // const color = 'white';
    const color = 'black';
    // const color = Platform.OS == 'web' ? 'black' : 'white';
    return (
      <View style={{alignItems: Platform.OS == 'web' ? undefined : 'center'}}>
        <Text style={{fontWeight: '500', color, fontSize: 16}}>
          New Post
        </Text>
        {(group && group != 'private') ? 
          <Text style={{fontSize: 12, color}}>
            in {this.state.groupName}
          </Text>
        :
          <Text style={{fontSize: 12, color}}>
            Not yet shared
          </Text>
        }
      </View>
    )
  } 
}


export class CancelActionLeftHeader extends React.Component {
  cancel() {
    const {navigation, reply, group, edit} = this.props;
    const thing = reply ? 'Reply' : (group ? 'Group' : 'Post');
    Alert.alert(
      edit ? 'Abondon Edits' : ('Delete ' + thing),
      "Your draft won't be saved. Do you you want to delete it?",
      [
        {text: edit ? 'Abandon Edits' : ('Delete ' + thing), onPress: () => navigation.goBack()},
        {
          text: 'Continue Editing',
          onPress: () => console.log('Continue Editing Pressed'),
          style: 'cancel',
        },
      ]
    );
  }

  render() {
    return (
      <FixedTouchable part='cancel' onPress={()=>this.cancel()}>
        <Text style={{fontSize: 17, fontWeight: '500', color: baseColor, marginLeft: 10}}>Cancel</Text>
      </FixedTouchable>  
    )
  }
}

var global_buttonCallback = null;
var global_setButtonState = null;

export class EditActionRightHeader extends React.Component {
  state = {modified: false, inProgress: false};

  componentDidMount() {
    global_setButtonState = state => this.setState(state);
  }

  getLabel({update, submitted, uploading}) {
    if (uploading) {
      return 'Uploading...'
    } else if (submitted) {
      if (update) {
        return 'Updating...';
      } else {
        return 'Posting...'
      }
    } else {
      if (update) {
        return 'Update';
      } else {
        return 'Post'
      }
    }
  }

  render () {
    const {update} = this.props;
    const {modified, submitted, uploading} = this.state;

    const label = this.getLabel({update, submitted, uploading});

    if (!modified || submitted || uploading) {
      return <Text style={{fontSize: 17, color: '#666', marginRight: 10}}>{label}</Text>
    } else {
      return (
        <FixedTouchable part='submit' onPress={()=>global_buttonCallback()}>
          <Text style={{fontSize: 17, fontWeight: '500', color: baseColor, marginRight: 10}}>{label}</Text>
        </FixedTouchable>  
      )
    }  
  }
}


export function NewPostButton({group}) {
  return (
    <NavClickable navAction='newPost' navProps={{group}}>
      <FontAwesome name='pencil-square-o' color='white' size={28} style={{marginRight: 8}}/>
    </NavClickable>
  )
}


function PhotoButton({onPress}) {
  return (
    <FixedTouchable onPress={onPress}>
      <View style={{flexDirection: 'row', marginLeft: 10, alignItems: 'center'}}>
        <FontAwesome name='photo' size={20} color={baseColor} />
        <Text style={{marginLeft: 4, fontSize: 16, color: baseColor}}>Add Photo</Text>
      </View>
    </FixedTouchable>
  )
}

export class EditPostScreen extends React.Component {
  state = {image: null, text: null, modified: false, isPrivate: false, isPublic: true, wasPrivate: false, submitted: false, users: null}

  async editPost() {
    // console.log('editPost', this.props);
    const {navigation, group} = this.props;
    const {text, isPrivate, isPublic, wasPrivate, users, title, image} = this.state;
    const mode = _.get(users, [getCurrentUser(),'mode']);
    this.setSharedState({submitted: true});
    var post = this.props.post;
    if (post) {
      await editPost({text, post, group, isPrivate, isPublic, title, image, wasPrivate});
    } else {
      post = await addPost({text, group, isPrivate, isPublic, title, image});
    }
    if (mode == 'admin' && !isPrivate && wasPrivate) {
      await publishPost({author: getCurrentUser(), group, post, text, user: getCurrentUser()})
    }
    if (this.props.post) {
      // console.log('navigating back');
      navigation.goBack();
    } else {
      navigation.replace('post', {group, author: getCurrentUser(), post, fromGroup: true});
    }
  }

  async newPhoto() {
    const {uri, base64, width, height} = await pickImage();
    console.log('image', {uri, base64length: base64.length, base64start: base64.slice(0,20)});
    this.setSharedState({modified: true, imageBase64: base64, imageUploading: true});

    setTimeout(async () => {
      const {thumbnailUrl, image} = await uploadImage({uri, width, height});
      this.setSharedState({image, imageBase64: null, imageUploading: false});  
    }, 50);
  }

  async componentDidMount() {
    const {post, group} = this.props;
    watchGroupUsers(this, group, users => this.setState({users}));
    if (post) {
      watchAuthorPostInfo(this, {author: getCurrentUser(), post}, postInfo => 
        this.setState({text: postInfo.text || '', title: postInfo.title || '', 
          isPrivate: postInfo.isPrivate, wasPrivate: postInfo.isPrivate,
          image: postInfo.image
        })
      );
    } else {
      this.setState({text: '', title: '', isPrivate: false, wasPrivate: true});
    }
    global_buttonCallback = () => this.editPost();
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }

  setSharedState(state) {
    this.setState(state);
    if (global_setButtonState) {
      global_setButtonState(state);
    }
  }

  render() {
    const {post, group} = this.props;
    const {imageUploading, text, modified, isPrivate, isPublic, submitted, users, title, image, imageBase64} = this.state;
    if (text == null || users == null) return <LoadingScreen />

    const mode = _.get(users, [getCurrentUser(),'mode']);

    const style = {
        paddingHorizontal: 10, paddingTop: 10, paddingBottom: 100,
        flex: 1
        // borderTopWidth: StyleSheet.hairlineWidth, borderTopColor: '#ddd'}
    }

    if (Platform.OS == 'web') {
      return (
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
          <View keyboardDismissMode='on-drag' style={{flex: 1, backgroundColor: 'white', maxWidth: 500, alignItems: 'stretch'}}>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 8, marginRight: 8, justifyContent: Platform.OS == 'web' ? 'flex-end' : 'flex-start'}}>
              <Button part='submit' disabled={!modified || submitted || imageUploading} onPress={()=>this.editPost()}>
                {imageUploading ? 'Uploading...'
                :
                  (post  
                  ? (submitted ? 'Updating...' : 'Update')
                  : (submitted ? 'Posting...' : 'Post')
                  )  
                }
              </Button>
            </View>
            <TextBox part='title'
              placeholder="Title (optional)"
              maxLength={60}
              value={title}
              onChangeText={title=>this.setSharedState({title, modified: true})}
              style={{paddingHorizontal: 10, paddingVertical: 10, borderTopWidth: StyleSheet.hairlineWidth,
                  borderTopColor: '#ddd', marginTop: 8, fontSize: 18}}
            />
            {(!image && !imageBase64) ? null :
              <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                <Image style={{width: 350, height: 350}} 
                    source={{uri: image ? getUrlForImage({image}) : 'data:image/jpeg;base64,'+imageBase64}}
                  />
              </View>
            }
            <TextBox part='input' 
              autoFocus multiline placeholder='What do you want to talk about?'
              value={text}
              onChangeText={text=>this.setSharedState({text, modified: true})}
              style={style} 
            />
          </View>
        </View>
      )
    } else {
      return (
        <KeyboardSafeView statusdark>
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
            <ScrollView style={{flex: 1, maxWidth: 500}} 
                contentContainerStyle={{backgroundColor: 'white'}}>
              <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 8, marginRight: 8, justifyContent: Platform.OS == 'web' ? 'flex-end' : 'flex-start'}}>
                <PhotoButton onPress={()=>this.newPhoto()} />
              </View>
              <TextBox part='title'
                placeholder="Title (optional)"
                maxLength={60}
                value={title}
                onChangeText={title=>this.setSharedState({title, modified: true})}
                style={{paddingHorizontal: 10, paddingVertical: 10, borderTopWidth: StyleSheet.hairlineWidth,
                   borderTopColor: '#ddd', marginTop: 8, fontSize: 18}}
              />
              {(!image && !imageBase64) ? null :
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  <Image style={{width: 350, height: 350}} 
                      source={{uri: image ? getUrlForImage({image}) : 'data:image/jpeg;base64,'+imageBase64}}
                    />
                </View>
              }
              <TextBox part='input' 
                autoFocus multiline placeholder='What do you want to talk about?'
                value={text}
                onChangeText={text=>this.setSharedState({text, modified: true})}
                style={style} 
              />
            </ScrollView>
          </View>
        </KeyboardSafeView>
      )
    }
  }
}

export class EditReplyScreen extends React.Component {
  state = {unpublished: true, replyInfo: null, parentInfo: undefined, text: null, users: null, 
      isPrivate: null, broadcasts : null, postInfo: null, submitted: false, modified: false,
      publishingUser: null, publisherName : null, anon: null}

  getPublishingUser() {
    const {postInfo, broadcasts} = this.state;
    const {parent, parentBy} = this.props;
    if (parent) {
      return parentBy || broadcasts[parent].from;
    } else {
      return postInfo.from;
    }
  }

  setSharedState(state) {
    this.setState(state);
    if (global_setButtonState) {
      global_setButtonState(state);
    }
  }


  async editReply() {
    const {navigation, post, postAuthor, group, parent} = this.props;
    const {text, isPrivate, anon} = this.state;
    this.setSharedState({submitted: true});
    const parentBy = this.getPublishingUser();
    var reply = this.props.reply;
    // const parentText = postInfo ? postInfo.text : _.get(broadcasts, [parent, 'text']);
    if (reply) {
      await editReply({text, anon, group, reply, post, postAuthor, parentBy, isPrivate});
    } else if (parent) {
      // const parentBy = this.props.parentBy || broadcasts[parent].from;
      reply = await addReply({text, anon, group, post, postAuthor, /* parentText, */ parent, parentBy, isPrivate,  })
    } else {
      // const parentBy = postInfo.from;
      reply = await addReply({text, anon, group, post, postAuthor, /* parentText,*/ parentBy, isPrivate})
    }
    if (parentBy == getCurrentUser() && !isPrivate) {
      await publishReply({postAuthor, post, group, user: getCurrentUser(), reply, authorName: 'You', text});
    }
    navigation.goBack();
  }

  async componentDidMount() {
    const {post, group, postAuthor, reply, parent, parentBy} = this.props;
    if(reply) {
      watchGroupPostBroadcasts(this, {group, post}, broadcasts => 
        this.setState({text: broadcasts[reply].text || '', 
            unpublished: broadcasts[reply].unpublished || false, 
            isPrivate: broadcasts[reply].isPrivate, 
            anon: broadcasts[reply].name == 'Anonymous',
            broadcasts})
      );
    } else {
      this.setState({text: '', isPrivate: false, replyInfo: {text: '', unpublished: true}});
    }
    if (parent) {
      watchAuthorPostBroadcasts(this, {author:postAuthor, post}, broadcasts => 
        this.setState({broadcasts})
      );
      const publisherName = await getUserName(parentBy);
      this.setState({publisherName});
    } else {
      watchAuthorPostInfo(this, {author:postAuthor, post}, postInfo => this.setState({postInfo, publisherName: postInfo.name}));
    }

    global_buttonCallback = () => this.editReply();
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }


  render() {
    const {parent, reply} = this.props;
    const {broadcasts, anon, text, isPrivate, postInfo, modified, submitted, publisherName} = this.state;

    if (text == null || publisherName == null || (parent && broadcasts == null) || (!parent && postInfo == null)) return <LoadingScreen />
  
    const publishingUser = this.getPublishingUser();

    const style = {
      paddingHorizontal: 10, paddingVertical: 16, paddingTop: 16, flex: 1,
      borderTopWidth: StyleSheet.hairlineWidth, borderTopColor: '#ddd',
      marginTop: 8}

    return (
      <KeyboardSafeView statusdark> 
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
          <View style={{flex: 1, backgroundColor: 'white', maxWidth: 500, alignItems: 'stretch'}}>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 8, marginRight: 8, justifyContent: 'space-between'}}>
              <PublicationToggle admin={publishingUser == getCurrentUser()} 
                  style={{marginLeft: 10}}
                  part='private' allowed={!isPrivate} 
                  onAllowed={allowed => this.setSharedState({isPrivate: !allowed, modified: true})} 
                  publisherName={publisherName} />
              <Toggle  
                  style={{marginHorizontal: 10}}
                  toggled={anon} label='Anonymous'
                  onToggle={anon => this.setSharedState({anon, modified: true})} />
              {Platform.OS != 'web' ? null : 
                <Button part='submit' disabled={!modified || submitted} onPress={()=>this.editReply()}>
                  {reply  
                    ? (submitted ? 'Updating...' : 'Update')
                    : (submitted ? 'Posting...' : 'Post')
                  }
                </Button>
              }
            </View>
            <TextBox part='input' autoFocus multiline placeholder='What is your reply?'
              value={text}
              onChangeText={text=>this.setSharedState({text, modified: true})}
              style={style} 
            />
          </View>
        </View>
      </KeyboardSafeView>
    )
  }
}

