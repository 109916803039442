import React from 'react';
import { Platform, View, Text, ScrollView, StyleSheet, TouchableOpacity, TextInput, InteractionManager} from 'react-native';
import { withNav } from '../basics/provider';
import { getCurrentUserName, getCurrentUser } from '../slowgroup/data';


// export function registerNavTrigger(navAction, callback){
//   if(!registerNavTrigger.navTriggers) {
//     registerNavTrigger.navTriggers = {};
//   }
//   registerNavTrigger.navTriggers[navAction] = callback;
// }
// export function doNavTrigger(navAction, navProps) {
//   if (!registerNavTrigger.navTriggers) return;
//   const navTrigger = registerNavTrigger.navTriggers[navAction];
//   if (navTrigger){
//     navTrigger(navProps);
//   }
// }

var global_inClick = false;

export class FixedWebTouchable extends React.Component {
  state = {pressed: false}

  render() {
    const {onPress, children} = this.props;

    const basicStyle = {cursor: 'pointer'};
    const pressedStyle = {opacity: 0.5, cursor: 'pointer'}

    return (
      <div style={this.state.pressed ? pressedStyle : basicStyle}
        onClick={e => {e.stopPropagation(); onPress()}}  
        onMouseDown={e => {e.stopPropagation(); this.setState({pressed: true})}}
        onMouseUp={() => {this.setState({pressed: false})}}
        onTouchStart={e => {e.stopPropagation(); this.setState({pressed: true})}}
        onTouchEnd={() => this.setState({pressed: false})}
        onTouchMove={() => this.setState({pressed: false})}
        >
        {children}
      </div>
    )
  }
}

var waiting = false;
function singleClick(callback) {
  return async () => {
    if (waiting) {
      console.log('ignoring duplicate click');
    } else {
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, 1000)
      await callback()
      InteractionManager.runAfterInteractions(() => {
        waiting = false;  
      })
    }  
  }
}


export function FixedTouchable({onPress, activeOpacity, children}) {
  if (Platform.OS == 'web') {
    return <FixedWebTouchable onPress={singleClick(onPress)}>{children}</FixedWebTouchable>
  } else {
    return <TouchableOpacity activeOpacity={activeOpacity} onPress={singleClick(onPress)}>{children}</TouchableOpacity>
  }
}


function NavClickableWithoutNav({navAction, navProps, activeOpacity, onPress, signIn=false, onNav, children, navigation, replace}) {
  if (signIn && !getCurrentUser()) {
    return (
      <FixedTouchable onPress={() => navigation.push('signin')}>
        {children}
      </FixedTouchable>
    )
  } else if (onPress) {
    return (
      <FixedTouchable onPress={onPress} >
        {children}
      </FixedTouchable>
    )
  } else if (navAction && replace) {
    return (
      <FixedTouchable onPress={() => {navigation.replace(navAction, navProps)}} >
        {children}
      </FixedTouchable>
    )
  } else if (navAction && onNav) {
    return (
      <FixedTouchable onPress={() => {onNav(); navigation.push(navAction, navProps)}} >
        {children}
      </FixedTouchable>
    )
  } else if (navAction) {
    return (
      <FixedTouchable activeOpacity={activeOpacity} onPress={() => {navigation.push(navAction, navProps)}} >
        {children}
      </FixedTouchable>
    )
  } else {
    return <View>{children}</View>
  }
}
export const NavClickable = withNav(NavClickableWithoutNav)


export function CommaSep({children}) {
  var outItems = [];
  var first = true;
  var count = 0;
  var length = React.Children.count(children);
  React.Children.forEach(children, item => {
    count++
    if (!first) {
      if (length > 2) {
        outItems.push(<Text key={count}>, </Text>)
      } else{
        outItems.push(<Text key='space'> </Text>)
      }
    }
    if (count > 1 && count == React.Children.count(children)) {
      outItems.push(<Text key='and'>and </Text>)
    }
    first = false
    outItems.push(item);
  })
  return <Text>{outItems}</Text>
}

export function HeaderTitle({children, backgroundColor}) {
  return (
    <Text style={{fontSize: 20, fontWeight: 'bold', color: backgroundColor ? 'white' : 'black'}} >
      {children}
    </Text>
  )
}

export function HeaderHoriz({children}) {
  return <View style={{flexDirection: 'row', alignItems: 'center'}}>{children}</View>
}
