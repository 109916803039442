
import firebase, { INTERNAL_TIMESTAMP } from './firebase'
import _ from 'lodash';
/* global fetch */



export function fetchSingle(path, fallback = {}) {
	return firebase.database().ref(path).once('value').then(snap => snap.val() || fallback);
}

// TODO: Better offline approach. Currently we watch everything twice to keep 
// data cached.
export function watchSingle(obj, path, callback, fallback = {}) {
	if (typeof(callback) != 'function') {
		console.error('bad watch', {obj, path, callback, cbtype: typeof(callback)});
		throw new Error('bad watch');
	}

	const func = snap => callback(snap.val() || fallback);
	const ref = firebase.database().ref(path);
	if (!obj.watchers) {obj.watchers = []}

	ref.on('value', () => {});

	obj.watchers.push({ref, func});
	return ref.on('value', func, error => {
		console.error(error)
		throw new Error(error);
	});
}

export function internalReleaseWatchers(obj) {
	if (obj.watchers) {
		obj.watchers.forEach(({ref, func}) => {
			ref.off('value', func);
		})
	}
}

export function pushObject(path, item) {
	return firebase.database().ref(path).push(item);
}

export function removeObject(path) {
	return firebase.database().ref(path).set(null)
}

export function setSingle(path, value) {
	// console.log('setSingle:', path, value);
	return firebase.database().ref(path).set(value);
}

export function updateSingle(path, value) {
	// console.log('setSingle:', path, value);
	return firebase.database().ref(path).update(_.pickBy(value, v => v !== undefined));
}

export function setMultiple(updates) {
	return firebase.database().ref().update(updates);
}

export function newKey() {
	return firebase.database().ref().push().key;
}


export async function uploadImage({base64data, isThumb, userId, key}) {
	return await fetch('https://talkful.org/uploadImage', {
		method: 'POST', mode: 'cors', cache: 'no-cache',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify({base64data, isThumb, userId, key})
	})
}


export async function pushFile(path, blob, extension = '') {
	const storage = firebase.storage();
	const key = newKey();
	const ref = storage.ref().child(path + '/' + key + extension);
	await ref.put(blob, {contentType: 'image/jpeg'});
	return key + extension;
}

export async function putFile(path, blob) {
	const storage = firebase.storage();
	const ref = storage.ref().child(path);
	await ref.put(blob);
}

export function getUrlForFile(path) {
	const pathAsParam = encodeURIComponent(path);
	return 'https://firebasestorage.googleapis.com/v0/b/slowgroup-1.appspot.com/o/' + pathAsParam + '?alt=media';
}

export function signOut() {
  firebase.auth().signOut();
}

export const SERVER_TIMESTAMP = INTERNAL_TIMESTAMP; 
