import React from 'react';
import { watchGroupInfo, getSingleGroup, watchAllUsers, watchGroupUsers, getCurrentUser, inviteUserToGroup, watchNotifs, watchGroupInvited, releaseWatchers } from './data';
import { LoadingScreen, HelpBubble, Button, baseColor, ScreenContentScroll, appDomain, appName, ScreenContentFlatList, DefaultImage, UserIcon } from './ui';
import { StyleSheet, Text, View, Platform, Share, Clipboard } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import { NavClickable, FixedTouchable } from '../components/basics';
import { doesTextMatchPrefix } from './util';
import Feather from 'react-native-vector-icons/Feather';
import _ from 'lodash';
import { Catcher } from '../basics/catcher';
import { SearchBox } from '../components/SearchBox';

export class InviteHeader extends React.Component {
  state = {name: 'Group'};
  async componentDidMount() {
    const {group} = this.props;
    const groupInfo = await getSingleGroup(group);    
    this.setState({name: groupInfo.name});
  }
  render () {
    // const color = Platform.OS == 'web' ? 'black' : 'white';
    const color = 'white';
    return (
      <View style={{alignItems: Platform.OS == 'web' ? undefined : 'center'}}>
        <Text style={{color, fontSize: 15}}>
          Invite new Members
        </Text>
        <Text style={{fontSize: 12, color}}>
          in {this.state.name}
        </Text>
      </View>
    )
  } 
}

function GroupUrlCopyBox({group}) {
  const groupUrl = appDomain + '/group/' + group;
  return (
    <View style={{backgroundColor: 'white', padding: 10, marginVertical: 8,
        borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, 
      }}>
      <Text style={{marginBottom: 4, fontWeight: 'bold'}}>
        Invite someone to this group by sending them a link
      </Text>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, backgroundColor: 'white', borderColor: '#bbb', borderWidth: 1, padding:8, marginRight: 10}}>
          <Text selectable>{groupUrl}</Text>
        </View>
        <Button onPress={() => Clipboard.setString(groupUrl)}>Copy to Clipboard</Button>
      </View>
    </View>
  )
}

function ShareButton({onPress}) {
  return (
    <FixedTouchable onPress={onPress}>
      <View style={{flexDirection: 'row', justifyContent: 'flex-end', padding: 8}}>
        <View style={{flexDirection:'row', alignItems: 'center', 
            justifyContent: 'flex-start', backgroundColor: baseColor,
            padding: 8}}>
          <Entypo name='share-alternative' size={16} color='white'/>
          <Text style={{marginLeft: 8, flexGrow: 0, color: 'white'}}>Share Group</Text>
        </View>
      </View>
    </FixedTouchable> 
  )
}

function UserToInvite({user, userInfo, group, member, invited}) {
  const name = _.get(userInfo, 'name', '?');
  if (user == getCurrentUser()) return null;
  return (
    <View style={{marginVertical: 10, marginHorizontal: 16, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
        <UserIcon userName={name} user={user} size={30} />
        <Text style={{marginLeft: 10, fontSize: 16, fontWeight: '500'}}>{name}</Text>
      </View>
      {(member || invited)
      ? <View><Text style={{color: '#666'}}>{invited ? 'Invited' : 'Member'}</Text></View> 
      : <FixedTouchable part='invite' onPress={()=>inviteUserToGroup({group, user})}>
          <View style={{backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4}}>
            <Text style={{color: 'white'}}>Invite</Text> 
          </View>
        </FixedTouchable>
      }
    </View>
  )
}


// TODO: Be smarter about showing all users
export class InviteScreen extends React.Component {
  state = {groupInfo: null, users: null, groupUsers: null, search: null, notifs: null, invited: null}
  componentDidMount() {
    const {group} = this.props;
    watchGroupInfo(this, group, groupInfo => this.setState({groupInfo}));
    watchAllUsers(this, users => this.setState({users}));
    watchGroupUsers(this, group, groupUsers => this.setState({groupUsers}));
    watchGroupInvited(this, group, invited => this.setState({invited}))
    watchNotifs(this, notifs => this.setState({notifs}));
  }
  componentWillUnmount() {releaseWatchers(this)}

  async showSharePopup() {
    const {group} = this.props;
    const {groupInfo} = this.state;
    const groupUrl = appDomain + '/group/' + group;
    const result = await Share.share({
      message: 'Join the group "' + groupInfo.name + '" on ' + appName + ': ' + groupUrl
    });
  }

  render() {
    const {group} = this.props;
    const {groupInfo, users, search, groupUsers, notifs, invited} = this.state;
    if (!groupInfo || users == null || groupUsers == null || invited == null) return <LoadingScreen/>
    var visibleUsers = [];
    if (search) {
      const matchingUsers = Object.keys(users).filter(u => doesTextMatchPrefix(users[u].name, search))
      visibleUsers = _.sortBy(matchingUsers, u => users[u].name.toLowerCase());
    } else {
      const notifUsers = _.mapKeys(notifs || {}, n => n.from);
      visibleUsers = Object.keys(notifUsers);
      // visibleUsers = _.sortBy(Object.keys(notifUsers), u => users[u].name.toLowerCase());
    }
    return (
      <ScreenContentFlatList style={{backgroundColor: 'white'}} data={[
        {key: '_share', value: () =>
          (Platform.OS == 'web' ?
            <GroupUrlCopyBox group={group} />
          : 
            <ShareButton onPress={()=>this.showSharePopup()} />
          )
        },
        {key: '_search', value: () =>
          <SearchBox search={search} part='search' placeholder='Search People to Invite' onChangeText={search => this.setState({search})} />         
        },
        ...visibleUsers.map(u => ({key: u, value: () =>
          <Catcher>
            <UserToInvite part={u} key={u} user={u} userInfo={users[u]} group={group} 
                member={groupUsers[u]} invited={_.get(invited, u)} />
          </Catcher>
        }))
      ]} />
    )
  }
}