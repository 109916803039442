import React from 'react';
import { Text, View, Platform, Share } from 'react-native';
import { watchGroupPostInfo, watchGroupUsers, watchGroupChats, watchGroupLikes, getCurrentUser, tagPersonOnPost, releaseWatchers, getPostTags, watchPostPeopleTagged, watchAuthorPostInfo, watchLastMessages, watchAllUserNames, fetchAuthorPostInfo, watchMyGroups, sharePostToGroup, watchAuthorPostGroupShares, watchAuthorPostUserShares } from './data';
import { LoadingScreen, ScreenContentFlatList, DefaultImage, baseColor, appDomain, UserIcon, GroupIcon } from './ui';
import { PostPreview } from './Group';
import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import _ from 'lodash';
import { doesTextMatchPrefix, sortKeysBy, filterObject } from './util';
import { FixedTouchable } from '../components/basics';
import { SearchBox } from '../components/SearchBox';


function PersonToTag({user, userName, group, post, postAuthor, tagged}) {
  const name = userName;
  // const name = _.get(users, [user, 'name'], '?')
  if (user == getCurrentUser()) return null;

  return (
    <View style={{marginVertical: 10, marginHorizontal: 16, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
        <UserIcon userName={name} user={user} size={30} />
        <Text style={{marginLeft: 10, fontSize: 16, fontWeight: '500'}}>{name}</Text>
      </View>
      {tagged
      ? <View><Text style={{color: '#666'}}>Shared</Text></View> 
      : <FixedTouchable part='invite' onPress={()=>tagPersonOnPost({group, post, postAuthor, user})}>
          <View style={{backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4}}>
            <Text style={{color: 'white'}}>Share</Text> 
          </View>
        </FixedTouchable>
      }
    </View>
  )
}

function GroupToTag({group, groupStatus, post, postAuthor, tagged = false}) {
  const isAdmin = groupStatus.mode == 'admin'
  return (
    <View style={{marginVertical: 10, marginHorizontal: 16, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <View style={{flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'flex-start'}}>
        <GroupIcon groupName={groupStatus.name} group={group} size={30} />
        <Text numberOfLines={1} style={{flex: 1, marginLeft: 10, fontSize: 16, fontWeight: '500'}}>{groupStatus.name}</Text>
      </View>
      {tagged
      ? <View><Text style={{color: '#666'}}>{isAdmin ? 'Published' : 'Submitted'}</Text></View> 
      : <FixedTouchable part='invite' onPress={()=>sharePostToGroup({group, post, postAuthor, isAdmin})}>
          <View style={{backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4}}>
            <Text style={{color: 'white'}}>{isAdmin ? 'Publish' : 'Submit'}</Text> 
          </View>
        </FixedTouchable>
      }
    </View>
  )

}

function ShareButton({onPress}) {
  if (Platform.OS == 'web') return null;
  return (
    <FixedTouchable onPress={onPress}>
      <View style={{flexDirection: 'row', justifyContent: 'flex-end', padding: 8}}>
        <View style={{flexDirection:'row', alignItems: 'center', 
            justifyContent: 'flex-start', backgroundColor: baseColor,
            padding: 8}}>
          <Entypo name='share-alternative' size={16} color='white'/>
          <Text style={{marginLeft: 8, flexGrow: 0, color: 'white'}}>Share Link To Post</Text>
        </View>
      </View>
    </FixedTouchable> 
  )
}

function getThingTime(thing) {
  // console.log('thingTime', key, thing);
  return _.max([thing.time || 0, thing.lastTime || 0]);
}

export class TagPeopleScreen extends React.Component {
  state = {authorShares: null, groupShares: null, posts: null, groups: null, users: null, tagged: null, postInfo: null, search: null, userLasts: null, userNames: null}
  async componentDidMount() {
    const {author, post} = this.props;
    watchAuthorPostInfo(this, {author, post}, postInfo => this.setState({postInfo: postInfo || {}}));
    watchLastMessages(this, userLasts => this.setState({userLasts}));
    watchAllUserNames(this, userNames => this.setState({userNames}));
    watchMyGroups(this, groups => this.setState({groups}))

    // if (group) {
    //   watchGroupUsers(this, group, users => this.setState({groupUsers: users || {}}));
    // } else {
    //   this.setState({groupUsers: {}});
    // }
    watchAuthorPostUserShares(this, {author, post}, userShares => this.setState({userShares}));
    watchAuthorPostGroupShares(this, {author, post}, groupShares => this.setState({groupShares})); 
  }
  componentWillUnmount() {releaseWatchers(this)}

  async showSharePopup() {
    const {author,post} = this.props;
    const postUrl = appDomain + '/post/' + author + '/' + post;
    const postInfo = await fetchAuthorPostInfo({author, post});
    console.log('showSharePopup', author, post, postUrl);
    const result = await Share.share({
      message: (postInfo.title || 'A Post on Talkful') + ': ' + postUrl
    });
  }

  render() {
    const {group, post} = this.props;
    const {userLasts, userShares, groupShares, postInfo, groups, search, userNames} = this.state;
    if (postInfo == null || groups == null || userLasts == null || userShares == null || groupShares == null) return <LoadingScreen />
    // const users = userLasts;
    // var things = {...userLasts, ...groups};
    var things = groups;
    if (search) {
      // const shownUsers = filterObject(userNames, (u,k) => doesTextMatchPrefix(_.get(userNames,k),search));
      const shownGroups = filterObject(groups, g => doesTextMatchPrefix(g.name, search));
      // things = {...shownUsers, ...shownGroups};
      things = shownGroups;
    }

    // const postAuthorInfo = users[postInfo.from];
    const sortedThings = sortKeysBy(things, t => getThingTime(t)).reverse();

    return (
      <ScreenContentFlatList innerStyle={{backgroundColor: 'white'}} data={[
        {key: '_sharebutton', value: () => <ShareButton onPress={()=>this.showSharePopup()} />},
        {key: '_search', value: () => <SearchBox search={search} onChangeText={search=>this.setState({search})} />},
        ...sortedThings.map(u => ({key: u, value: () => 
          userNames[u] ? 
            <PersonToTag user={u} userName={userNames[u]} group={group} post={post} postAuthor={postInfo.from}
                tagged={_.get(userShares,u)} />
          :
            <GroupToTag group={u} groupStatus={groups[u]} post={post} postAuthor={postInfo.from} tagged={groupShares[u]} />      
        }))
      ]} />
    )
  }
}