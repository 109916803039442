import { Platform } from 'react-native';
const Sentry = require('@sentry/browser');

/* global window */

if (window.location.hostname == 'talkful.org'){
  Sentry.init({ dsn: 'https://a40c84cc762949f3a633a877d3d2eece@sentry.io/1269763' });
  console.log('Live mode - logging enabled')
  console.log('OS: ', Platform.OS)
} else {
  console.log('Dev mode - logging disabled')
}


// HACK: We log a "sentry is shit" exception as well, because otherwise extra isn't logged
const Logger = {
  init: () => {},
  setUserContext: (user) => Sentry.configureScope((scope) => scope.setUser(user)),
  captureMessage: Sentry.captureMessage,
  captureException: (err, extra) => {
    Sentry.configureScope(scope => {
      scope.setExtra('extra', extra);
    });
    Sentry.captureException(err);
  }
}

export default Logger;
