
import React from 'react';
import { TouchableOpacity, Image, SafeAreaView, Platform, StyleSheet, Text, View } from 'react-native';
import {screens, tabs} from '../slowgroup';
import { setWebNavigator } from './provider';
import { NavClickable, FixedTouchable, HeaderTitle } from '../components/basics';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { reloadIfVersionChanged } from './versioncheck';
import { baseColor, appIcon } from '../slowgroup/ui';
import _ from 'lodash';
import { HeaderProfile } from '../slowgroup/Account';
import { Catcher } from './catcher';
import { watchWindowUrlChange } from './web';

/* global window */

const highlightColor = 'rgb(29, 161, 242)';
const inactiveColor = 'rgb(102,117,127)';

function ProductLogo(){
  return (
    <Image style={styles.productLogo} source={{uri:appIcon}} />
  )
}

function TabIcon({icon, selected}) {
  return React.createElement(icon.family, 
      {name: icon.name, size: 20, style: {height: 20}, color: selected ? highlightColor : inactiveColor})
}

export function HeaderTab({tab, selected}) {
  const color = selected ? highlightColor : inactiveColor;
  return (
    <NavClickable navAction={tab.screen}>
      <View style={selected ? styles.selectedHeaderTab : styles.headerTab}>
        {tab.icon(color)}
        {/* <TabIcon icon={tab.icon} selected={selected} /> */}
        <Text style={[styles.headerTabText, {color}]}>{tab.name} </Text> 
      </View>
    </NavClickable>
  )
}

export function SubTab({tab, screen, navProps, selected}) {
  const color = selected ? highlightColor : inactiveColor;
  return (
    <NavClickable navAction={screen} navProps={{...navProps, tab}} replace>
      <View style={selected ? styles.selectedHeaderTab : styles.headerTab}>
        <Text style={[styles.headerTabText, {color}]}>{tab} </Text> 
      </View>
    </NavClickable>
  )
}


function TopBar ({navigation, headerRight, history, subtabs, tab, navProps, children, screen, backgroundColor}) {
  if (screen == 'feed' || screen == 'home' || screen == 'people' || screen == 'notifs' || screen == 'posts' || screen == 'updates') {
    return (
      <View style={styles.topbar}>
        <ProductLogo />
        {tabs.map(tab => 
          <HeaderTab key={tab.name} selected={tab.screen == screen} tab={tab} />
        )}
        <View style={styles.spacer}></View>
        <HeaderProfile />
      </View>
    )  
  } else {
    return (
      <View>
        <View style={[styles.backbar, {backgroundColor: backgroundColor || 'white'}]}>
          <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
            <FixedTouchable onPress={() => navigation.goBack()} >
              <Ionicons name='md-arrow-back' size={30} color={backgroundColor ? 'white' : 'black'}/>
            </FixedTouchable>
            <View style={{marginLeft: 16}}>
              {children}
            </View>
          </View>
          {/* {headerRight} */}
        </View>
      </View>

    )
  }
}


function encodeQueryData(data) {
  let ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

function decodeQueryData() {
  const searchpath = _.get(window, ['location','search'],'').trim();
  if (searchpath != '') {
    return searchpath.slice(1).split('&')
    .reduce(function _reduce (/*Object*/ a, /*String*/ b) {
        b = b.split('=');
        a[b[0]] = decodeURIComponent(b[1]);
        return a;
    }, {});
  } else {
    return {}
  }
}

export function decodeUrl() {
  const pathname = _.get(window,['location','pathname'],'').slice(1);
  var [screen, first, second] = pathname.split('/');
  var props = decodeQueryData();
  if (screen == 'invite' && first) {
    props = {...props, inviteId: first};
  } else if (screen == 'group' && first) {
    props = {...props, group: first};
  } else if (screen == 'post' && first) {
    props = {...props, author: first, post: second}
  } else if (screen == 'p' && first) {
    props = {...props, userId: first};
    screen = 'profile'
  } else if (screen == 'freq' || screen == 'freqtest') {
    props = {...props, first, second}
  }
  return {screen, props}
}

function setWindowState(history) {
  reloadIfVersionChanged();
  if (history.length >= 1) {
    const {screen, props} = history[history.length - 1];
    switch (screen) {
      case 'group': {
        window.history.pushState({}, '', '/group/' + props.group)
        break;
      }
      case 'invite': {
        window.history.pushState({}, '', '/invite/' + props.inviteId)
        break;
      }
      // case 'post': {
      //   window.history.pushState({}, '', '/post/' + props.group + '/' + props.post)
      //   break;
      // }

      // case 'profile': {
      //   window.history.pushState({}, '', '/p/' + props.userId)        
      //   break;
      // }
      default: {
        const nonNullProps = _.pickBy(props, p => p)
        window.history.pushState(props, '','/' + screen + '?' + encodeQueryData(nonNullProps));
      }
    }
  } else {
    window.history.pushState({}, '', '/');
  }
}


const defaultScreen = 'home';

export class WebNavigator extends React.Component {
  state = {history: []};
  navigation = {
    getScreen: () => {
      const topEntry = this.state.history[this.state.history.length -1];
      if (topEntry) {
        return topEntry.screen;
      } else {
        return defaultScreen;
      }
    },
    goBack: () => {
      const newHistory = this.state.history.slice(0, -1);
      this.setState({history: newHistory})
      setWindowState(newHistory);
      const latest = newHistory[newHistory.length - 1];
      this.props.onNavigate(_.get(latest,'screen',null));
    },
    push: (screen, props={}) => {
      const newHistory = [...this.state.history, {screen, props}]
      this.setState({history: newHistory})
      setWindowState(newHistory)
      this.props.onNavigate(screen);
    },
    replace: (screen, props={}) => {
      const newHistory = [...this.state.history.slice(0, -1), {screen, props}]
      this.setState({history: newHistory})
      setWindowState(newHistory)
      this.props.onNavigate(screen);
    },
    gotoRoot: (screen, props={}) => {
      const newHistory = [{screen, props}]
      this.setState({history: newHistory})
      setWindowState(newHistory)
      this.props.onNavigate(screen);
    },
    setParams: (props) => {
      console.log('setParams not yet implemented on web')
    },
    isFocused: () => true
  };

  navigateToWindowUrl() {
    const {screen, props} = decodeUrl();
    this.props.onNavigate(screen);
    if (screen) {
      this.setState({history: [{screen, props}]});
    }
    reloadIfVersionChanged();

  }

  componentDidMount() {
    this.navigateToWindowUrl();

    watchWindowUrlChange(() => this.navigateToWindowUrl());
 }


  render () {
    const {history} = this.state;
    setWebNavigator(this.navigation);

    var topState = {screen: defaultScreen, props: {}};
    if (history.length > 0) {
      topState = history[history.length - 1];
    }
    var topConfig = screens[topState.screen];
    if (!topConfig) {
      topConfig = screens[defaultScreen];
    }
    const screenProps = Object.assign({}, topState.props, {navigation: this.navigation});
    const element = React.createElement(topConfig.screen, screenProps);
    const backgroundColor = topConfig.background ? topConfig.background(topState.props) : null
    const headerTitle = topConfig.title ? 
      <HeaderTitle backgroundColor={backgroundColor}>{topConfig.title(topState.props)}</HeaderTitle> 
      : (topConfig.headerTitle && React.createElement(topConfig.headerTitle,topState.props));
    // const title = topConfig.title(topState.props)
    const headerRight = !topConfig.headerRight ? null : 
      React.createElement(topConfig.headerRight,topState.props);
    const subtabs = topConfig.subtabs;
    const tab = topState.props.tab || topConfig.defaultTab;

    return (
      <SafeAreaView style={styles.safebox}>
        {/* <StatusBar barStyle='light-content' backgroundColor='rgb(33,150,243)' /> */}
        <TopBar screen={topState.screen} tab={tab} navProps={topState.props} subtabs={subtabs} navigation={this.navigation} 
            history={this.state.history} backgroundColor={backgroundColor} headerRight={headerRight}>
          {headerTitle}
        </TopBar>
        <Catcher style={{flex: 1}}>{element}</Catcher>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  productLogo: {
    width: 36,
    height: 32,
    marginRight: 32,
    marginLeft: 8,
    // imageResizeMode: 'contain',
  },
  profilePhoto: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 8,
  },
  spacer: {
    flex: 1
  },
  headerTab: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
    paddingBottom: 12,
    paddingTop: 10,
  },
  selectedHeaderTab: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
    borderBottomColor: highlightColor,
    borderBottomWidth: 3,
    paddingBottom: 9,
    paddingTop: 10,
  },
  headerTabText: {
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: '700',
    marginLeft: 6,
  },
  hbox: {
    flexDirection: 'row',
    flex: 1,
    // backgroundColor: 'blue',
    backgroundColor: "#e6ecf0",
    justifyContent: 'space-around',
  },
  mainbox: {
    flex: 1,
    backgroundColor: 'green',
    // backgroundColor: "#e6ecf0",
    flexDirection: 'column',
    alignItems: 'center'
  },
  safebox: {
    flex: 1,
    backgroundColor: "#e6ecf0",
    // backgroundColor: 'rgb(33,150,243)',
    // backgroundColor: 'red'
  },
  backbar: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomColor: '#bbb',
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingHorizontal: 12,
    paddingVertical: 4
  },
  topbar: {
    // height: 32 + getStatusBarPadding(),
    // paddingTop: getStatusBarPadding(),
    // padding: 8,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    // backgroundColor: 'rgb(33,150,243)',
    borderBottomColor: '#bbb',
    borderBottomWidth: StyleSheet.hairlineWidth,
    // flex: 1
  },
  subtabs: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottomColor: '#bbb',
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingLeft: 16
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userName: {
    marginRight: 8,
    fontSize: 18,
    color: '#fff',
  },
  userPill: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logIn: {
    color: '#fff',
    fontSize: 18,
    marginRight: 8
  },
  goback: {
    color:'#fff',
    marginLeft: 4,
    fontSize: 18
  },
  slowTalkName: {
    color: '#fff',
    fontSize: 18,
    marginLeft: 8
  },
  bottomBar: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 4,
    borderTopColor: '#ddd',
    borderTopWidth: StyleSheet.hairlineWidth,
    backgroundColor: 'white'
  }
});
