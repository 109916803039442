import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.locale(en);
const timeAgo = new TimeAgo('en-US');


export function formatTimeAgo(time = 1532242922000, mode = undefined) {
  return timeAgo.format(time, mode);
}

const dayLength = 86400000;
// export function formatTalkbeatTime(time) {
//   if ((Date.now() - time) < dayLength) {
//     return formatTimeAgo(time);
//   } else {
//     return formatTimeAgo(time, 'twitter');
//   }
// }

// export function getExtensionForUri(uri) {
//   const ext = uri.split('.').pop();
//   if (ext) {
//     return '.' + ext;
//   } else {
//     return null;
//   }
// }

// export function childOrEmpty(parent,keys) {
//   if (keys.length == 0) {
//     return parent;
//   } else {
//     const firstKey = keys[0];
//     const firstVal = parent[firstKey];
//     if (firstVal) {
//       return childOrEmpty(firstVal, keys.slice(1));
//     } else {
//       return {};
//     }
//   }
// }

export function mapKeyed(obj, cb) {
  if (obj) {
    return Object.keys(obj).map((key) =>
      cb(key, obj[key])
    )
  } else {
    return []
  }
}

// export function sortByValue(obj, func) {
//   const sortValueMap = linksToMap(obj, func)
//   const keys = Object.keys(sortValueMap);
//   return keys.sort((a,b) => sortValueMap[a] - sortValueMap[b]);
// }

// export function mapKeyedByTime(obj, func, earliestFirst=false) {
//   if (obj) {
//     const keys = Object.keys(obj);
//     var sorted;
//     if (earliestFirst) {
//       sorted = keys.sort((a,b) => (obj[b].time || 0) - (obj[a].time || 0));
//     } else {
//       sorted = keys.sort((a,b) => (obj[a].time || 0) - (obj[b].time || 0));
//     }
//     return sorted.map(key => func(key, obj[key]));
//   }
// }


export function mapObject(obj, mapper) {
  var newObject = {};
  Object.keys(obj).map((key, idx) => {
    newObject[key] = mapper(key, obj[key]);
  });
  return newObject;
}

// export function removeNulls(obj) {
//   return filterObject((val) => val) 
// }

export function followPathString(path, data){
  const parts = path.split('/');
  return followPath(parts, data);
}

export function modifyPathString(path, value, data) {
  const parts = path.split('/');
  return modifyPath(parts, value, data);
}

export function followPath(parts, data){
  if (parts.length === 0) {
    return data;
  } else if (data === undefined) {
    return undefined;
  } else {
    const head = parts[0];
    const tail = parts.slice(1);
    if (head === '') {
      return followPath(tail, data);
    } else {
      return followPath(tail, data[head]);
    }
  }
}

export function modifyPath(parts, value, data) {
  // console.log('modifyPath', {parts, value});
  if (parts.length === 0) {
    return value;
  } else {
    const head = parts[0];
    const tail = parts.slice(1);
    if (head === '') {
      return modifyPath(tail, value, data);
    } else {
      return Object.assign({}, data, {[head]: modifyPath(tail, value, data[head] || {})});
    }
  }
}

export function filterObject(obj, func) {
  if (!obj) {
    return {};
  }
  var newObject = {};
  Object.keys(obj).map((key, idx) => {
    if(func(obj[key], key)) {
      newObject[key] = obj[key];
    }
  });
  return newObject;
}

export function filterObjectByProp(obj, prop, value) {
  var newObject = {};
  Object.keys(obj).map((key, idx) => {
    const child = obj[key];
    if (child[prop] === value) {
      newObject[key] = obj[key];
    }
  });
  return newObject;
}

export function firstMatchingObjectKey(obj, func) {
  return Object.keys(obj || {}).find((key) => func(obj[key]));
}

// export function removeDups(obj, func) {
//   var newObject = {};
//   var done = {};
//   Object.keys(obj).map((key, idx) => {
//     const essence = func(obj[key]);
//     if (!done[essence]) {
//       done[essence] = true;
//       newObject[key] = obj[key];
//     }
//   });
//   return newObject;
// }

export function removeKeyDups(keys, func) {
  var newKeys = [];
  var done = {};
  keys.forEach(key => {
    const essence = func(key);
    if (!done[essence]) {
      done[essence] = true;
      newKeys.push(key);
    }
  });
  return newKeys
}


export function removeKey(obj, key) {
  const {[key]: drop, ...rest} = obj;
  return rest;
}

export function addKey(obj, key, value=true) {
  return {...obj, [key]:value}
}

export function flattenNestedObject(parent, keyProp) {
  const withKeyAdded = mapObject(parent, (key, child) => 
    mapObject(child, (childKey, item) => 
      Object.assign({}, item, {[keyProp]: key})));
  const asArray = mapKeyed(withKeyAdded, (key, child) => child);
  return Object.assign({}, ...asArray);
}

// export function singleObjectItem(obj) {
//   const key = Object.keys(obj)[0];
//   return obj[key];
// }

// export function singleObjectProp(obj, prop, defaultValue) {
//   const item = singleObjectItem(obj);
//   if (item) {
//     return item[prop]
//   } else {
//     return defaultValue
//   }
// }

// export function singleObjectKey(obj) {
//   return Object.keys(obj)[0];
// }

// export function objectHasItems(obj) {
//   return Object.keys(obj).length != 0;
// }

export function linksToMap(obj, func) {
  var outMap = {}
  Object.keys(obj).forEach(linkKey => {
    const {key, value} = func(linkKey, obj[linkKey]);
    outMap[key] = value
  })
  return outMap;
}

export function indexByProp(obj, prop) {
  var outMap = {}
  Object.keys(obj).forEach(linkKey => {
    if (obj[linkKey][prop]) {
      outMap[obj[linkKey][prop]] = linkKey
    }
  })
  return outMap;
}

// export function indexPropToVal(obj, keyProp, valProp) {
//   var outMap = {}
//   Object.keys(obj).forEach(linkKey => {
//     if (obj[linkKey][keyProp]) {
//       outMap[obj[linkKey][keyProp]] = obj[linkKey][valProp]
//     }
//   })
//   return outMap;
// }



export function groupByProp(obj, propKey) {
  var outMap = {}
  Object.keys(obj).forEach(linkKey => {
    const propVal = obj[linkKey][propKey];
    if (!outMap[propVal]) {
      outMap[propVal] = {}
    }
    outMap[propVal][linkKey] = obj[linkKey]; 
  })
  return outMap;
}

// export function indexKeyArray(arr, obj, func) {
//   var outMap = {}
//   arr.forEach(linkKey => {
//     const outKey = func(obj[linkKey]);
//     if (outKey) {
//       outMap[outKey] = linkKey;
//     }
//   })
//   return outMap;
// }


export function indexByPropMax(obj, keyprop, maxprop) {
  var outMap = {}
  Object.keys(obj).forEach(childId => {
    const child = obj[childId];
    const key = child[keyprop];
    const value = child[maxprop];
    if (key && value) {
      const current = outMap[key];
      if(!current || current < value) {
        outMap[key] = value;
      }
    }
  })
  return outMap;
}

// export function arrayToMapOfTrue(keys) {
//   var outMap = {}
//   keys.forEach(key => {
//     outMap[key] = true;
//   })
//   return outMap;
// }

export function nullMax(x,y) {
  if (x) {
    if (y) {
      if (x > y) {
        return x;
      } else {
        return y;
      }
    } else {
      return x;
    }
  } else {
    return y;
  }
}

// export function maxMerge(obj1, obj2) {
//   var out = {...obj1};
//   Object.keys(obj2).forEach(key => {
//     const current= out[key];
//     if(!current || current < obj2[key]) {
//       out[key] = obj2[key];
//     }
//   })
//   return out;
// }

// export function maxMergeList(objs) {
//   var out = {};
//   objs.forEach(obj => {
//     Object.keys(obj).forEach(key => {
//       const current= out[key];
//       if(!current || current < obj[key]) {
//         out[key] = obj[key];
//       }
//     })
//   })

//   return out;
// }

// export function propMax(obj, prop) {
//   var max = null;
//   Object.keys(obj).forEach(key => {
//     const child = obj[key];
//     const thisVal = child[prop];
//     if (!max) {
//       max = thisVal;
//     } else if (thisVal && thisVal > max) {
//       max = thisVal;
//     }
//   })
//   return max;
// }


// export function mapToSortedKeys(obj) {
//   return Object.keys(obj).sort((a,b) => obj[a] - obj[b]);  
// }

// export function sortByValue(obj, func) {
//   const sortValueMap = linksToMap(obj, func)
//   const keys = Object.keys(sortValueMap);
//   return keys.sort((a,b) => (sortValueMap[a] || 0) - (sortValueMap[b] || 0));
// }

// export function sortByProp(obj, propName) {
//   return sortByValue(obj, (key, item) => ({key, value: item[propName]}))
// }

export function hoistFirst(list, func) {
  var matched = [];
  var rest = [];
  list.forEach((item) => {
    if (func(item)) {
      matched.push(item);
    } else {
      rest.push(item);
    }
  })
  if (matched.length > 0) {
    return [...matched, ...rest]
  } else {
    return list;
  }
}

// export function partitionArray(list, func, count=false) {
//   var first = []
//   var second = []
//   var added = 0;
//   list.forEach(item => {
//     if ((!count || (added < count)) && func(item)){
//       first.push(item);
//       added = added+1;
//     } else {
//       second.push(item)
//     }
//   })
//   return [first, second];
// }

