import React from 'react';
import { View, Text, Platform, StyleSheet } from 'react-native';
import { getCurrentUser, watchMessages, addMessage, watchGroupPostInfo, getSingleGroup, getSingleGroupUser, cancelPublish, publishMessages, markChatRead, watchGroupPostLikes, watchGroupLikes, watchGroupBroadcasts, watchGroupUsers, askToPublish, releaseWatchers, watchGroupPostBroadcasts, watchGroupChats, publishReply, publishBroadcast, likePost, getTalkTreeLastRead, markTalkTreeRead, watchGroupPostReplyLikes, likeReply, watchGroupPostFollows, markAppUsed, watchSingleGroup, watchPostPeopleTagged, fetchGroupPostInfo, watchAuthorPostInfo, watchAuthorPostBroadcasts, watchAuthorPostReplyLikes, watchAuthorPostFollows, getCurrentUserName, watchMyGroupStatus, watchPostInfo, getGroupName, watchGroupInfo, watchAuthorPostGroupShares, watchAuthorPostUserShares, watchAuthorPostLikes, watchHelpCleared, watchAnonIdentity, watchMyGroups } from './data';
import { chatText, LoadingScreen, meMessageColor, Button, baseColor, linkColor, CleanButton, OfflineWarning, ScreenContentFlatList, baseBackgroundColor, unpublishedColor, getNodeColor, getNodeVerb, HelpBubble, LinkText, CollapsedNode, PostIcon, ScreenContentScroll, HeaderProfileBare, nameColor, UserIcon } from './ui';
import { sortKeysBy, plural, getFirstName, getPostVerb, filterObject, pluralNum } from './util';
import IonIcons from 'react-native-vector-icons/Ionicons';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { FixedTouchable, NavClickable, CommaSep } from '../components/basics';
import _ from 'lodash';
import { ActionMenu } from '../components/ActionMenu';
import { formatTimeAgo } from '../basics/util';
import { PostPreview, ResponsePreview, NewResponsePreview, CompactResponsePreview, ChatPreview } from './Post';
import { Catcher } from '../basics/catcher';
import { GroupPreview, GroupSummary } from './GroupList';
import { ScrollView } from 'react-native-gesture-handler';
import { chooseFirstHelpPrompt, Help, HelpReplyPrivately, HelpFollowPost, PublishedAppearHere, HelpPublishedAppearHere } from './HelpPrompt';


// function ShareBlurb({groupShares, userShares}) {
//   const groupCount = Object.keys(groupShares || {}).length;
//   const userCount = Object.keys(userShares || {}).length;
//   return (
//     <Text style={{fontSize: 12, color: '#666', marginLeft: 8, marginBottom: 4}}>
//       {!groupCount ? null :
//         'PUBLISHED IN ' + groupCount + ' ' + pluralNum(groupCount, 'GROUP', 'GROUPS')
//       }
//       {(!groupCount || !userCount) ? null : 
//         ' AND ' 
//       }
//       {!userCount ? null : 
//         'SHARED WITH ' + userCount + ' ' + pluralNum(userCount,'PERSON','PEOPLE')
//       }
//       {(!groupCount && !userCount) ? 'NOT YET PUBLISHED OR SHARED' : null}
//     </Text>
//   )
// }

export class TalkTreeHeader extends React.Component {
  state = {groupName: 'Group', postInfo: null, userShares: null, groupShares: null};
  async componentDidMount() {
    const {author,post, group} = this.props;
    watchAuthorPostGroupShares(this, {author, post}, groupShares => this.setState({groupShares}));
    watchAuthorPostUserShares(this, {author, post}, userShares => this.setState({userShares}));
    watchAuthorPostInfo(this, {author, post}, postInfo => this.setState({postInfo}));
    if (group) {
      const groupName = await getGroupName(group);    
      // const pPostInfo = await fetchGroupPostInfo({group, post});
      this.setState({groupName});
    } else {
      this.setState({groupName: 'Private'})
    }
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }
  render () {
    const {author, post, group} = this.props;
    const {postInfo, groupName, groupShares, userShares} = this.state;
    // const color = 'white';
    // const color = Platform.OS == 'web' ? 'black' : 'white';
    const color ='black';
    // const postName = _.get(postInfo,'title') || _.get(postInfo, 'text');
    const groupCount = Object.keys(groupShares || {}).length;
    const userCount = Object.keys(userShares || {}).length;
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        {/* <PostIcon user={author} userName={_.get(postInfo,'name', '?')} size={30} /> */}
        <View style={{marginLeft: 8}}>
          <Text style={{fontSize: 16, color}}>Post by 
            <Text style={{fontWeight: 'bold'}}> {_.get(postInfo, 'name', null)}</Text>
          </Text>
          {!groupName ? null : 
            <Text style={{fontSize: 12}} numberOfLines={1}>
              in {groupName}
            </Text>
          }
        </View>
      </View>
    )
  } 
}



function isBroadcastVisibleTopLevel({group, broadcastInfo, postFrom, broadcast, anonIdentity}) {
  if (broadcastInfo.from == getCurrentUser()) {
    return true;
  } if (broadcastInfo.group && broadcastInfo.group != group) {
    return false;
  } else if (broadcastInfo.parentBy == getCurrentUser()) {
    return true;
  } else if (_.startsWith(broadcastInfo.parentBy,'anon-') && 
      _.get(anonIdentity,[broadcastInfo.parent,'user']) == getCurrentUser()) {
    return true;
  } else if (!broadcastInfo.unpublished) {
    return true;
  } else if (postFrom == getCurrentUser() && !broadcastInfo.parentBy) {
    return true;
  } else if (anonIdentity[broadcast]) {
    return true;
  } else {
    return false;
  }
}

export function getBroadcastChildren({broadcasts}) {
  var childrenForNode = {};
  Object.keys(broadcasts).forEach(b => {
    const parent = broadcasts[b].parent || 'root';
    if(!childrenForNode[parent]) {
      childrenForNode[parent] = [];
    }
    childrenForNode[parent].push(b);
  })
  return childrenForNode;
}

function setParentTimes({broadcasts, childTimes, b}) {
  if (!broadcasts[b]) return;
  const parent = broadcasts[b].parent;
  const time = Math.max(broadcasts[b].time, childTimes[b] || 0);
  if (parent) {
    if((childTimes[parent] || 0) < time) {
      childTimes[parent] = time;      
      setParentTimes({broadcasts, childTimes, b:parent});
    }
  }
}

export function getChildTimes({broadcasts}) {
  var childTimes = {}
  Object.keys(broadcasts).forEach(b => {
    setParentTimes({broadcasts, childTimes, b})
  })
  return childTimes;
}

function ActionBar({broadcast, parentBy, broadcastInfo, nodeFrom, likes, post, postFrom, group, inverted, authorName, text}) {
  const color = inverted ? 'white' : '#666'
  const replyParentBy = (nodeFrom == getCurrentUser()) ? broadcastInfo.parentBy : nodeFrom;
  const style = {
    flexDirection: 'row', alignItems: 'center',
    paddingVertical: 4, paddingHorizontal: 8
  }
  return (
    <View style={{flexDirection: 'row', justifyContent: 'flex-end',
        /* paddingHorizontal: 10, */ /* marginTop: 4, */ alignItems: 'center',
        borderTopColor: '#ddd', borderTopWidth: 0}}>
      {(broadcastInfo.unpublished && !broadcastInfo.isPrivate && (parentBy == getCurrentUser())) ?
        <FixedTouchable part='publish' onPress={() => publishReply({postAuthor: postFrom, post, group, user: broadcastInfo.from, reply:broadcast, authorName, text})}>
          <View style={style}>
            <FontAwesome name='check' size={12} color={color} />
            <Text style={{fontSize: 12, color, marginLeft: 4}}>Publish</Text>
          </View>
        </FixedTouchable>
        :
        ((nodeFrom == getCurrentUser()) ? 
          <NavClickable part='edit' navAction='editReply' navProps={{postAuthor: postFrom, post, reply: broadcast, parent: broadcastInfo.parent, group, parentBy: broadcastInfo.parentBy}}>
            <View style={style}>
              <Entypo name='edit' size={12} color={color} />
              <Text style={{fontSize: 12, color, marginLeft: 4}}>Edit</Text>
            </View>
          </NavClickable>
        : 
          <FixedTouchable part='like' onPress={() => likeReply({group, postAuthor: postFrom, post, reply: broadcast, user: broadcastInfo.from, like: !likes[broadcast], text: broadcastInfo.text})}>
            <View style={style}>
              <FontAwesome name={likes[broadcast] ? 'heart' : 'heart-o'} size={12} color={color} />
              <Text style={{fontSize: 12, color, marginLeft: 4}}>Like</Text>
            </View>
          </FixedTouchable>
        )
      }
      <NavClickable part='reply' navAction='editReply' navProps={{postAuthor: postFrom, post, group, parent: broadcast, parentBy:replyParentBy}}>
        <View style={style}>
          <FontAwesome name='reply' size={12} color={color} />
          <Text style={{fontSize: 12, color: color, marginLeft: 4}}>Reply Privately</Text>
        </View>
      </NavClickable>
    </View>
  ) 
}

function getName({users, user}) {
  if (user == getCurrentUser()) {
    return 'You'
  } else {
    return _.get(users, [user,'name'], '?');
  }
}


const privateColor = '#337F3E'
// const privateColor = '#666'


export class MiniTalkNode extends React.Component {
  state = {expanded: false}

  render() {
    const {nodeInfo, users} = this.props;
    const inverted = nodeInfo.unpublished || nodeInfo.isPrivate;
    const verb = getNodeVerb({broadcastInfo:nodeInfo});
    const expanded = this.state.expanded;
    const topLevel = !nodeInfo.parent;

    return (      
      <View>
        {expanded ? 
          <View style={{padding: 8, marginVertical: 4, borderRadius: 16, 
            borderTopLeftRadius: topLevel ? 16 : 0,
            backgroundColor: getNodeColor({broadcastInfo:nodeInfo}), 
            }}>
            <View style={{flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
              <Text style={{fontSize: 12, marginBottom: 2, color: inverted ? 'white' : 'black'}}>
                <Text style={{fontWeight: 'bold'}}>{getName({users, user: nodeInfo.from})}</Text>
                <Text style={{color: inverted ? 'white' : '#666', marginLeft: 8}}> - {formatTimeAgo(nodeInfo.time, 'twitter')}</Text>
                {!nodeInfo.edited ? null : 
                  <Text> - edited</Text>
                }
                {!verb ? null : 
                  <Text> - {verb}</Text>
                }
              </Text>
            </View>
            <Text style={{color: inverted ? 'white' : '#222'}}>{nodeInfo.text}</Text>
          </View>
        :
          <FixedTouchable onPress={()=>this.setState({expanded: true})} >
            <CollapsedNode nodeInfo={nodeInfo} users={users} topLevel={topLevel} />
          </FixedTouchable>
        }
      </View>
    )
  }
}

function NodeLikesList({likes, inverted}) {
  if (!likes) return null;
  const sortedLikes = sortKeysBy(likes, l => l.time);
  // console.log('likes', {likes, sortedLikes});
  return (
    <Text style={{fontSize: 12, color: inverted ? 'white' : '#666', marginTop: 8}}>
      <CommaSep>
        {sortedLikes.map(l =>
          <Text key={l}>{_.get(likes[l],'name','?')}</Text>
        )}
      </CommaSep>
      {' liked this'}
    </Text>
)
}

export class TalkNode extends React.Component {
  state = {expanded: false, showChildren: false}

  render() {
    const {anonIdentity, lastRead, broadcasts, group, post, topLevel, postFrom, users, node, likes, childTimes, childrenForNode, shownReply} = this.props;
    const nodeInfo = broadcasts[node];
    const children = _.get(childrenForNode,node,[]);
    const sortedChildren = _.sortBy(children, n => Math.max(broadcasts[n].time, childTimes[n] || 0)).reverse();
    
    const broadcastInfo = broadcasts[node];
    const inverted = broadcastInfo.unpublished || broadcastInfo.isPrivate;
    const verb = getNodeVerb({broadcastInfo});
    const unread = !(lastRead > nodeInfo.time);
    const childTime = topLevel ? Math.min(childTimes[node],lastRead) : lastRead;
  
    // TODO: Fix the code for hiding extra children
    const visibleChildren = sortedChildren;
    const hiddenCount = sortedChildren.length - visibleChildren.length;
    const expanded = (shownReply == node) || this.state.expanded || (!shownReply && (unread || sortedChildren.length == 0));
    var authorName = broadcastInfo.name // getName({users, user: broadcastInfo.from});
    var nodeFrom = broadcastInfo.from;
    var parentBy = broadcastInfo.parentBy;
    if (authorName == 'Anonymous' && anonIdentity[node]) {
      nodeFrom = anonIdentity[node].user;
      if (anonIdentity[node].user == getCurrentUser()) {
        authorName = 'Anonymous (You)' 
      } else {
        authorName = 'Anonymous (' + anonIdentity[node].name + ')' 
      }
    }
    if (_.startsWith(parentBy, 'anon-') && anonIdentity[broadcastInfo.parent]) {
      parentBy = anonIdentity[broadcastInfo.parent].user;
    }

    return (
      <View style={{margin: topLevel ? 4 : 0}}>
        {expanded ? 
          <View style={{padding: 8, paddingBottom: 0, marginVertical: 4, borderRadius: 16, 
            backgroundColor: getNodeColor({broadcastInfo}), maxWidth: 500,
            borderTopLeftRadius: topLevel ? 16 : 0
            }}>
            <View style={{flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}}>
              <NavClickable navAction={authorName == 'Anonymous' ? null : 'user'} navProps={{user:nodeInfo.from}}>
                <Text style={{fontSize: 12, marginBottom: 2, color: inverted ? 'white' : 'black'}}>
                  <Text style={{fontWeight: 'bold'}}>{authorName}</Text>
                  <Text style={{color: inverted ? 'white' : '#666', marginLeft: 8}}> - {formatTimeAgo(broadcastInfo.time, 'twitter')}</Text>
                  {!nodeInfo.edited ? null : 
                    <Text> - edited</Text>
                  }
                  {!verb ? null : 
                    <Text> - {verb}</Text>
                  }
                </Text>
              </NavClickable>
              <ActionMenu group={group} post={post} reply={node} size={13} color={inverted ? 'white' : '#666'} style={{paddingLeft: 8, paddingRight: 2, paddingVertical: 2}} />
            </View>
            <LinkText inverted={inverted} style={{fontSize: 15, lineHeight: 20, color: inverted ? 'white' : '#222'}} text={nodeInfo.text} />
            {nodeInfo.from != getCurrentUser() ? null : 
              <NodeLikesList likes={likes[node]} users={users} inverted={inverted} />
            }
            <ActionBar authorName={authorName} parentBy={parentBy} nodeFrom={nodeFrom} text={nodeInfo.text} inverted={inverted} broadcast={node} broadcastInfo={broadcastInfo} likes={likes} post={post} postFrom={postFrom} group={group} />
          </View>
        :
          <FixedTouchable onPress={()=>this.setState({expanded: true})} >
            <CollapsedNode nodeInfo={nodeInfo} users={users} topLevel={topLevel} />
          </FixedTouchable>
        }
        <View style={{paddingLeft: 8, borderLeftColor: 'white', borderLeftWidth: 1 /*StyleSheet.hairlineWidth */}}>
          {visibleChildren.map(node => 
            <TalkNode anonIdentity={anonIdentity} part={node} postFrom={postFrom} key={node} group={group} post={post} node={node} broadcasts={broadcasts} childrenForNode={childrenForNode} 
                childTimes={childTimes} users={users} likes={likes} lastRead={childTime} shownReply={shownReply} />
          )}
          {hiddenCount == 0 ? null : 
            <FixedTouchable onPress={()=>this.setState({showChildren:true})}> 
              <View style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                <View style={{flexShrink: 1, marginVertical: 2, backgroundColor: 'white', borderRadius: 16, paddingHorizontal: 8, paddingVertical: 4}}>
                  <Text style={{fontSize: 12, color: '#666'}}>View {hiddenCount}{visibleChildren.length == 0 ? null : ' more'} {pluralNum(hiddenCount, 'reply', 'replies')}</Text>
                </View>    
              </View>
            </FixedTouchable>
          }
        </View>
      </View>
    )
  }
}


function RequestedHelpBubble() {
  return (
    <HelpBubble style={{marginBottom: 16, marginTop: 8}}>
      You asked to join this group but have not yet been granted access. 
      Currently the only post you can see is your post introducing yourself to the group.
    </HelpBubble>
  )
}

function PostNoAccessScreen() {
  if (getCurrentUser()) {
    return null;
  } else {
    return (
       <ScreenContentScroll>
         <HelpBubble title='This post has not been shared publicly' style={{marginTop: 16}}>
            If you should have access to this post then log in to view it.
        </HelpBubble>
        <View style={{flexDirection: 'row', justifyContent: 'space-around', marginTop: 16}}>
          <Button navAction='signin'>
            Log In / Sign Up
          </Button>
        </View>
        </ScreenContentScroll>
    )
  }
}

function GroupShareItem({group, author, post, groupShare}) {
  const backgroundColor = nameColor({name: group});
  return (
    <NavClickable navAction='post' replace navProps={{group, author, post, key: group+'-'+post}}>
      <View style={{maxWidth: 200, flex: 0, flexDirection: 'row', alignItems: 'center', 
          backgroundColor, marginHorizontal: 8, borderRadius: 10, paddingVertical: 8, paddingHorizontal: 12}} >
        <Text numberOfLines={1} style={{color: 'white', fontSize: 16, fontWeight: '500'}}>
          {groupShare.groupName}
        </Text>
      </View>
    </NavClickable>
  )
}

function UserShareItem({user, userShare}) {
  return (
    <NavClickable navAction='user' navProps={{user}} >
      <View style={{marginHorizontal: 4, marginVertical: 4, justifyContent: 'space-around'}}>
        <UserIcon userName={userShare.name} user={user} size={25} />
      </View>
    </NavClickable>
  )
}

function ShareButton({post, postInfo, inverted}) {
  if (postInfo.from != getCurrentUser()) {
    return null;
  }
  return (
    <NavClickable navAction='tagPeople' navProps={{author: getCurrentUser(), post}} >
      <View style={{flexDirection: 'row', alignItems: 'center', 
          // backgroundColor: baseColor, 
          borderColor: inverted ? 'white' : baseColor, borderWidth: StyleSheet.hairlineWidth,
          borderRadius: 10, marginHorizontal: 8,
          paddingVertical: 8, paddingLeft: 8, paddingRight: 12}}>
        <Entypo name='plus' size={16} color={inverted ? 'white' : baseColor} />
        <Text style={{fontSize: 16, color: inverted ? 'white' : baseColor, marginLeft: 4}}>Share</Text>
      </View>
    </NavClickable>
  )
}

function GroupShareScroller({post, group, myGroups, author, postInfo, groupShares, userShares}) {
  const sortedGroupShares = sortKeysBy(groupShares, g => g.time).filter(g => g != group && _.get(myGroups,g));
  if (sortedGroupShares.length == 0) {
    if (group == null) {
      return <NotPublishedNotice post={post} postInfo={postInfo} />
    } else {
      return null;
    }
  }
  return (    
    <View style={{marginBottom: 10, marginTop: 10, backgroundColor: 'white', paddingVertical: 8}}>
      {/* <ShareBlurb groupShares={groupShares} userShares={userShares} /> */}
      <ScrollView horizontal contentContainerStyle={{alignItems: 'center'}} >
        <Text style={{color: '#666', marginLeft: 16}}>{group ? 'Also ' : ''} In:</Text>
        {/* <ShareButton post={post} postInfo={postInfo} /> */}
        {sortedGroupShares.map(g => <GroupShareItem key={g} group={g} author={author} post={post} groupShare={groupShares[g]} />)}
      {/* </ScrollView>
      <ScrollView horizontal> */}
        {/* <View style={{width: 8}}/>
        {sortedUserShares.map(u => <UserShareItem key={u} user={u} userShare={userShares[u]} />)} */}
      </ScrollView>
    </View>
  )
}

function NotPublishedNotice({post, postInfo}){
  return (
    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#444'}}>
      <ShareButton inverted post={post} postInfo={postInfo} />
      <View style={{padding: 10, flex: 1}}>
        <Text style={{color: 'white', fontWeight: '500', fontSize: 16}}>
            Not Yet Shared
        </Text>
        <Text style={{color: 'white', marginTop: 2}}>
            Only you can see this post. Click {'"Share"'} to share it.
        </Text>
      </View>
    </View>
  )
}



export class TalkTree extends React.Component {
  state = {anonIdentity: null, myGroups: null, cleared: null, broadcasts: null, users: null, postInfo: null, groupInfo: null, postLikes: null, likes: null, lastRead: null, follows: null, tagged: null}

  async componentDidMount() {
    const {post, group, author, user} = this.props;
    watchMyGroups(this, myGroups => this.setState({myGroups}))
    if (group) {
      watchPostInfo(this, {group, post}, postInfo => this.setState({postInfo}));
      watchGroupPostBroadcasts(this, {group, post}, broadcasts => this.setState({broadcasts: broadcasts || {}}));
      watchGroupUsers(this, group, users => this.setState({users}));
    } else {
      watchAuthorPostInfo(this, {author, post}, postInfo => this.setState({postInfo}));
      this.setState({broadcasts: {}});
      this.setState({users: {}});
    }
    watchAuthorPostReplyLikes(this, {author, post}, likes => this.setState({likes}));
    watchAuthorPostFollows(this, {author, post}, follows => this.setState({follows}));
    watchAuthorPostGroupShares(this, {author, post}, groupShares => this.setState({groupShares}));
    watchAuthorPostUserShares(this, {author, post}, userShares => this.setState({userShares}));
    watchAuthorPostLikes(this, {author, post}, postLikes => this.setState({postLikes}));
    watchHelpCleared(this, cleared => this.setState({cleared}));
    watchAnonIdentity(this, anonIdentity => this.setState({anonIdentity}));

    if (group) {
      watchGroupInfo(this, group, groupInfo => this.setState({groupInfo}));
      watchMyGroupStatus(this, group, myGroupStatus => this.setState({myGroupStatus}));
    } else {
      this.setState({groupInfo: {}, myGroupStatus: {}});
    }
    // watchPostPeopleTagged(this, {group, post}, tagged => this.setState({tagged}));
    if (getCurrentUser()) {
      const lastRead = await getTalkTreeLastRead({post});
      this.setState({lastRead});
      await markTalkTreeRead({post});
      await markAppUsed();
    } else {
      this.setState({lastRead: 0});
    }
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }

  render() {
    const {collapsed, post, author, group, user, navigation, requestPost, replyOpen, shownReply, fromGroup} = this.props;
    const {myGroups, anonIdentity, cleared, postLikes, userShares, broadcasts, postInfo, likes, lastRead, follows, groupInfo, myGroupStatus, tagged, groupShares} = this.state;

    if (myGroups == null || anonIdentity == null || cleared == null || userShares == null || postLikes == null || groupShares == null || lastRead == null || groupInfo == null || broadcasts == null || likes == null || postInfo == null) return <LoadingScreen />
    const visibleBroadcasts = filterObject(broadcasts, (b,k) => 
        isBroadcastVisibleTopLevel({group,
          broadcastInfo: b, postFrom: postInfo.from, broadcast: k, anonIdentity}));
    const childrenForNode = getBroadcastChildren({broadcasts:visibleBroadcasts});
    const childTimes = getChildTimes({broadcasts:visibleBroadcasts});

    const roots = _.get(childrenForNode, 'root', []);
    const sortedRoots = _.sortBy(roots, n => Math.max(broadcasts[n].time, childTimes[n] || 0)).reverse();
    const newest = sortKeysBy(visibleBroadcasts, b => b.time).reverse();
    var newNodeTime = 0;
    if (newest.length > 0) {
      newNodeTime = broadcasts[newest[Math.min(3, newest.length-1)]].time;
    }
    const cutOffTime = Math.min(newNodeTime, lastRead);

    // const sortedRoots = _.get(childrenForNode,'root',[]).sort(n => Math.max(broadcasts[n].time, childTimes[n] || 0)).reverse();

    const mode = _.get(myGroupStatus, 'mode');
    // const mode = _.get(users,[getCurrentUser(), 'mode']);

    // const myStatus = users[getCurrentUser()];
    // const mode = myStatus.mode;
 
    // TODO: Check access rights if logged in too
    if (!postInfo.isPublic && !getCurrentUser()) {
      return (
        <PostNoAccessScreen />
      )
    }

    const help = chooseFirstHelpPrompt(cleared, [
        postInfo.from != getCurrentUser() && Help.ReplyPrivately,
        sortedRoots.length == 0 && Help.PublishedHere
        // postInfo.from != getCurrentUser() && Help.FollowPost
    ])
    // console.log('groupInfo', {groupInfo});

    return (
      <ScreenContentFlatList maxWidth={700} statusdark style={{backgroundColor: baseBackgroundColor}} data={[
        {key: '_request', value: () => !requestPost ? null : <RequestedHelpBubble />},
        {key: '_group', value: () => (fromGroup || requestPost || !group) ? null : <View style={{marginVertical: 16}}><GroupSummary group={group} groupInfo={groupInfo} /></View>},
        // {key: '_notpublished', value: () => (groupCount + userCount != 0) ? null : <NotPublishedNotice postInfo={postInfo}/>},
        {key: '_post', value: () => 
          <PostPreview embedded likes={postLikes} group={group}
            replyOpen={replyOpen} replyInPlace collapsed={collapsed} mode={mode}
            post={post} postStatus={postInfo} follows={follows} 
            navigation={navigation} />},
        {key: '_helpReply', value: () => 
          <HelpReplyPrivately help={help} userName={postInfo.name} />},
        {key: '_groupshares', value: () => <GroupShareScroller myGroups={myGroups} author={author} group={group} post={post} postInfo={postInfo} groupShares={groupShares} userShares={userShares}/>},
        {key: '_helpPublished', value: () => 
          <HelpPublishedAppearHere help={help} />},
        // {key: '_helpFollow', value: () => 
        //   <HelpFollowPost help={help} />},
        ...sortedRoots.map(node => ({key: node, value: () => 
          <TalkNode part={node} node={node} group={group} broadcasts={broadcasts} childrenForNode={childrenForNode} 
              post={post} postFrom={postInfo.from} topLevel lastRead={cutOffTime} shownReply={shownReply}
              anonIdentity={anonIdentity}
              childTimes={childTimes} likes={likes} />})),
        {key: '_spacer', value: () => 
          <View style={{height: 100}} />            }  
      ]} />
    )
  }
}

