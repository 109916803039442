import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { watchPostFollows, fetchMyGroups, fetchGroupUsers, getCurrentUser, watchMyTags, tagPersonOnPost, watchThemPostTags, releaseWatchers, watchGroupPosts, getSingleGroup, sharePostToGroup } from './data';
import { LoadingScreen, ScreenContentFlatList, DefaultImage, baseColor, NewPost } from './ui';
import _ from 'lodash';
import { sortKeysBy, doesTextMatchPrefix } from './util';
import { formatTimeAgo } from '../basics/util';
import { NavClickable, FixedTouchable } from '../components/basics';
import { FollowedPostPreview } from './GroupList';
// import AntDesign from 'react-native-vector-icons/AntDesign';
import { SearchBox } from '../components/SearchBox';

function FollowedPost({post, postFollow}) {
  const {userName, groupName, user, group, title, time} = postFollow;
  return (
    <View style={{flex: 1}}>
    <NavClickable navAction='post' navProps={{author: postFollow.user, group, post}}>
      <View style={{marginHorizontal: 0, marginVertical: 4, padding: 8, borderColor: '#ddd',
          // borderWidth: StyleSheet.hairlineWidth, marginLeft: 16,
          backgroundColor: 'white', flex: 1,
          marginTop: 4}}>
        {/* <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2}}>
          <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <Text style={{fontSize: 12, fontWeight: 'bold', color: '#222'}}>{user == getCurrentUser() ? 'You' : userName}</Text>
              <Text style={{fontSize: 12, color: '#666'}}> in {groupName}</Text> 
              <Text style={{color: '#666', fontSize: 12, marginLeft: 8}}> - {formatTimeAgo(time)}</Text>
          </View>
        </View> */}
        <Text numberOfLines={2} style={{color: '#222', fontWeight: '500', fontSize: 15}}>{(title || '').trim()}</Text>
      </View>
    </NavClickable>
    </View>
  )  
}


function SharablePostPreview({user, post, postFollow, tags, group, groupStatus}) {
  const shared = _.get(tags, post);

  if (group) {
    const isAdmin = groupStatus.mode == 'admin';
    return (
      <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', flex: 1, paddingRight:16}}>
        <FollowedPost post={post} postFollow={postFollow} />
        {shared
        ? <View><Text style={{color: '#666'}}>{isAdmin ? 'Published' : 'Submitted'}</Text></View> 
        : <FixedTouchable part='invite' onPress={()=>sharePostToGroup({group, postAuthor: getCurrentUser(), post, user, isAdmin})}>
            <View style={{backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4}}>
              <Text style={{color: 'white'}}>{isAdmin ? 'Publish' : 'Submit'}</Text> 
            </View>
          </FixedTouchable>
        }
      </View>
    )

  } else {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', flex: 1, paddingRight:16}}>
        <FollowedPost post={post} postFollow={postFollow} />
        {/* <FollowedPostPreview post={post} postFollow={postFollow} postReadTime={postFollow.time} /> */}
        {shared
        ? <View><Text style={{color: '#666'}}>Shared</Text></View> 
        : <FixedTouchable part='invite' onPress={()=>tagPersonOnPost({group:postFollow.group, postAuthor: getCurrentUser(), post, user})}>
            {/* <AntDesign name='pluscircleo' color='#666' size={40} /> */}
            <View style={{backgroundColor: baseColor, paddingHorizontal: 8, paddingVertical: 4}}>
              <Text style={{color: 'white'}}>Share</Text> 
            </View>
          </FixedTouchable>
        }
        {/* <FollowedPost post={post} postFollow={postFollow} /> */}
      </View>
    )  
  }
}

function canSharePost(post) {
  // if (post.user == getCurrentUser()) {
  //   return true;
  // } else 
  if (post.user == getCurrentUser()) {
    return true;
  } else {
    return false;
  }
}

export class ChoosePostsScreen extends React.Component {
  state = {followedPosts: null, tags: null, groupStatus: null}
  async componentDidMount() {
    const {user, group} = this.props;
    watchPostFollows(this, followedPosts => this.setState({followedPosts}));
    if (user) {
      watchThemPostTags(this, user, tags => this.setState({tags}));
      this.setState({groupStatus: {}});
    } else {
      watchGroupPosts(this, group, tags => this.setState({tags}));
      const groupStatus = await getSingleGroup(group);
      this.setState({groupStatus});
    }
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }


  render() {
    const {user, group} = this.props;
    const {followedPosts, tags, search, groupStatus} = this.state;

    if (followedPosts == null || tags == null || groupStatus == null) return <LoadingScreen />

    const sortedPosts = sortKeysBy(followedPosts, p => p.time).reverse();
    const visiblePosts = _.filter(sortedPosts, p => canSharePost(followedPosts[p]))
    var shownPosts = visiblePosts;
    if (search) {
      shownPosts = _.filter(visiblePosts, p => doesTextMatchPrefix(followedPosts[p].title, search));
    }

    // console.log('userInGroup', {sortedPosts, visiblePosts, userInGroup, followedPosts});

    return (
      <ScreenContentFlatList statusdark style={{backgroundColor: 'white'}} data={[
        // {key: 'newpost', value: () => <NewPost group='private' /> },
        {key: '_search', value: () => 
          <SearchBox part='search' search={search} onChangeText={search => this.setState({search})} placeholder='Search' />},
        ...shownPosts.map(p => ({key: p, value: () =>
          <SharablePostPreview part={p} user={user} group={group} groupStatus={groupStatus} post={p} postFollow={followedPosts[p]} tags={tags} />    
        }))
      ]} />
    ) 
  }
}