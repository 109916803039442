
import firebase from './firebase';
import { Updates } from 'expo';
import { Platform } from 'react-native';

/* global window */

const thisVersion = 112;
var latestVersion;
var forceReloadVersion;

firebase.database().ref('/special/version_35').on('value', (snap) => {
  latestVersion = snap.val();
  console.log('version: ' + latestVersion);
})

firebase.database().ref('/special/forceReloadVersion_35').on('value', (snap) => {
  forceReloadVersion = snap.val();
  if (forceReloadVersion && forceReloadVersion > thisVersion) {
    forceReload();
  }
})

export function forceReload() {
  if (Platform.OS != 'web') {
    console.log('reloading');
    Updates.reload();
  } else {
    console.log('reloading web');
    window.location.reload(true);
  }
}


export function reloadIfVersionChanged() {
  // console.log('reloadIfVersionChaned', {latestVersion, thisVersion});
  if (latestVersion && latestVersion > thisVersion) {
    forceReload();
  }
}
