import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

import { newKey } from '../basics/fbutil';
import { saveImage, getUrlForImage, saveThumbnailImage, getUrlForThumbnailImage, getCurrentFbUid } from './data';

export async function pickImage() {
  const { status: existingStatus } = await Permissions.getAsync(
    Permissions.CAMERA_ROLL
  );
  if (existingStatus !== 'granted') {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
  }

  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: 'Images',
    base64: true
  });
  // console.log('result', {...result, base64:result.base64.slice(0,60), base64length: result.base64.length});
  return {uri: result.uri, base64: result.base64, width: result.width, height: result.height}
}

async function compressImage({uri, shortDimension, width, height}) {
  var newWidth; var newHeight;
  if (width > height) {
    newHeight = shortDimension; 
    newWidth = (width / height) * shortDimension;
  } else {
    newWidth = shortDimension;
    newHeight = (height / width) * shortDimension;
  }
  // console.log('resizing to :', {newWidth, newHeight});
  return await ImageManipulator.manipulateAsync(uri, 
    [{resize: {width: newWidth, height: newHeight}}],
    {format: 'jpeg', compress: 0.7, base64: true});
}


export async function uploadImage({uri, width, height}) {
  // console.log('uploadImage:', uri);

  const resizedImage = await compressImage({uri, shortDimension: 600, width, height});
  const thumbnailImage = await compressImage({uri, shortDimension: 150, width, height});
  // console.log('resizeSize', resizedImage.base64.length, 'thumbSize', thumbnailImage.base64.length)

  const key = newKey();
  const pSave = saveImage({base64data: resizedImage.base64, key});
  const pSaveThumb = saveThumbnailImage({base64data: thumbnailImage.base64, key});
  await pSave; await pSaveThumb;
  const image = getCurrentFbUid() + '/' + key;

  const downloadUrl = await getUrlForImage({image});
  const thumbnailUrl = await getUrlForThumbnailImage({image})
  console.log('uploaded', {downloadUrl, image, thumbnailUrl});
  return {downloadUrl, thumbnailUrl, image, base64: thumbnailImage.base64};
}
