import React from 'react';
import { Platform, Image, Dimensions, Text, View, StyleSheet, CheckBox, Switch } from 'react-native';
import { watchGroupPosts, watchGroupUsers, watchGroupBroadcasts, watchGroupPostBroadcasts, watchPostInfo, watchGroupChats, likePost, watchGroupLikes, getSingleGroup, markGroupRead, watchGroupInfo, approveGroupMember, releaseWatchers, addReply, publishReply, publishBroadcast, publishPost, followPost, getUrlForImage } from './data';
import { chatText, ScreenContentFlatList, baseColor, LoadingScreen, Button, DefaultImage, linkColor, timeColor, textColor, unreadColor, HelpBubble, NotifsBanner, ActionButton, LinkText, getNodeColor, getName, CollapsedNode, UserIcon } from './ui';
import { sortKeysBy, plural, getPostVerb, postIsPublished, getReplyVerb, replyColor, replyBorderRadius, replyBorderWidth, getFirstName, countNewlines } from './util';
import { formatTimeAgo } from '../basics/util';
import { NavClickable, FixedTouchable, CommaSep } from '../components/basics';
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import _ from 'lodash';
import { getCurrentUser, getLoadedCurrentUserName } from '../slowgroup/data';
import { ActionMenu } from '../components/ActionMenu';
import { Catcher } from '../basics/catcher';
import { RobMarkdown } from './markdown';



function nameOrYou(user, thing) {
  if (user == getCurrentUser()) {
    return 'You'
  } else {
    return _.get(thing, 'name', '?');
  }
}

export function FollowsList({mine, likes, follows,users, inverted}) {
  if (!follows) return null;
  const sortedFollows = sortKeysBy(follows, l => l.time);
  const sortedLikes = sortKeysBy(likes, l => l.time);

  if (sortedFollows.length == 0 && sortedLikes.lenth == 0) return null;
  return (
    <Catcher style={{marginLeft: 10, marginBottom: 8}}>
      <Text numberOfLines={1} style={{fontSize: 12, color: inverted ? 'white' : '#666'}}>
        {sortedFollows.length == 0 ? null :
          <Text>
            Followed by <CommaSep>
            {sortedFollows.map(l =>
              <Text key={l}>{nameOrYou(l, follows[l])}</Text>
              // <Text key={l}>{_.get(users[l],'name','?')}</Text>
            )}
            </CommaSep>
          </Text>
        }
        {sortedLikes.length != 0 && sortedFollows.length != 0 ? <Text>. </Text> : null}
        {sortedLikes.length == 0 ? null :
          <Text>
            {'Liked by '}
              {mine ? 
                <CommaSep>
                  {sortedLikes.map(l =>
                    <Text key={l}>{nameOrYou(l, likes[l])}</Text>
                    // <Text key={l}>{_.get(users[l],'name','?')}</Text>
                  )}
                </CommaSep>
              :
                <Text>{sortedLikes.length} {plural(sortedLikes, 'person', 'people')}</Text>
              }
          </Text>
        }
      </Text>
    </Catcher>
  )
}

export function TalkTreePreview({post, group, postFrom, lastRead = 0, lastPublic, lastPrivate}) {
  var lastNode = null;
  if (_.get(lastPublic, 'time',0) > _.get(lastPrivate, 'time',0)) {
    lastNode = lastPublic;
  } else {
    lastNode = lastPrivate;
  }
  if (!lastNode) {
    return null;
  }
  return (
    <NavClickable navAction='post' navProps={{collapsed: true, author: postFrom, group, post, fromGroup: true}} >
      <Catcher style={{marginLeft: 16, marginBottom: 10, marginRight: 4}}>
          <CollapsedNode nodeInfo={lastNode} infeed topLevel translucent={lastNode.time <= lastRead || lastNode.from == getCurrentUser() || lastNode.author == getCurrentUser()} />
        {/* {totalCount <= 1 ? null : */}
        <View style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
          <View style={{flexShrink: 1, marginTop: 2, backgroundColor: 'white', borderRadius: 16, paddingHorizontal: 8, paddingVertical: 6}}>
            {/* <Text style={{fontSize: 12, color: '#666'}}>View {totalCount - 1} {sorted.length == 0 ? null : 'more'} {plural(visibleBroadcasts, 'reply', 'replies', 1)}</Text> */}
            <Text style={{fontSize: 12, color: '#666'}}>View more replies</Text>
          </View>    
        </View>
      {/* } */}
      </Catcher>
    </NavClickable>
  )
}

function PostActionBar({post, small, group, postStatus, inverted, mode, follows, likes, authorName}) {
  return (
  <View style={{flexDirection: 'row', justifyContent: small ? 'flex-end' : 'space-around',
      paddingHorizontal: 10,
      borderTopColor: '#ddd', borderTopWidth: small ? null : StyleSheet.hairlineWidth}}>
    {((postStatus.type == 'asktojoin') && (mode == 'admin')) ?
    <ActionButton small={small} part='accept' iconName='add-user' inverted={inverted} iconFamily={Entypo} label='Accept'
      onPress={() => approveGroupMember({group, post, user: postStatus.from})}
    />
    : ((postStatus.unpublished && !postStatus.isPrivate && mode == 'admin') ?
        <ActionButton small={small} part='publish' iconName='check' inverted={inverted} iconFamily={FontAwesome} label='Publish'
          onPress={() => publishPost({author: postStatus.from, group, post, user: postStatus.from, text: postStatus.text})}
        />
      :
        (postStatus.from == getCurrentUser() ? 
          <ActionButton small={small} part='edit' iconName='edit' inverted={inverted} iconFamily={Entypo} label='Edit'
            navAction='editPost' navProps={{author: postStatus.from, post, group}}
          />
        :
          <ActionButton small={small} part='like' iconFamily={FontAwesome} signin 
            iconName={_.get(likes, getCurrentUser()) ? 'heart' : 'heart-o'} 
            inverted={inverted} label={_.get(likes, getCurrentUser()) ? 'Liked' : 'Like'} liked={likes ? true : false}
            onPress={() => likePost({author: postStatus.from, group, post, text: postStatus.text, user: postStatus.from, userName: authorName, like: !_.get(likes, getCurrentUser())})}
          />
        )
      )
    }
    <ActionButton small={small} iconName='reply' label='Reply Privately' inverted={inverted}
      navAction='editReply' navProps={{postAuthor: postStatus.from, group, post}} />

    {/* {postStatus.from == getCurrentUser() ? null : 
      <ActionButton small={small} part='follow' iconFamily={FontAwesome} signin 
        iconName={_.get(follows, getCurrentUser()) ? 'star' : 'star-o'} 
        inverted={inverted} label={_.get(follows, getCurrentUser()) ? 'Following' : 'Follow'} liked={likes ? true : false}
        onPress={() => followPost({author: postStatus.from, group, post, text: postStatus.text, user: postStatus.from, userName: authorName, follow: !_.get(follows, getCurrentUser())})}
      />
    } */}

     {postStatus.from != getCurrentUser() ? null : 
      <ActionButton small={small} iconName='add-user' iconFamily={Entypo} label='Share' inverted={inverted}
        navAction='tagPeople' navProps={{author: postStatus.from, group, post}}
      />
    }
  </View>
  )

}

export class PostPreview extends React.PureComponent {
  state = {reply: null, expanded: false, allowPublication: true}

  render() {
    const {replyOpen, embedded, collapsed, lastRead, onChat, response, inGroup, 
      parent, post, group, chats, broadcasts, likes, users, postStatus, tagged,
      navigation, follows, lastPrivate, lastPublic, mode} = this.props;
    const authorName = _.get(postStatus, 'name', '?')
    const textBig = (_.get(postStatus,['text','length'],0) > 300) || countNewlines(_.get(postStatus, ['text'])) > 5;
    const collapse = collapsed && !this.state.expanded && textBig /* && inGroup */;
    // const wide = false;
    const wide = !inGroup && Dimensions.get('window').width > 500;
    // console.log('width', Dimensions.get('window').width, wide);

    // const backgroundColor =
    const backgroundColor = !inGroup ? 'white' : getNodeColor({broadcastInfo: postStatus});
    const inverted = inGroup && (postStatus.unpublished || postStatus.isPrivate);

    return (
      <NavClickable navAction={inGroup ? 'post' : null} navProps={{fromGroup: true, group, post, author: postStatus.from}}>
        <View style={{borderColor: '#ddd', borderBottomWidth: embedded ? StyleSheet.hairlineWidth : 0, marginVertical: 4, backgroundColor}}>
          <View style={{paddingHorizontal: inGroup ? 2 : (wide ? 10 : 2), paddingVertical: inGroup ? 2 : (wide ? 10 : 2)}} >
              {/* <FeedReason lastPrivate={lastPrivate} lastPublic={lastPublic} follows={follows} users={users} inverted={inverted} /> */}
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 10}}>
              <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <NavClickable navAction='user' navProps={{user:postStatus.from}}>
                  <UserIcon userName={authorName} user={postStatus.from} size={30} />
                </NavClickable>
                <View style={{marginLeft: 8}}>
                  <NavClickable navAction='user' navProps={{user: postStatus.from}}>
                    <Text>
                      <Text style={{fontWeight: 'bold', color: inverted ? 'white' : '#222'}}>{authorName} </Text>
                      <Text style={{color: inverted ? 'white' : '#666'}}>{getPostVerb({postStatus, response})}</Text>
                    </Text>
                  </NavClickable>
                  <Text style={{color: inverted ? 'white' : '#666', fontSize: 12}}>
                      {!postStatus.edited ? null : 
                        <Text>edited </Text>
                      }
                    {formatTimeAgo(postStatus.editTime || postStatus.time)}
                  </Text>
                </View>
              </View>
              <ActionMenu navigation={navigation} post={post} color={inverted ? 'white' : '#666'} size={16} style={{paddingVertical: 4, paddingHorizontal: 8}} />
            </View>
            {!postStatus.title ? null :
              <Text style={{fontSize: inGroup ? 18 : (wide ? 36 : 20), fontWeight: '500', color: inverted ? 'white' : textColor, 
                  marginHorizontal: 10, marginVertical: 8}}>
                {postStatus.title}
              </Text>
            }
            {!postStatus.image ? null :
              <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                <NavClickable activeOpacity={0.8} 
                    navAction='image' navProps={{image:postStatus.image}}>
                  <Image style={{marginVertical: 16, width: 350, height: 350}} 
                      source={{uri: getUrlForImage({image:postStatus.image})}}
                  />        
                </NavClickable>
              </View>
            }
            {!postStatus.text ? null : 
              (collapse ? 
                // <NavClickable navAction='post' navProps={{post, author: postStatus.from, group}} >
                  <View style={{marginBottom: 12, marginHorizontal: 10, marginTop: 4}}>
                    <Text numberOfLines={postStatus.title ? 4 : 8} 
                        style={{
                          fontSize: inGroup ? 15: (wide ? 18 : 15), 
                          lineHeight: inGroup ? 20 : (wide ? 24 : 20), 
                          color: inverted ? 'white' : textColor}}>
                      <LinkText 
                        inline text={(postStatus.text || '').trim()} inverted={inverted} />
                    </Text>
                    <NavClickable 
                        navAction={inGroup ? 'post' : null} navProps={{post, group, author:postStatus.from}}
                        onPress={inGroup ? null : () => this.setState({expanded: true})} >
                      <View style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                        <View style={{padding: 4, marginTop: 8, borderWidth: StyleSheet.hairlineWidth, borderColor: inverted ? 'white' : '#ddd'}}>
                          <Text style={{color: inverted ? 'white' : '#666'}}>Read More</Text>
                        </View>
                      </View>
                    </NavClickable>
                    {/* <Text style={{marginTop: 1, color: inverted ? 'white' : '#385898'}}>See More</Text> */}
                  </View>
                // </NavClickable>      
              :
                <View style={{marginBottom: 12, marginTop: 4, marginHorizontal: 10}}>
                   <LinkText inline={false} style={{
                     fontSize: inGroup ? 15: (wide ? 18 : 15), 
                     lineHeight: inGroup ? 20 : (wide ? 24 : 20), 
                     color: inverted ? 'white' : textColor}} text={(postStatus.text || '').trim()} inverted={inverted} />
                </View>
              )
            }
            {(inGroup || postStatus.from != getCurrentUser()) ? null : 
              <FollowsList mine={postStatus.from == getCurrentUser()} likes={likes} follows={follows} inverted={inverted} users={users} />
            }
            {/* <YouSharedList tagged={tagged} users={users} inverted={inverted} /> */}
          </View>
          {/* {inGroup ? null : */}
            <PostActionBar small={inGroup} post={post} group={group} postStatus={postStatus} inverted={inverted} mode={mode} follows={follows} likes={likes} authorName={authorName} />
          {/* } */}
          {/* function PostActionBar({post, group, postStatus, inverted, mode, follows, likes, authorName}) */}
        </View>
        {embedded ? null : 
          <TalkTreePreview post={post} group={group} lastRead={lastRead}           
              postFrom={postStatus.from} broadcasts={broadcasts}  
              lastPublic={lastPublic} lastPrivate={lastPrivate} />
        }
      </NavClickable>
    )
  }
}

function ToggleCheck({value, onValueChanged, label}) {
  return (
    <View style={{flexDirection: 'row', marginLeft: 10}}>
        <FontAwesome name={value ? 'check-circle' : 'circle-thin'} size={20} color={value ? baseColor : '#666'} />
    </View>
  )
}

export function PublicationToggle({allowed, onAllowed, publisherName, admin, style, global}) {
  var action;
  if (global) {
    action = 'Visible Publicly'
  } else {
    action = admin ? 'Publish Now' : 'Allow ' + getFirstName(publisherName) + ' to publish this';
  }
  return (
    <FixedTouchable onPress={() => onAllowed(!allowed)}>
      <View style={{flexDirection: 'row', alignItems: 'center', ...style}}>
        <ToggleCheck value={allowed} />
        <Text style={{marginLeft: 4, color: '#666'}}>{action}</Text>
      </View>
    </FixedTouchable>
  )
}

export function Toggle({label, toggled, onToggle, style}) {
  return (
    <FixedTouchable onPress={() => onToggle(!toggled)}>
      <View style={{flexDirection: 'row', alignItems: 'center', ...style}}>
        <ToggleCheck value={toggled} />
        <Text style={{marginLeft: 4, color: '#666'}}>{label}</Text>
      </View>
    </FixedTouchable>
  )
}
