import { SERVER_TIMESTAMP, fetchSingle, watchSingle, setSingle, updateSingle, pushFile, getUrlForFile, putFile, newKey, uploadImage, setMultiple, internalReleaseWatchers} from '../basics/fbutil';
import _ from 'lodash'
import { Platform } from 'react-native';
import { plural, filterObject } from './util';

export function releaseWatchers(obj) {
  return internalReleaseWatchers(obj);
}

export async function watchMyGroups(obj, callback) {
  return watchSingle(obj, '/v3/userGroup/' + getCurrentUser(), callback);
}

export async function fetchMyGroups() {
  return fetchSingle('/v3/userGroup/' + getCurrentUser());
}

export async function fetchGroupUsers(group) {
  return fetchSingle('/v3/groupUser/' + group);
}


export async function getSingleGroup(group) {
  return await fetchSingle('/v3/userGroup/' + getCurrentUser() + '/' + group);
}

export async function watchSingleGroup(obj,group, callback) {
  return await watchSingle(obj, '/v3/userGroup/' + getCurrentUser() + '/' + group, callback);
}

export async function watchGroupInfo(obj, group, callback) {
  return await watchSingle(obj, '/v3/groupInfo/' + group, callback);
}

export async function getCurrentUserName() {
  return await fetchSingle('/v2/userToName/' + getCurrentUser(),'?');
}

export async function getUserName(user) {
  return await fetchSingle('/v2/userToName/' + user,'?');
}

export async function watchAllUserNames(obj, callback) {
  return await watchSingle(obj, '/v2/userToName', callback);
}

export async function watchUserName(obj, user, callback) {
  return watchSingle(obj, '/v2/userToName/' + user, callback);
}

export async function fetchUserName(user) {
  return await fetchSingle('/v2/userToName/' + user);
}

export async function getUserForId(id) {
  return await fetchSingle('/v2/uidToUser/' + id);
}

export async function getGroupName(group) {
  return await fetchSingle('/v3/groupInfo/' + group + '/name','?');
}

export async function getReply({author, post, reply}) {
  return await fetchSingle('/v3/authorPostReply/' + author + '/' + post + '/' + reply);
}

export async function getGroupReply({group, post, reply}) {
  return await fetchSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply);
}


export function watchCurrentUserName(obj, callback) {
  return watchSingle(obj, '/v2/userToName/' + getCurrentUser(), callback, '?');
}

export function watchGroupPosts(obj, group, callback) {
  return watchSingle(obj, '/v3/groupPost/' + group, callback);
}

// export function watchAuthorPostInfo(obj, {author, post}, callback) {
//   return watchSingle(obj, '/v3/authorPostInfo/' + author + '/' + post, callback);
// }

// export function fetchAuthorPostInfo({author, post}) {
//   return fetchSingle('/v3/authorPostInfo/' + author + '/' + post);
// }


export function watchAuthorPostInfo(obj, {author, post}, callback) {
  return watchSingle(obj, '/v3/authorPostInfo/' + author + '/' + post, callback);
}

export function fetchAuthorPostInfo({author, post}) {
  return fetchSingle('/v3/authorPostInfo/' + author + '/' + post);
}

export function watchGroupUsers(obj, group, callback) {
  return watchSingle(obj, '/v3/groupUser/' + group, callback);
}

export function watchMyGroupStatus(obj, group, callback) {
  return watchSingle(obj, '/v3/groupUser/' + group + '/' + getCurrentUser(), callback);
}

export function watchAllUsers(obj, callback) {
  return watchGroupUsers(obj, 'welcome', callback);
}

// export async function getSingleGroupUser({group, user}) {
//   return await fetchSingle('/v3/groupUser/' + group + '/' + user);
// }

// export function watchGroupBroadcasts(obj, group, callback) {
//   return watchSingle(obj, '/v3/groupPostBroadcast/' + group, callback);
// }

export function watchGroupPostBroadcasts(obj, {group, post}, callback) {
  return watchSingle(obj, '/v3/groupPostBroadcast/' + group + '/' + post, callback);

}

export function watchAuthorPostBroadcasts(obj, {author, post}, callback) {
  return watchSingle(obj, '/v3/authorPostReply/' + author + '/' + post, callback);
}

// export function watchMessages(obj, {post, user}, callback) {
//   return watchSingle(obj, '/v3/meThemPostMessage/' + getCurrentUser() + '/' + user + '/' + post, callback);
// }

export function watchPostInfo(obj, {group, post}, callback) {
  return watchSingle(obj, '/v3/groupPost/' + group + '/' + post, callback);
}

// export function watchGroupChats(obj, group, callback) {
//   return watchSingle(obj, '/v3/groupUserPostThemChat/' + group + '/' + getCurrentUser(), callback)
// }

export function watchGroupLikes(obj, group, callback) {
  return watchSingle(obj, '/v3/groupUserPostThemLike/' + group + '/' + getCurrentUser(), callback)
}

export function watchAuthorPostReplyLikes(obj, {author, post}, callback) {
  return watchSingle(obj, '/v3/authorPostReplyUserLike/' + author + '/' + post, callback)
}

// export function watchGroupPostLikes(obj, {group, post}, callback) {
//   return watchSingle(obj, '/v3/groupUserPostThemLike/' + group + '/' + getCurrentUser() + '/' + post, callback)
// }


export async function markGroupRead(group) {
  if (!group) {return null}
  const groupStatus = await fetchSingle('/v3/groupUser/' + group + '/' + getCurrentUser(), null);
  if (groupStatus) {
    await setSingle('/v3/userGroupLastPrivate/' + getCurrentUser() + '/' + group + '/unread', false);
    return setSingle('/v3/userGroup/' + getCurrentUser() + '/' + group + '/lastRead', SERVER_TIMESTAMP);
  }
}

// export async function markChatRead({group,post,user}) {
//   const chatInfo = await fetchSingle('/v3/groupUserPostThemChat/' + group + '/' + getCurrentUser() + '/' + post + '/' + user, null);

//   if (chatInfo) {
//     return setSingle('/v3/groupUserPostThemChat/' +
//         group + '/' + getCurrentUser() + '/' + post + '/' + user + '/lastRead', SERVER_TIMESTAMP);
//   }
// }

export async function markTalkTreeRead({post}) {
  await setSingle('/v3/userPostRead/' + getCurrentUser() + '/' + post, SERVER_TIMESTAMP);
  await updateSingle('/v3/userPostLastPrivate/' + getCurrentUser() + '/' + post, {unread: false});
}

export async function watchPostReads(obj, callback) {
  return watchSingle(obj, '/v3/userPostRead/' + getCurrentUser(), callback);
}


// export async function setTalkTreeReadTime({group,post, time}) {
//   return setSingle('/v3/groupUserPostRead/' +
//       group + '/' + getCurrentUser() + '/' + post, time);
// }

// export async function markTalkTreeTempRead({group,post}) {
//   return setSingle('/v3/groupUserPostTempRead/' +
//       group + '/' + getCurrentUser() + '/' + post, SERVER_TIMESTAMP);
// }


export async function getTalkTreeLastRead({post}) {
  return await fetchSingle('/v3/userPostRead/' + getCurrentUser() + '/' +
      post);
}

export async function watchGroupPostLastReads(obj, {group}, callback) {
  watchSingle(obj, '/v3/groupUserPostRead/' + group + '/' + getCurrentUser(), callback);
}

export async function setMyName({name}) {
  const userGroups = await fetchSingle('/v3/userGroup/' + getCurrentUser());
  // console.log('setMyName', {grops: Object.keys(userGroups), name});
  const pGroupUpdates = Object.keys(userGroups).map(group =>
    setSingle('/v3/groupUser/' + group + '/' + getCurrentUser() + '/name', name)
  )
  await setSingle('/v2/userToName/' + getCurrentUser(), name);
  await Promise.all(pGroupUpdates);
}

export async function checkIfGroupNameTaken(id){
  const groupInfo = await fetchSingle('/v3/groupInfo/' + id);
  if (groupInfo && groupInfo.name) {
    return true
  } else {
    return false
  }
}

export async function chooseGroupId(prefix) {
  const nameTaken = await checkIfGroupNameTaken(prefix);
  if (!nameTaken) {
    return prefix;
  } else {
    const digit = Math.floor(Math.random() * 10);
    return chooseGroupId(prefix + digit);
  }
}

export async function editGroup({group, name,intro, isPublic=false}) {
  await updateSingle('/v3/groupInfo/' + group, {
    name, intro, createTime: SERVER_TIMESTAMP, creator: getCurrentUser(), isPublic
  });
}


export async function createGroup({name,intro, isPublic=false}) {
  const prefix = name.replace(/\s/g,'').toLowerCase();
  const group = await chooseGroupId(getCurrentUser() + '-' + prefix);
  const userName = await getCurrentUserName();
  await setSingle('/v3/groupUser/' + group + '/' + getCurrentUser(), {
    name: userName, mode: 'admin', joinTime: SERVER_TIMESTAMP
  })

  const pInfo = setSingle('/v3/groupInfo/' + group, {
    name, intro, createTime: SERVER_TIMESTAMP, creator: getCurrentUser(), isPublic
  });
  const pList = setSingle('/v3/userGroup/' + getCurrentUser() + '/' + group, {
    name, lastTime: SERVER_TIMESTAMP, lastUserName: userName, lastText: 'created the group'
  })
  await pInfo; await pList;
}

export async function addPost({text, group, image=null, type=null, title=null, isPrivate=null, isPublic=null}) {
  const key = newKey('post');
  const name = await getCurrentUserName();
  const groupName = group ? await getGroupName(group) : null;
  var pGroupPost = Promise.resolve();
  var pShare = Promise.resolve();
  if (group) {
    pGroupPost = setSingle('/v3/groupPost/' + group + '/' + key,{
      from: getCurrentUser(), name, text, image, time: SERVER_TIMESTAMP, type, isPrivate, unpublished: true, title
    });
    pShare = setSingle('/v3/authorPostGroupShare/' + getCurrentUser() + '/' + key + '/' + group, {
      unpublished: true, time: SERVER_TIMESTAMP, groupName
    })
  
  }
  const pPost = setSingle('/v3/authorPostInfo/' + getCurrentUser() + '/' + key,{
    from: getCurrentUser(), name, text, image, title, time: SERVER_TIMESTAMP, type, isPrivate, isPublic
  });

  if (!isPrivate) {
    await notifyGroupAdmins({group, text, title, image, post: key, postAuthor: getCurrentUser(), type: 'newPost', action: 'submitted a post in'})
  }
  // const pStatus = setSingle('/v3/groupInfo/' + group + '/lastPost', {
  //   from: getCurrentUser(), text, time: SERVER_TIMESTAMP, type
  // });
  await followPost({author: getCurrentUser(), user: getCurrentUser(), group, post: key, text, title, image, follow: true, hasNotif: false, created: true});
  await pPost; await pShare; await pGroupPost;
  return key;
  // await pStatus;
}

async function fetchPostGroupShares({author, post}) {
  return fetchSingle('/v3/authorPostGroupShare/' + author + '/' + post);
}

async function editGroupPost({group, post, text, title, image=null, isPrivate, isPublic}) {
  // console.log('editGroupPost', {image});

  const pPostText = setSingle('/v3/groupPost/' + group + '/' + post + '/text', text);
  const pPostTitle = setSingle('/v3/groupPost/' + group + '/' + post + '/title', title);
  const pPostImage = setSingle('/v3/groupPost/' + group + '/' + post + '/image', image);
  const pPostTime = setSingle('/v3/groupPost/' + group + '/' + post + '/editTime', SERVER_TIMESTAMP);
  const pPostEdited = setSingle('/v3/groupPost/' + group + '/' + post + '/edited', true);
  const pPostPublic = setSingle('/v3/groupPost/' + group + '/' + post + '/isPublic', isPublic);
  const pPostPrivate = setSingle('/v3/groupPost/' + group + '/' + post + '/isPrivate', isPrivate);

  await pPostTitle; await pPostText; await pPostTime; await pPostEdited; await pPostPrivate; 
  await pPostImage; await pPostPublic;
}

export async function editPost({post, text, title=null, image=null, isPrivate=null, isPublic=null}) {
  const shares = await fetchPostGroupShares({author: getCurrentUser(), post});

  // console.log('editPost', {shares, post, text, isPrivate});

  const pShareUpdates = Promise.all(Object.keys(shares).map(group => editGroupPost({group, post, text, title, image, isPublic, isPrivate})));

  const pUpdate = updateSingle('/v3/authorPostInfo/' + getCurrentUser() + '/' + post, {
    text, title, image, editTime: SERVER_TIMESTAMP, edited: true, isPublic, isPrivate
  })

  // const pStatus = setSingle('/v3/groupInfo/' + group + '/lastPost', {
  //   from: getCurrentUser(), text, time: SERVER_TIMESTAMP, type
  // });
  // if (!isPrivate && wasPrivate) {
  //   notifyGroupAdmins({group, text, post, type: 'newPost', action: 'submitted a post in'})
  // }

  await pShareUpdates; await pUpdate;

}


// TODO: Non-member shouldn't be writing to their own groupUser
export async function askToJoinGroup({text, group}) {
  const key = newKey('join');
  const name = await getCurrentUserName();
  const groupName = await getGroupName(group);

  await setSingle('/v3/groupPostUserTagged/' + group + '/' + key + '/' + getCurrentUser(), {
    from: getCurrentUser(), time: SERVER_TIMESTAMP
  })

  const pAuthorPost = setSingle('/v3/authorPostInfo/' + getCurrentUser() + '/' + key,{
    from: getCurrentUser(), name, text, time: SERVER_TIMESTAMP, type: 'asktojoin'
  });
  const pGroupPost = setSingle('/v3/groupPost/' + group + '/' + key,{
    from: getCurrentUser(), name, text, time: SERVER_TIMESTAMP, type: 'asktojoin', unpublished: true,
  });
  const pShare = setSingle('/v3/authorPostGroupShare/' + getCurrentUser() + '/' + key + '/' + group, {
    unpublished: true, time: SERVER_TIMESTAMP, groupName
  })
  const pUser = setSingle('/v3/groupUser/' + group + '/' + getCurrentUser() + '/name', name);
  const pGroup = setSingle('/v3/userGroup/' + getCurrentUser() + '/' + group, {
    name: groupName, lastUserName: 'You', lastText: 'asked to join',
    lastTime: SERVER_TIMESTAMP, requested:true
  })
  const pRequest = setSingle('/v3/userGroupRequest/' + getCurrentUser() + '/' + group, {
    post: key, time: SERVER_TIMESTAMP
  })
  notifyGroupAdmins({group, text, post: key, postAuthor: getCurrentUser(), type: 'asktojoin', action: 'asked to join'});

  await pAuthorPost; await pGroupPost; await pShare;
  await pUser; await pGroup; await pRequest;
  return key;
}

export async function watchGroupRequest(obj, group, callback) {
  return watchSingle(obj, '/v3/userGroupRequest/' + getCurrentUser() + '/' + group, callback);
}

export async function approveGroupMember({group, post, user}) {
  // console.log('approveGroupMember', {group, post,user});
  const pUser = setSingle('/v3/groupUser/' + group + '/' + user + '/mode', 'member');
  const pPost = setSingle('/v3/groupPost/' + group + '/' + post + '/type', 'newmember');
  const pPublish = setSingle('/v3/groupPost/' + group + '/' + post + '/unpublished', null);

  const pUserMember = setSingle('/v3/userGroup/' + user + '/' + group + '/requested', null);
  const pUserMode = setSingle('/v3/userGroup/' + user + '/' + group + '/mode', 'member');
  // await addMessage({post, group, user, text: 'added you to the group', type: 'approveMember'})
  const pNotif = sendNotif({user, group, text: '', post, postAuthor: user, type: 'addtogroup', action: 'added you to'});
  // const pNotif = setSingle('/v3/userNotif/' + user + '/' + newKey(), {
  //   from: getCurrentUser(), to: user, text:'added you to the group', group, post, type: 'addtogroup', time: SERVER_TIMESTAMP
  // })

  await pUser; await pPost; await pNotif; await pUserMember; await pPublish; await pUserMode;
}

export async function watchAllGroupsLastPrivate(obj, callback) {
  return watchSingle(obj, '/v3/userGroupLastPrivate/' + getCurrentUser(), callback);
}

export async function notifyGroupAdmins({group, image, post, postAuthor, text, title, type=null, action=null}) {
  const users = await fetchSingle('/v3/groupUser/' + group);
  const admins = filterObject(users, u => u.mode == 'admin');
  const name = await getCurrentUserName();
  const pNotifs = Object.keys(admins).map(u =>
    sendNotif({hasMessage: false, user: u, group, post, postAuthor, text, image, title, type, action})
  );
  const pPrivates = Object.keys(admins).map(u =>
    updateSingle('/v3/userGroupLastPrivate/' + u + '/' + group, {
      time: SERVER_TIMESTAMP, unread: true, publishPending: true, messageInfo: {from: getCurrentUser(), text: 'submitted a post', name}
    })
  );

  await Promise.all(pNotifs); await Promise.all(pPrivates);
}

export async function publishPost({author, group, post, text, user}) {
  // console.log('publishPost', {author, group, post, text, user});
  const postInfo = await fetchAuthorPostInfo({author, post});
  // console.log('publishPost', {postInfo, author, post});
  const pShareUpdate = updateSingle('/v3/authorPostGroupShare/' + author + '/' + post + '/' + group, {time: SERVER_TIMESTAMP, unpublished: false});
  const pGroupPost = setSingle('/v3/groupPost/' + group + '/' + post, {...postInfo, time: SERVER_TIMESTAMP});
  const pNotif = sendNotif({hasMessage: false, user, group, post, postAuthor: user, text, type: 'publishpost', action: 'published your post in'});
  // const lastUserName = await fetchUserName(user);
  const pStatus = setSingle('/v3/groupInfo/' + group + '/lastPost', {
    from: user, text: postInfo.title || text, time: SERVER_TIMESTAMP
  });
  await pShareUpdate; await pGroupPost; await pNotif; await pStatus;
}

// export async function likePost({group, post, user, like}) {
//   const pMe = setSingle('/v3/groupUserPostThemLike/' + group + '/' + getCurrentUser() + '/' +
//       post + '/' + user, like ? {time: SERVER_TIMESTAMP} : null);
//   const pThem = setSingle('/v3/groupUserPostThemLike/' + group + '/' + user + '/' +
//   post + '/' + getCurrentUser(), like ? {time: SERVER_TIMESTAMP} : null);
//   await pThem; await pMe;
// }

async function followGroupPost({group, post, follow, name}) {
  // console.log('followGroupPost', {group, post, follow, name});
  await setSingle('/v3/groupPostUserFollow/' + group + '/' + post + '/' + getCurrentUser(), follow ? {time: SERVER_TIMESTAMP, name} : null)
}

async function likeGroupPost({group, post, like, name}) {
  // console.log('followGroupPost', {group, post, follow, name});
  await setSingle('/v3/groupPostUserLike/' + group + '/' + post + '/' + getCurrentUser(), like ? {time: SERVER_TIMESTAMP, name} : null)
}


export async function watchAuthorPostLikes(obj, {author, post}, callback) {
  watchSingle(obj, '/v3/authorPostUserLike/' + author + '/'+ post + '/', callback);
} 
export async function watchGroupPostLikes(obj, group, callback) {
  watchSingle(obj, '/v3/groupPostUserLike/' + group, callback);
}

export async function likePost({author, post, group = null, title, like = true, hasNotif = true, created = false}) {
  const pPostInfo = fetchAuthorPostInfo({author, post});
  const pShares = fetchPostGroupShares({author, post});
  const pName = getCurrentUserName();
  const pGroupName = group ? getGroupName(group) : Promise.resolve(null);
  const shares = await pShares; const name = await pName; const postInfo = await pPostInfo; const groupName = await pGroupName;
  const pShareUpdates = Promise.all(Object.keys(shares).map(group => likeGroupPost({group, post, like, name})));

  const user = postInfo.from;
  const text = postInfo.text;
  const pLike = setSingle('/v3/authorPostUserLike/' + author + '/' + post + '/' + getCurrentUser(), like ? {time: SERVER_TIMESTAMP, name} : null);

  const pRead = markTalkTreeRead({post});
  var pNotif = Promise.resolve();
  var pPrivateThemLast = Promise.resolve();
  if (hasNotif && like) {
    pNotif = sendNotif({group, hasMessage: false, user, post, postAuthor: user, action: 'liked your post', text, type: 'like'})
    pPrivateThemLast = updateSingle('/v3/userPostLastPrivate/' + author + '/' + post, {
      time: SERVER_TIMESTAMP, replyInfo: {from: getCurrentUser(), text: 'liked your post', name}
    })
  }
  await pLike; await pNotif; await pRead; await pShareUpdates; await pPrivateThemLast;
}


export async function followPost({author, post, group = null, title, follow = true, hasNotif = true, created = false}) {
  const pPostInfo = fetchAuthorPostInfo({author, post});
  const pShares = fetchPostGroupShares({author, post});
  const pName = getCurrentUserName();
  const pGroupName = group ? getGroupName(group) : Promise.resolve(null);
  const shares = await pShares; const name = await pName; const postInfo = await pPostInfo; const groupName = await pGroupName;
  const pShareUpdates = Promise.all(Object.keys(shares).map(group => followGroupPost({group, post, follow, name})));

  const user = postInfo.from;
  const text = postInfo.text;
  const userName = postInfo.name;
  // console.log('followPost', {postInfo, shares, user, text, author, post});
  const pFollow = setSingle('/v3/authorPostUserFollow/' + author + '/' + post + '/' + getCurrentUser(), follow ? {time: SERVER_TIMESTAMP, name} : null);
  const pUserFollow = setSingle('/v3/userPostFollow/' + getCurrentUser() + '/' + post, !follow ? null : {
    time: SERVER_TIMESTAMP, title: postInfo.title || text, group, groupName,
    text: created ? 'you created this post' : 'you followed this post',
    user, userName
  });
  const pRead = markTalkTreeRead({post});
  var pNotif = Promise.resolve();
  var pPrivateThemLast = Promise.resolve();
  if (hasNotif && follow) {
    pNotif = sendNotif({hasMessage: false, user, post, postAuthor: user, action: 'followed your post', text, type: 'follow'})
    pPrivateThemLast = updateSingle('/v3/userPostLastPrivate/' + author + '/' + post, {
      time: SERVER_TIMESTAMP, replyInfo: {from: getCurrentUser(), text: 'followed your post', name}
    })

  }
  await pFollow; await pNotif; await pUserFollow; await pRead; await pShareUpdates; await pPrivateThemLast;
}

export async function watchPostFollows(obj, callback) {
  return watchSingle(obj, '/v3/userPostFollow/' + getCurrentUser(), callback);
}

export async function watchMyPosts(obj, callback) {
  return watchSingle(obj, '/v3/')
}

export async function likeReply({postAuthor, group, post, reply, text, user, like}) {
  // console.log('likeReply', {postAuthor, post, reply});
  const name = await getCurrentUserName();
  const pLike = setSingle('/v3/authorPostReplyUserLike/' + postAuthor + '/' +
      post + '/' + reply + '/' + getCurrentUser(), like ? {name, time: SERVER_TIMESTAMP} : null);
  if (like) {
    const pNotif = sendNotif({group, hasMessage: false, user, post, postAuthor, reply, type: 'likereply', text, action: 'liked your reply'})
    const pPrivateThemLast = updateSingle('/v3/userPostLastPrivate/' + user + '/' + post, {
      unread: true, replyInfo: {from: getCurrentUser(), text: 'liked your reply', name}, time: SERVER_TIMESTAMP
    })
    await pNotif; await pPrivateThemLast;
  
  }
  await pLike;
}


// TODO: do this at user level instead, so I only see posts I'm tagged on
export async function watchGroupPeopleTagged(obj, group, callback) {
  watchSingle(obj, '/v3/groupPostUserTagged/' + group, callback);
}

export async function watchMyGroupPostTags(obj, group, callback) {
  watchSingle(obj, '/v3/userGroupPostThemTagged/' + getCurrentUser() + '/' + group, callback);
}

export async function watchThemPostTags(obj, user, callback) {
  watchSingle(obj, '/v3/userThemPostTagged/' + getCurrentUser() + '/' + user, callback);
}

// export async function watchMyTags(obj, callback) {
//   watchSingle(obj, '/v3/userGroupPostThemTagged/' + getCurrentUser(), callback);
// }

export async function watchPostPeopleTagged(obj, {group, post}, callback) {
  // watchSingle(obj, '/v3/groupPostUserTagged/' + group + '/' + post, callback);
  watchSingle(obj, '/v3/userPostThemTagged/' + getCurrentUser() + '/' + post, callback);
}

export async function inviteUserToGroup({group, user}) {
  const pNotif = sendNotif({user, group, type: 'invite', text: '', action: 'invited you to join'});
  const pInvited = setSingle('/v3/userGroupInvited/' + getCurrentUser() + '/' + group + '/' + user, SERVER_TIMESTAMP);
  await pNotif; await pInvited;
}

export async function watchGroupInvited(obj, group, callback) {
  return watchSingle(obj, '/v3/userGroupInvited/' + getCurrentUser() + '/' + group, callback);
}

export async function tagPersonOnPost({post, group, postAuthor, user}) {
  const userName = await getUserName(user);
  const postInfo = await fetchSingle('/v3/authorPostInfo/' + postAuthor + '/' + post);
  const pUserTagged = setSingle('/v3/userThemPostTagged/' + getCurrentUser() + '/' + user + '/' + post, SERVER_TIMESTAMP);
  const pPostTagged = setSingle('/v3/userPostThemTagged/' + getCurrentUser() + '/' + post + '/' + user, SERVER_TIMESTAMP);
  const pUserShare = setSingle('/v3/authorPostUserShare/' + getCurrentUser() + '/' + post + '/' + user, {time: SERVER_TIMESTAMP, name: userName});
 
  const pNotif = sendNotif({user, post, group, postAuthor, type: 'tag', action: 'shared a post', image: postInfo.image, text: postInfo.text, title: postInfo.title});
  await pNotif; await pUserTagged; await pPostTagged; await pUserShare;
}

export async function sharePostToGroup({post, group, postAuthor, isAdmin = false}) {
  // console.log('shareGroupToPost', {post, group, postAuthor, isAdmin});
  const pName = await getCurrentUserName();
  const pPostInfo = await fetchAuthorPostInfo({author: postAuthor, post});
  const pGroupName = await getGroupName(group);
  const name = await pName; const postInfo = await pPostInfo; const groupName = await pGroupName;
  const pGroupPost = setSingle('/v3/groupPost/' + group + '/' + post,{
    from: getCurrentUser(), name, text:postInfo.text, time: SERVER_TIMESTAMP, unpublished: true, title:postInfo.title || null
  });
  const pShare = setSingle('/v3/authorPostGroupShare/' + getCurrentUser() + '/' + post + '/' + group, {
    unpublished: true, time: SERVER_TIMESTAMP, groupName
  })
  await pGroupPost; await pShare;

  if (isAdmin) {
    await publishPost({author: postAuthor, user: postAuthor, group, post, text: postInfo.text})
  } else {
    await notifyGroupAdmins({group, text:postInfo.text, title:postInfo.title, post, postAuthor: getCurrentUser(), type: 'newPost', action: 'submitted a post in'})
  }
}

export function watchGroupBoosts(obj, group, callback) {
  return watchSingle(obj, '/v3/userGroupPostBoost/' + getCurrentUser() + '/' + group, callback);
}

export function watchNotifs(obj, callback) {
  return watchSingle(obj, '/v3/userNotif/' + getCurrentUser(), callback);
}

export async function sendNotif({user, image=null, group=null, post=null, postAuthor=null, parentText=null, reply=null, type=null, action=null, text, title=null, hasMessage = true}) {
  if (user == getCurrentUser()) {
    return;
  }
  const key = newKey('notif');
  var groupName = null;
  if (group) {
    groupName = await getGroupName(group);
  }
  const pFromName = getCurrentUserName();
  const fromName = await pFromName;
  var pNotif;
  if (_.startsWith(user, 'anon-')) {
    const anonUser = user.slice(5);
    setSingle('/v3/userNotif/anon/' + key, {
      anonUser,
      from: getCurrentUser(), fromName,
      group, groupName, reply, parentText,
      to: user, text: title || text, post, postAuthor, type, action, time: SERVER_TIMESTAMP
    })
  } else {
    setSingle('/v3/userNotif/' + user + '/' + key, {
      from: getCurrentUser(), fromName,
      group, groupName, reply, parentText,
      to: user, text: title || text, post, postAuthor, type, action, time: SERVER_TIMESTAMP
    })
  }
  if (hasMessage) {
    await addMessage({user, text, title, image, group, post, postAuthor, type, reply, action, notif:key, hasNotif: false, groupName})
  }
  await pNotif;
}

export async function markNotifRead({notif}) {
  return setSingle('/v3/userNotifRead/' + getCurrentUser() + '/' + notif, SERVER_TIMESTAMP);
}

export async function watchNotifReads(obj, callback) {
  return watchSingle(obj, '/v3/userNotifRead/' + getCurrentUser(), callback);
}

export async function watchPostLastPrivate(obj, callback) {
  return watchSingle(obj, '/v3/userPostLastPrivate/' + getCurrentUser(), callback);
}

export async function addReply({postAuthor, anon, group, post, parent = null, parentText=null, parentBy = null, text, notifText = null, isPrivate = null}) {
  const key = newKey('reply');
  // const shares = await fetchPostGroupShares({author: postAuthor, post});
  const userName = await getCurrentUserName();

  // console.log('addReply', {postAuthor, post, shares});

  const replyInfo = {from: getCurrentUser(), text, name: userName};

  var pAnon = Promise.resolve(null);
  var pAnonShare = Promise.resolve(null);
  var pAnonSelf = Promise.resolve(null);
  if (anon) {
    pAnon = setSingle('/v3/secretAnonIdentity/' + key, {user: getCurrentUser(), name: userName, time: SERVER_TIMESTAMP});
    pAnonShare = setSingle('/v3/userAnonIdentity/' + parentBy + '/' + key, {user: getCurrentUser(), name: userName});
    pAnonSelf = setSingle('/v3/userAnonIdentity/' + getCurrentUser() + '/' + key, {user: getCurrentUser(), name: userName});
  }

  const pReply = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + key, {
    from: anon ? ('anon-' + key) : getCurrentUser(), time: SERVER_TIMESTAMP, text, unpublished: true, isPrivate,
    name: anon ? 'Anonymous' : userName,
    parent, parentBy
  })
  const pNotif = sendNotif({hasMessage: false, user: parentBy, group, text: notifText || text, parentText, post, postAuthor, reply: key, 
    type: 'reply', action: anon ? 'replied anonymously' : 'replied'});
  const name = await getCurrentUserName();
  // const pLastPrivate = Promise.all(Object.keys(shares).map(async group => {
  const pThemLast = await setSingle('/v3/userGroupPostLastPrivate/' + parentBy + '/' + group + '/' + post, {
    from: getCurrentUser(), name, time: SERVER_TIMESTAMP, text, reply: key
  })
  const pMeLast = await setSingle('/v3/userGroupPostLastPrivate/' + getCurrentUser() + '/' + group + '/' + post, {
    from: getCurrentUser(), name, time: SERVER_TIMESTAMP, text, reply: key, isPrivate, unpublished: true,
  });
  // }));
  const pPrivateThemLast = updateSingle('/v3/userPostLastPrivate/' + parentBy + '/' + post, {
    unread: true, publishPending: isPrivate ? undefined : true, replyInfo, time: SERVER_TIMESTAMP
  })
  const pPrivateMeLast = updateSingle('/v3/userPostLastPrivate/' + getCurrentUser() + '/' + post, {
    replyInfo, time: SERVER_TIMESTAMP
  })

  await pReply; await pNotif;
  // await pThemGroupLast; await pThemPostTime;
  await pPrivateThemLast; await pPrivateMeLast; await pThemLast; await pMeLast; await pAnon; await pAnonShare; await pAnonSelf;
  await followPost({author: postAuthor, group, post, hasNotif:false})
  return key;
}

export function watchAnonIdentity(obj, callback) {
  watchSingle(obj, '/v3/userAnonIdentity/' + getCurrentUser(), callback);
}


// TODO: Re-do data to fix security
export async function editReply({postAuthor, anon, post, group, reply, user, parent, parentBy, text, isPrivate = null}) {
  // console.log('editReply', {postAuthor, post, reply});
  const pReplyText = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/text', text);
  const pReplyTime = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/time', SERVER_TIMESTAMP);
  const pReplyEdited = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/edited', true);
  const pReplyPrivate = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/isPrivate', isPrivate);

  if (anon) {    
    const userName = await getCurrentUserName();
    const pReplyFrom = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/from', 'anon-' + reply);
    const pReplyName = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/name', 'Anonymous');
    const pAnon = setSingle('/v3/secretAnonIdentity/' + reply, {user: getCurrentUser(), name: userName, time: SERVER_TIMESTAMP});
    const pAnonShare = setSingle('/v3/userAnonIdentity/' + parentBy + '/' + reply, {user: getCurrentUser(), name: userName});
    const pAnonSelf = setSingle('/v3/userAnonIdentity/' + getCurrentUser() + '/' + reply, {user: getCurrentUser(), name: userName});

    await pAnon; await pAnonShare; await pAnonSelf; await pReplyFrom; await pReplyName; 
  } else {
    const userName = await getCurrentUserName();
    const pReplyFrom = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/from', getCurrentUser());
    const pReplyName = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/name', userName);
    await pReplyFrom; await pReplyName;
  }

  await pReplyText; await pReplyTime; await pReplyPrivate; await pReplyEdited;
}

export async function publishReply({postAuthor, post, user, reply, group, /* authorName, */ text}) {
  // const shares = await fetchPostGroupShares({author: postAuthor, post});
  const pName = await getCurrentUserName();
  const pAuthorName = await getUserName(user);
  const name = await pName; const authorName = await pAuthorName;
  // const pGroupLast = Promise.all(Object.keys(shares).map(group => 
  const pGroupLast = setSingle('/v3/groupPostLastPublic/' + group + '/' + post, {
    from: getCurrentUser(), author: user, name: authorName, text, time: SERVER_TIMESTAMP, reply
  })
  // ))

  const pUpdate = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/unpublished', null);
  const pTime = setSingle('/v3/groupPostBroadcast/' + group + '/' + post + '/' + reply + '/time', SERVER_TIMESTAMP);
  const pNotif = sendNotif({hasMessage: false, user, post, group, postAuthor, reply, type: 'publish', action: 'published your reply', text});
  const pPublicLast = setSingle('/v3/authorPostLastPublic/' + postAuthor + '/' + post, {
    // title: postInfo.title || postInfo.text, group,
    // groupName: await getGroupName(group), userName:
    from: getCurrentUser(), author: user, name: authorName, text, time: SERVER_TIMESTAMP, reply
  })
  const pPrivateThemLast = updateSingle('/v3/userPostLastPrivate/' + user + '/' + post, {
    unread: true, replyInfo: {from: getCurrentUser(), text: 'published your reply', name}, time: SERVER_TIMESTAMP
  })


  // const pNotif = setSingle('/v3/userNotif/' + user + '/' + newKey('publish'), {
  //   from: getCurrentUser(), to: user, text: 'published your reply', group, post, type: 'publish', time: SERVER_TIMESTAMP
  // })
  await pUpdate; await pTime; await pNotif; await pPublicLast; await pGroupLast;
  await pPrivateThemLast;
}


export async function watchNetworkConnected(obj, callback) {
  return watchSingle(obj, '/.info/connected', callback, false);
}

export async function submitAbuseReport({user=null, group=null, post=null, options=null, extraInfo=null}) {
  const key = newKey('abuse');
  await setSingle('/v3/userAbuseReport/' + getCurrentUser() + '/' + key, {user, group, post, options, extraInfo, time: SERVER_TIMESTAMP});
}

export async function getNotifToken() {
  return await fetchSingle('/v2/userToNotifToken/' + getCurrentUser());
}

export async function setNotifToken(token) {
  return await setSingle('/v2/userToNotifToken/' + getCurrentUser(), token);
}

export async function markAllNotifsRead() {
  return await setSingle('/v3/userAllNotifsRead/' + getCurrentUser(), SERVER_TIMESTAMP);
}

export async function markAppUsed() {
  return await setSingle('/v3/userActive/' + getCurrentUser(), SERVER_TIMESTAMP);
}

export async function watchAllNotifsReadTime(obj, callback) {
  return await watchSingle(obj, '/v3/userAllNotifsRead/' + getCurrentUser(), callback, 0);
}

export async function watchGroupFollows(obj, group, callback) {
  return await watchSingle(obj, '/v3/groupPostUserFollow/' + group, callback);
}

export async function watchAuthorPostFollows(obj, {author, post}, callback) {
  return await watchSingle(obj, '/v3/authorPostUserFollow/' + author + '/' + post, callback);
}

export async function watchAuthorPostGroupShares(obj, {author, post}, callback) {
  return await watchSingle(obj, '/v3/authorPostGroupShare/' + author + '/' + post, callback);
}

export async function watchAuthorPostUserShares(obj, {author, post}, callback) {
  return await watchSingle(obj, '/v3/authorPostUserShare/' + author + '/' + post, callback);
}

export async function watchGroupLastPublic(obj, group, callback) {
  return await watchSingle(obj, '/v3/groupPostLastPublic/' + group, callback);
}

export async function watchGroupLastPrivate(obj, group, callback) {
  return await watchSingle(obj, '/v3/userGroupPostLastPrivate/' + getCurrentUser() + '/' +  group, callback);
}


export async function addMessage({user, text, image=null, meta=null, notif=null, type=null, action=null, group=null, post=null, postAuthor=null, hasNotif=true, title=null, replyMessage=null, replyMessageInfo=null, groupName=null, reply=null}) {
  if (user == getCurrentUser()) {
    return null;
  }
  const key = newKey('message');
  const message = {
    from: getCurrentUser(), text, image, time: SERVER_TIMESTAMP, meta, post, postAuthor, title, replyMessage, notif, group, type, action, groupName, reply,
    replyTitle: _.get(replyMessageInfo, 'title', null),
    replyText: _.get(replyMessageInfo, 'text', null)
  }
  const pMe = setSingle('/v3/userThemMessage/' + getCurrentUser() + '/' + user + '/' + key, message);
  const pThem = setSingle('/v3/userThemMessage/' + user + '/' + getCurrentUser() + '/' + key, message);
  var pMeLast = Promise.resolve();
  // var pThemLast = Promise.resolve();
  var pRead = Promise.resolve();
  if (!type) {
    pMeLast = setSingle('/v3/userThemLast/' + getCurrentUser() + '/' + user, message);
    pRead = markUserChatRead(user);
  }
  const pThemLast = setSingle('/v3/userThemLast/' + user + '/' + getCurrentUser(), message);

  if (hasNotif) {
    await sendNotif({user, text, hasMessage: false});
  }
  await pMe; await pThem; await pMeLast; await pThemLast; await pRead;
  return key;
}

export async function watchUserMessages(obj, user, callback) {
  return watchSingle(obj, '/v3/userThemMessage/' + getCurrentUser() + '/' + user, callback);
}

export async function watchLastMessages(obj, callback) {
  return watchSingle(obj, '/v3/userThemLast/' + getCurrentUser(), callback);
}

export async function markUserChatRead(user) {
  // console.log('mark user chat read', user);
  await setSingle('/v3/userThemRead/' + getCurrentUser() + '/' + user, SERVER_TIMESTAMP);
}

export async function watchAllUserChatReads(obj, callback) {
  return watchSingle(obj, '/v3/userThemRead/' + getCurrentUser(), callback);
}

export async function markNotifsSkipped() {
  await setSingle('/v3/userNotifsDisabled/' + getCurrentUser(), SERVER_TIMESTAMP);
}

export async function watchNotifsSkipped(obj, callback) {
  await watchSingle(obj, '/v3/userNotifsDisabled/' + getCurrentUser(), callback, false);
}

export async function watchHelpCleared(obj, callback) {
  await watchSingle(obj, '/v3/userHelpCleared/' + getCurrentUser(), callback);
}

export async function markHelpCleared(prompt) {
  await setSingle('/v3/userHelpCleared/' + getCurrentUser() + '/' + prompt, SERVER_TIMESTAMP);
}


export async function saveImage({base64data, key}) {
  await uploadImage({base64data, isThumb: false, userId: getCurrentFbUid(), key});
}


export async function saveThumbnailImage({base64data, key}) {
  await uploadImage({base64data, isThumb: true, userId: getCurrentFbUid(), key});
}

export function getUrlForImage({image}) {
  const path = 'image/' + image + '.jpg';
  return getUrlForFile(path);
}

export function getUrlForThumbnailImage({image}) {
  if (!image) return null;
  const path = 'thumbnail/' + image + '.jpg';
  return getUrlForFile(path);
}

export function getAppUrlForImage({image}) {
  return 'https://talkbeat.org/image?image='+encodeURIComponent(image);
}

export async function logMessage(message, props = null) {
  if (getCurrentUser() == 'rob' && Platform.OS != 'web') {
    const key = newKey('log');
    await setSingle('/v3/userLog/' + getCurrentUser() + '/' + key, {message, props, time: SERVER_TIMESTAMP});
    console.log(message, props || '');
  }
}

export async function watchLogs(obj, user, callback) {
  await watchSingle(obj, '/v3/userLog/' + getCurrentUser(), callback);
}

// export async function getPublicCount(obj, {group, post}, callback) {

// }

var currentUser;
var currentUserName;
var currentFbUid;
export function getCurrentUser(){
  return currentUser;
}
// export function getLoadedCurrentUserName() {
//   return currentUserName || currentUser;
// }
export function getCurrentFbUid() {
  return currentFbUid;
}
export function setCurrentUser(user, fbUid){
  currentUser = user;
  currentFbUid = fbUid;
}
export function setCurrentUserName(name) {
  currentUserName = name;
}
export async function withUser(name, callback) {
  const oldUser = currentUser;
  currentUser = name;
  await callback()
  currentUser = oldUser;
}
