
/* global window */

export function getCurrentPath(){
  return window.location.href.slice(window.location.origin.length);
}



export function getUserAgent(){
  return window.navigator.userAgent.toLocaleLowerCase();
}

export function watchWindowUrlChange(callback) {
  window.addEventListener('popstate', callback);
}

