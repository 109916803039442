import React from 'react';
import { watchGroupInfo, watchGroupUsers, releaseWatchers, getCurrentUser } from './data';
import { View, Text, Platform, StyleSheet } from 'react-native';
import { LoadingScreen, DefaultImage, baseColor, ScreenContentFlatList, Button, UserIcon } from './ui';
import _ from 'lodash';
import { NavClickable } from '../components/basics';
import Entypo from 'react-native-vector-icons/Entypo';

export class GroupInfoHeader extends React.Component {
  state = {name: 'Group', users: null};
  async componentDidMount() {
    const {group} = this.props;
    watchGroupInfo(this, group, groupInfo => this.setState({name: _.get(groupInfo,'name', 'Group')}));
  }
  componentWillUnmount() {releaseWatchers(this)}

  render () {
    const {name, users} = this.state;
    // const color = Platform.OS == 'web' ? 'black' : 'white';
    const color = 'white'
    return (
        <View style={{alignItems: Platform.OS == 'web' ? undefined : 'center'}}>
          <Text numberOfLines={1} style={{color, fontSize: 16}}>
            About <Text style={{fontWeight: 'bold'}}>{name}</Text>
          </Text>
        </View>
    )
  } 
}

function SectionHead({children}) {
  return (
    <View style={{marginTop: 16, marginLeft: 16, marginBottom: 10}}>
      <Text style={{fontWeight: 'bold', fontSize: 16}}>{children}</Text>
    </View>
  )
}

function MemberPreview({userInfo, user}) {
  return (
    <View style={{flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16, paddingVertical: 8}}>
      <UserIcon user={user} userName={userInfo.name} size={30} />
      <Text style={{marginLeft: 10, fontSize: 16, fontWeight: '500'}}>{userInfo.name}</Text>
      {userInfo.mode == 'member' ? null :
        <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, 
              borderRadius: 16, paddingHorizontal: 8, paddingVertical: 6,
              backgroundColor: '#666',marginLeft: 8
              }}>
          <Text style={{color: 'white'}}>{userInfo.mode}</Text>
        </View> 
      }
    </View>
  )
}

function GroupInvite({group}) {
  return (
    <NavClickable navAction='groupInvite' navProps={{group}}>
      <View style={{flexDirection: 'row'}}>
        <View style={{flexDirection: 'row', alignItems: 'center', height: 30, marginLeft: 16, 
              marginVertical: 8,
              backgroundColor: baseColor, borderRadius: 16, paddingRight: 16, paddingLeft: 4, paddingVertical: 0}}>
          <Entypo name='plus' size={28} color='white'/>
          <Text style={{marginLeft: 10, fontSize: 16, color: 'white'}}>Invite New Members</Text>
        </View>
      </View>
    </NavClickable>

  )
}


function EditButton({group}) {
  return (
    <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginVertical: 16}}>
      <Button navAction='editGroup' navProps={{group}} >
        Edit
      </Button>
    </View>    
  )
}


export class GroupInfoScreen extends React.Component {
  state = {groupInfo: null, users: null}
  componentDidMount() {
    const {group} = this.props;
    watchGroupInfo(this, group, groupInfo => this.setState({groupInfo: groupInfo || {}}));
    watchGroupUsers(this, group, users => this.setState({users: users || {}}));
  }
  componentWillUnmount() {releaseWatchers(this)}

  render() {
    const {groupInfo, users} = this.state;
    const {group} = this.props;
    if (!groupInfo || !users) return <LoadingScreen />
    const sortedMembers = Object.keys(users);
    if (!groupInfo || !users) return <LoadingScreen/>
    const mode = _.get(users,[getCurrentUser(),'mode']);
    return (
      <ScreenContentFlatList style={{backgroundColor: 'white'}} data={[
        {key: '_edit', value: () => (mode != 'admin') ? null : <EditButton group={group} />},
        {key: 'abouthead', value: () => <SectionHead>About</SectionHead>},
        {key: 'intro', value: () => <Text style={{marginHorizontal: 16}}>{groupInfo.intro}</Text>},
        {key: 'memberhead', value: () => <SectionHead>Members</SectionHead>},
        {key: 'invite', value: () => <GroupInvite group={group} />},
        ...sortedMembers.map(u => ({key: u, value: () =>
          <MemberPreview userInfo={users[u]} user={u} />
        }))
      ]} />
    )
  }

}