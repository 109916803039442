import React from 'react';
import { View } from 'react-native';
import IonIcons from 'react-native-vector-icons/Ionicons';
import Entypo from 'react-native-vector-icons/Entypo';
import { TextBox } from './testable';
import { FixedTouchable } from './basics';
import { HelpSearchPeople } from '../slowgroup/HelpPrompt';

export function SearchBox({help, onChangeText, search, placeholder='Search'}) {
  return (
    <View style={{marginVertical: 16}}>
      <View style={{borderRadius: 8, marginHorizontal: 10,
          flexDirection: 'row', alignItems: 'center', 
          backgroundColor: '#eee'}}>
        <IonIcons name='ios-search' size={20} color='#999' style={{marginLeft: 8}} />
        {/* <FontAwesome name='search' size={20} color='#ddd' style={{marginLeft: 8}} /> */}
        <TextBox value={search || ''} underlineColorAndroid='transparent' placeholder={placeholder} 
            onChangeText={onChangeText} style={{flex: 1, fontSize: 16, padding: 8}}/>
        {!search ? null : 
          <FixedTouchable onPress={()=>onChangeText('')}>
            <Entypo name='circle-with-cross' size={20} color='#999' style={{marginHorizontal: 8}} />   
          </FixedTouchable>
        }
      </View>
      <HelpSearchPeople help={help} />
    </View>
  )
}
