
import firebase from '@firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

var config = {
  apiKey: "AIzaSyDaqI3qljjVAyEMv2uuSUdSirVxgQ-McM0",
  authDomain: "slowgroup.org",
  databaseURL: "https://slowgroup-1.firebaseio.com",
  projectId: "slowgroup-1",
  storageBucket: "slowgroup-1.appspot.com",
  messagingSenderId: "523058769729",
  appId: "1:523058769729:web:1bb06c167c3047d6"
};

export const INTERNAL_TIMESTAMP = firebase.database.ServerValue.TIMESTAMP; 

export default firebase.initializeApp(config);
