import React from 'react';
import { watchLogs, releaseWatchers } from './data';
import { ScreenContentFlatList, ScreenContentBottomFlatList, LoadingScreen } from './ui';
import { sortKeysBy } from './util';
import { View, Text } from 'react-native';
import { formatTimeAgo } from '../basics/util';

function LogItem({logInfo}) {
  return (
    <View style={{backgroundColor: 'white', margin: 4, padding: 8}}>
      <View style={{flexDirection: 'row',}}>
        <Text style={{fontWeight: 'bold'}}>{logInfo.message}</Text>
        {!logInfo.props ? null : 
          <Text>{JSON.stringify(logInfo.props)}</Text>
        }
      </View>
      <Text style={{fontSize: 12, color: '#666'}}>
        {formatTimeAgo(logInfo.time)}
      </Text>
    </View>
  )
}

export class LogViewScreen extends React.Component {
  state = {logs: null}
  componentDidMount() {
    watchLogs(this, 'rob', logs => this.setState({logs})); 
  }
  componentWillUnmount() {releaseWatchers(this)}

  render() {
    const {logs} = this.state;
    if (logs == null) return <LoadingScreen />

    const sortedLogs = sortKeysBy(logs, l => l.time);
    return (
      <ScreenContentBottomFlatList data={[
        ...sortedLogs.map(l => ({key: l, value: () =>
          <LogItem logInfo={logs[l]} />
        }))
      ]} />
    )
  }



}