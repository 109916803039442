import React from 'react';
import { Image, View } from 'react-native';
import { getUrlForImage } from './data';

export class ImageScreen extends React.Component {
  state = {url: null}

  async componentDidMount() {
    const {image, user} = this.props;
    if (image) {
      const url = await getUrlForImage({image, user});
      this.setState({url});
      // console.log('url', url);
    }
  }

  render() {
    const {url} = this.state;
    if (url) {
      return <Image source={{uri: url}} style={{flex: 1, backgroundColor: 'black'}} resizeMode='contain' />
    } else {
      return null;
    }
  }
}
