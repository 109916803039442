
import React from 'react';
import {StyleSheet, ScrollView, View, Platform, Text, Keyboard} from 'react-native';
import { FlatList } from 'react-native';
import { Catcher } from '../basics/catcher';


export class BottomScrollView extends React.Component {
  scrolled = false;

  scrollToEnd() {
    if (this.props.scrollToEnd && !this.scrolled) {
      this.scrollview.scrollToEnd(false);
      this.scrolled = true;
    }
  }

  render() {
    const {children} = this.props;
    return (
      <ScrollView ref={r => this.scrollview = r} 
          onLayout={() => this.scrollToEnd()}
          >
        {children}
      </ScrollView>
    )
  }
}

function basicRenderItem ({item: {key, item, value}}) {
  return <Catcher>{item || value()}</Catcher>;
}


// HACK: Inverted flatlist doesn't work on the web, so we work around that
export class BottomFlatList extends React.Component {
  state = {height:0}

  flatList = null;

  componentDidUpdate() {
    this.maybeGoToEnd()
    setTimeout(() => {
      this.maybeGoToEnd()
    },400)
  }

  scrollToEnd() {
    console.log('scroll to end');
    if (Platform.OS == 'web') {
      this.flatList.scrollToEnd();
    } else {
      this.flatList.scrollToIndex({index: 0, viewOffset: 0, viewPosition: 0.5});
    }
  }

  maybeGoToEnd() {
    if (Platform.OS == 'web') {
      this.flatList.scrollToEnd();  
    }
  }

  componentDidMount() {
    this.maybeGoToEnd();
  }

  contentHeightChanged({height}) {
    this.setState({height});
    this.maybeGoToEnd();
  }

  render () {
    const {data, renderItem} = this.props;
    if (Platform.OS == 'web') {
      return ( 
        <ScrollView ref={r => this.flatList = r}>
          {data.map(x => {
            const {key, value} = x;
            return <Catcher key={key}>{value()}</Catcher>
          })}
        </ScrollView>
      )
    } else {
      return (
        <View style={styles.topPusher} > 
          <FlatList inverted data={data.slice().reverse()} 
              keyboardDismissMode='on-drag'
              renderItem={renderItem || basicRenderItem}
              style={{flex: 1}} />
          </View>
      )
      // var scrollStyle;
      // if (this.state.height) {
      //   scrollStyle = {
      //     maxHeight: this.state.height,
      //   }
      // } else {
      //   scrollStyle = {flex: 1}
      // }
      // return (
      //   <View style={styles.topPusher} > 
      //     <FlatList inverted data={data.slice().reverse()} renderItem={renderItem || basicRenderItem}
      //         onLayout={()=>this.maybeGoToEnd()}
      //         onContentSizeChange={(width, height) => this.contentHeightChanged({height})}
      //         ref={r => this.flatList = r}
      //         style={scrollStyle} />
      //   </View>
      // )
    }
  }
}

export function TopFlatList({data}) {
  return <FlatList data={data} renderItem={basicRenderItem} automaticallyAdjustContentInsets={false}
    // contentContainerStyle={{flex: 1}}
      keyboardDismissMode='on-drag' keyboardShouldPersistTaps='always' />
}

export function BasicScroller({children}) {
  return <ScrollView>{children}</ScrollView>
}


const styles = StyleSheet.create({
  topPusher: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }
})

