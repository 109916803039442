import React from 'react';
import { Platform, Text, View, SafeAreaView, StyleSheet } from 'react-native';
import { watchNotifs, releaseWatchers, watchNotifReads, markNotifRead, markAllNotifsRead, watchAllNotifsReadTime, markAppUsed, watchLastMessages, watchAllUserChatReads } from './data';
import { LoadingScreen, ScreenContentFlatList, DefaultImage, unreadColor, KeyboardLessScreenContentFlatList } from './ui';
import { sortKeysBy } from './util';
import { formatTimeAgo } from '../basics/util';
import { NavClickable, FixedTouchable } from '../components/basics';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {Notifications} from 'expo';
import _ from 'lodash';
import { variableDeclaration } from '@babel/types';

export function NotifListHeader() {
  const androidPadding = (Platform.OS == 'android' ? 25 : 0);
  const extraPadding = (Platform.OS == 'ios' && parseInt(Platform.Version) < 11) ? 16 : androidPadding;
  return (
    <SafeAreaView style={{backgroundColor: 'white'}}>
      <View>
        <View style={{backgroundColor: 'white', flexDirection: 'row', 
            justifyContent: 'space-between', alignItems: 'center', 
            paddingTop: 12 + extraPadding, paddingBottom: 8,
            paddingHorizontal: 16
            }}>
          <Text numberOfLines={1} textAlign='left' style={{fontWeight: 'bold', fontSize: 32}}>
            Notifications
          </Text>
        </View>
      </View>
    </SafeAreaView>
  )
}

// TODO: Do this more efficiently - e.g. just write a value we update
export class PeopleCountIcon extends React.Component {
  state = {notifs: null, notifReads: null, allNotifReadTime: null}

  componentDidMount() {
    watchLastMessages(this, userLasts => this.setState({userLasts}));
    watchAllUserChatReads(this, userReads => this.setState({userReads}));
    watchAllNotifsReadTime(this, allNotifReadTime => this.setState({allNotifReadTime}));
  }
  componentWillUnmount() {releaseWatchers(this)}

  render() {
    // console.log('render notif count icon');

    const {tintColor} = this.props;
    const {userLasts, userReads} = this.state;

    var count = 0;
    if (userLasts != null && userReads != null) {
      const unreadPeople = Object.keys(userLasts).filter(u => userLasts[u].time > userReads[u]);
      count = unreadPeople.length;
    }

    return (
      <View>
        <Ionicons name='ios-people' size={25} color={tintColor} /> 
        {count == 0 ? null : 
          <View style={{
            position: "absolute", top: 0, right: -8,
            width: 16, height: 16, borderRadius: 8,
            backgroundColor: '#ff0000',
            alignItems: 'center', justifyContent: 'center'
          }} >
            <Text style={{color: 'white', fontSize: 10}}>{count}</Text>
          </View>
        }
      </View>
    )
  }  
}


// TODO: Do this more efficiently - e.g. just write a value we update
export class NotifCountIcon extends React.Component {
  state = {notifs: null, notifReads: null, allNotifReadTime: null}

  componentDidMount() {
    watchNotifs(this, notifs => this.setState({notifs}));
    watchNotifReads(this, notifReads => this.setState({notifReads}));
    watchAllNotifsReadTime(this, allNotifReadTime => this.setState({allNotifReadTime}));
  }
  componentWillUnmount() {releaseWatchers(this)}

  render() {
    // console.log('render notif count icon');

    const {tintColor} = this.props;
    const {notifs, notifReads, allNotifReadTime} = this.state;
    var count = 0;
    if (allNotifReadTime != null && notifs != null && notifReads != null) {
      const unread = notifReads ? Object.keys(notifs).filter(n => notifs[n].type && !notifReads[n] && notifs[n].time > allNotifReadTime) : Object.keys(notifs);
      count = unread.length;      
    }

    if (Platform.OS != 'web' && allNotifReadTime != null && notifs != null) {
      Notifications.setBadgeNumberAsync(count);
    }  

    return (
      <View>
        <Ionicons name='ios-notifications' size={25} color={tintColor} /> 
        {count == 0 ? null : 
          <View style={{
            position: "absolute", top: 0, right: -8,
            width: 16, height: 16, borderRadius: 8,
            backgroundColor: '#ff0000',
            alignItems: 'center', justifyContent: 'center'
          }} >
            <Text style={{color: 'white', fontSize: 10}}>{count}</Text>
          </View>
        }
      </View>
    )
  }  
}

export function NotifPreview({notif, notifInfo, showExpander = false, unread = false, inChat = false}) {
  // const unread = notifInfo.time > lastRead;
  return (
    <View style={{backgroundColor: 'white', marginRight: inChat ? 32 : undefined, marginBottom: inChat? 8 : undefined}}>
      <NavClickable navAction={notifInfo.post ? 'post' : (notifInfo.group ? 'group' : 'user')} 
          onNav={() => markNotifRead({notif})}
          navProps={{user: notifInfo.from, group: notifInfo.group, author: notifInfo.postAuthor, post: notifInfo.post, shownReply: notifInfo.reply}}>
        <View style={{paddingHorizontal: 16, paddingVertical: 8,
            flexDirection: 'row', alignItems: 'flex-start',
            backgroundColor: unread ? unreadColor : null
            }}>
          <DefaultImage colorKey={notifInfo.from} name={notifInfo.fromName} size={40} />
          <View style={{marginLeft: 10, flex: 1}}>
            <Text numberOfLines={1} style={{color: 'black'}}>
              <Text style={{fontWeight: 'bold'}}>{notifInfo.fromName} {notifInfo.action} </Text>
              <Text>in {notifInfo.groupName}</Text>
            </Text>
            <Text numberOfLines={2} style={{color: '#666', marginVertical: 2}}>{notifInfo.text}</Text>
            <Text numberOfLines={1} style={{marginTop: 1, color: 'rgb(101, 119, 134)', fontSize: 12}}>{formatTimeAgo(notifInfo.time)}</Text>
          </View>
        </View>
      </NavClickable>
      {!(showExpander && notifInfo.type == 'likereply') ? null : 
        <View style={{flexDirection: 'row', marginVertical: 4}}>
          <NavClickable navAction='notifs' navProps={{showAll: true}}>
            <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, 
                paddingHorizontal: 10, paddingVertical: 4, borderRadius: 16, marginLeft: 64}}>
              <Text style={{color: '#666'}}>Show more likes</Text>
            </View>
          </NavClickable>
        </View>
      }
      {!(showExpander && notifInfo.type == 'publish') ? null : 
        <View style={{flexDirection: 'row', marginVertical: 4}}>
          <NavClickable navAction='notifs' navProps={{showAll: true}}>
            <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, 
                paddingHorizontal: 10, paddingVertical: 4, borderRadius: 16, marginLeft: 64}}>
              <Text style={{color: '#666'}}>Show more publish actions</Text>
            </View>
          </NavClickable>
        </View>
      }
      {!(showExpander && notifInfo.type == 'follow') ? null : 
        <View style={{flexDirection: 'row', marginVertical: 4}}>
          <NavClickable navAction='notifs' navProps={{showAll: true}}>
            <View style={{borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth, 
                paddingHorizontal: 10, paddingVertical: 4, borderRadius: 16, marginLeft: 64}}>
              <Text style={{color: '#666'}}>Show more follows</Text>
            </View>
          </NavClickable>
        </View>
      }


    </View>
  )
}

function MarkReadButton() {
  return (
    <View style={{
        // backgroundColor: '#eee', 
        flexDirection: 'row', justifyContent: 'flex-end',
        // borderBottomWidth: StyleSheet.hairlineWidth, borderBottomColor: '#ddd'
      }}>
      <FixedTouchable part='markallread' onPress={() => markAllNotifsRead()} >
        <View style={{flexDirection: 'row', alignItems: 'center', paddingHorizontal: 8, margin: 8,       
              borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth}}>
          <Ionicons name='ios-done-all' size={30} color='#222' />
          <Text style={{marginLeft: 4, color: '#222'}}>Mark all read</Text>
        </View>
      </FixedTouchable>
    </View>
  )
}

function filterExtraLikesAndPublishes({sortedNotifs, notifs}) {
  var hasLike = false;
  var hasPublish = false;
  var hasFollow = false;
  var newArray = [];
  sortedNotifs.forEach(n => {
    if (notifs[n].type == 'likereply') {
      if (!hasLike) {
        hasLike = true;
        newArray.push(n);
      }
    } else if (notifs[n].type == 'publish') {
      if (!hasPublish) {
        hasPublish = true;
        newArray.push(n);
      }
    } else if (notifs[n].type == 'follow') {
      if (!hasFollow) {
        hasFollow = true;
        newArray.push(n);
      }
    } else {
      newArray.push(n);
    }
  })
  return newArray;
}

export class NotifListScreen extends React.Component {
  state = {notifs: null, notifReads: null, allNotifReadTime: null}

  componentDidMount() {
    watchNotifs(this, notifs => this.setState({notifs}));
    watchNotifReads(this, notifReads => this.setState({notifReads}));
    watchAllNotifsReadTime(this, allNotifReadTime => this.setState({allNotifReadTime}));
    markAppUsed();
  }
  componentWillUnmount() {
    releaseWatchers(this);
  }

  render() {
    const {user} = this.props;
    const {notifs, notifReads, allNotifReadTime} = this.state;
    // const {showAll} = this.props;
    const showAll = true;
    if (notifs == null || notifReads == null) {
      return <LoadingScreen />
    }

    const sortedNotifs = sortKeysBy(notifs, n => n.time).reverse();
    var visibleNotifs;
    if (showAll) {
      visibleNotifs = sortedNotifs.filter(n => notifs[n].type);
    } else {
      visibleNotifs = filterExtraLikesAndPublishes({sortedNotifs, notifs});
    }
    if (user) {
      visibleNotifs = sortedNotifs.filter(n => notifs[n].from == user && notifs[n].type);
    }
    return (
      <KeyboardLessScreenContentFlatList key='notifs' statusdark style={{backgroundColor: 'white'}} data={[
        {key: '$allread', value: () => user ? null :
          <MarkReadButton />
        },
        ...visibleNotifs.map(n => ({key: n, value: () => 
          <NotifPreview notif={n} notifInfo={notifs[n]} 
              showExpander={!showAll}
              unread={_.get(notifs,[n,'time'],0) > Math.max(_.get(notifReads, n, 0),allNotifReadTime)}
              />
        }))
      ]} />
    )
  }
}

export function PersonNotifsScreen({user}) {
  return <NotifListScreen user={user} />
}
