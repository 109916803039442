import { registerRootComponent } from 'expo';
import React from 'react';
import { View, Platform } from 'react-native';
import App from './App';

/* global document */


// console.log('Hello');

if (Platform.OS == 'web') {
  const style = document.createElement('link');
  style.setAttribute('href', 'https://talkful.org/app.css');
  style.setAttribute('rel', 'stylesheet');
  document.head.appendChild(style);  
  document.body.style.setProperty('height','100%')
}

registerRootComponent(App);
