import _ from 'lodash';
import { Platform, StyleSheet } from 'react-native';
import { getUserAgent } from '../basics/web';

export function sortKeysBy(object, func) {
  const keys = Object.keys(object);
  const keyToSortVal = _.mapValues(object, (c,k) => func(c,k));
  return keys.sort((a,b) => keyToSortVal[a] - keyToSortVal[b]);
}

export function plural(array, word, pluralWord=null, subtract = 0){
  if ((array.length - subtract) == 1) {
    return word;
  } else {
    return pluralWord || (word+'s')
  } 
}

export function pluralNum(num, word, pluralWord=null){
  if (num == 1) {
    return word;
  } else {
    return pluralWord || (word+'s')
  } 
}


export function getFirstName(name) {
  return name.split(' ')[0];
}

export function doesTextMatchPrefix(name, prefix) {
  if (!name) return false;
  return (' ' + name.toLowerCase()).indexOf(' ' + prefix.toLowerCase()) != -1;
}


export function countNewlines(str = '') {
  var count = 0;
  for (var i = 0; i < str.length; i++) {
    if (str[i] == '\n') {
      count++;
    }
  }
  return count;
}



export function getPostVerb({postStatus, response=false}) {
  switch (postStatus.type) {
    case 'asktojoin': return 'asked to join';
    case 'newmember': return 'joined the group';
    case 'published': return 'published a message';
    default: 
      if (response) {
        return 'published a message'
      } else if (postStatus.isPrivate) {
        return '- private to tagged people';
      } else if (postStatus.unpublished) {
        return '- not yet published';
      } else {
        return ''        
      }
  }
}

export function filterObject(obj = {}, func) {
  var out = {};
  Object.keys(obj).forEach(k => {
    if(func(obj[k],k)){
      out[k] = obj[k]
    }
  })
  return out;
}

function isIOSBrowser() {
  if (Platform.OS == 'web') {
    return /iphone|ipod|ipad/.test( getUserAgent() );
  } else {
    return false;
  }
}

function isAndroidBrowser() {
  if (Platform.OS == 'web') {
    return /android/.test( getUserAgent() );
  } else {
    return false;
  }
}

export function isMobileBrowser() {
  return isAndroidBrowser() || isIOSBrowser();
}
