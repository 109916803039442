import React from 'react';
import { Platform, Linking, YellowBox, View, Text, SafeAreaView} from 'react-native';
import firebase from './basics/firebase';
import { NativeNavigator, LoggedOutNativeNavigator, SetNameNativeNavigator, EnableNotifsNativeNavigator, navigateToNotifs, navigateToGroup, navigateToHome} from './basics/navigator';
import { WebNavigator, decodeUrl } from './basics/webnavigator';
import { Notifications } from 'expo';
import Logger from './basics/logging';
import { reloadIfVersionChanged, forceReload } from './basics/versioncheck';
import { isMobileBrowser } from './slowgroup/util';
import _ from 'lodash';
import { setCurrentUser, watchNetworkConnected, setCurrentUserName, watchCurrentUserName, getUserForId, watchNotifsSkipped, releaseWatchers, logMessage } from './slowgroup/data';
import { AppRedirectScreen } from './slowgroup/AppRedirect';
import { Button, NotifsBanner, LoadingScreen, OfflineWarning } from './slowgroup/ui';
import { SetNameScreen, isValidName } from './slowgroup/SetName';
import { SignInScreen } from './slowgroup/Signin';
import { checkIfNotifsGranted, refreshNotifToken, EnableNotifsScreen, watchNotifsGranted } from './slowgroup/NotifPermission';
import { loadPromptsCleared } from './slowgroup/HelpPrompt';

Logger.init();

// HACK: Ignore android warnings due to firebase
console.ignoredYellowBox = [
  'Setting a timer'
];
YellowBox.ignoreWarnings = [
  'Setting a timer'
]


export const UserStatus = {
  STARTING_UP: 'starting up',
  GETTING_NAME: 'getting name',
  LOGGED_OUT: 'logged out',
  LOGGING_IN: 'logging in',
  CHECKING_USER: 'checking user',
  LOADING_DATA: 'loading data',
  READY: 'ready',
}


/* global window */

function getScreen() {
  if (Platform.OS == 'web') {
    return window.location.pathname.slice(1).split('/')[0];
  } else {
    return null;
  }
}

function isPublicScreen(screen) {
  if (Platform.OS == 'web') {
    switch (screen) {
      case 'post': return true;
      case 'group': return true;
      default: return false;
    }
  } else {
    return false;
  }

}

export default class App extends React.Component {
  state = {user: null, name: null, debugMessage: null, errorMessage: null, gotAuth: false, 
      networkConnected: true, waitedForNetwork: false, notifsGranted: null, screen: null,
      notifsSkipped: null
    }
  
  userNameChanged(name) {
    this.setState({name});
    setCurrentUserName(name);
  }

  async authStateChanged(fbUser) {
    if (fbUser) {
      const user = await getUserForId(fbUser.uid);
      Logger.setUserContext({email: user.email, user: user});
      setCurrentUser(user, fbUser.uid);
      this.setState({user, gotAuth: true});
      watchCurrentUserName(this, name => this.userNameChanged(name));
      const notifsGranted = await checkIfNotifsGranted();
      if (notifsGranted) {
        refreshNotifToken();
      } 
      logMessage('authStateChanged - finished notif refresh');
      watchNotifsGranted(() => this.setState({notifsGranted: true}))
      watchNotifsSkipped(this, notifsSkipped => this.setState({notifsSkipped}));
      this.setState({notifsGranted});    
    } else {
      // console.log('logged out');
      this.setState({user: null, gotAuth: true});
      releaseWatchers(this);
    }
  }

  notificationReceived(notification) {
    if (notification && notification.origin == 'selected') {
      // this.navigateToNotif(notification);
      if (this.navigator) {
        navigateToHome(this.navigator);
      } else {
        this.notification = notification;
      }
    }
  }

  async componentDidMount() {
    firebase.auth().onAuthStateChanged(user => this.authStateChanged(user));
    if (Platform.OS != 'web') {
      Notifications.addListener(n => this.notificationReceived(n));
    }
    this.setState({screen: getScreen(), notifsGranted: await checkIfNotifsGranted()});
  }

  componentWillUnmount() {
    releaseWatchers(this);
  }
  
  async navigateToUrl(url = '') {
    const pathparts = url.split('/').filter(x=>x);
    if (pathparts.length >= 2) {
      const screen = pathparts[pathparts.length - 2];
      const param = pathparts[pathparts.length -1 ];
      // console.log('urlParts', {screen, param, pathparts, url});
      if (screen == 'group') {
        navigateToGroup({navigator: this.navigator, group: param});
      }
      if (screen == 'post') {
        navigateToGroup({navigator: this.navigator, group: param});
      }
    } else {
      // console.log('url has no path', {pathparts, url});
    }
  }

  async navigatorStarted(navigator) {
    // console.log('navigator Started');
    this.navigator = navigator;
    if (this.notification) {
      navigateToHome(this.navigator);
      // this.notificationReceived(this.notification);
      this.notification = null;
    }
    // console.log('get initial URL');
    const initialUrl = await Linking.getInitialURL();
    // console.log('addEventListener');
    Linking.addEventListener('url', ({url}) => {
      // console.log('new URL', url);
      this.navigateToUrl(url);
    })
    // console.log('initialURL', initialUrl);
    this.navigateToUrl(initialUrl);
  }

  renderNavigator() {
    if (Platform.OS == 'web') {
      return <WebNavigator onNavigate={screen => this.setState({screen})} />
    } else {
      const uriPrefix = /grouphut:\/\/|https:\/\/multitalk.net\/|https:\/\/talkful.org\//;
      return (
        <View style={{flex: 1}}>
          <NativeNavigator key='navigator' uriPrefix={uriPrefix} ref={navigator => this.navigatorStarted(navigator)}/>
          <NotifsBanner onClickNotif={notif => navigateToHome(this.navigator)} />
        </View>
      )
    }
  }

  render() {
    reloadIfVersionChanged();
    const {user, gotAuth, name, notifsGranted, screen, notifsSkipped} = this.state;

    // console.log('render with screen', {screen, gotAuth, isPublic: isPublicScreen(screen)});

    if (gotAuth && isPublicScreen(screen)) {
      return this.renderNavigator();
    }

    if (isMobileBrowser() && !isPublicScreen(screen) && screen) {
      return <AppRedirectScreen />
    }

    if (!gotAuth || (user && !name) || (user && notifsSkipped == null) || (gotAuth && name && notifsGranted == null)) {
      return (
        <View style={{flex: 1}}>
          <SafeAreaView>
            <OfflineWarning />
            <Text style={{marginTop: 100, textAlign: 'center'}}>Loading</Text>
          </SafeAreaView>
        </View>
      )
    } else if (!user) {
      if (Platform.OS == 'web') {
        const {screen, props} = decodeUrl();        
        return <SignInScreen email={props.email} code={props.code} screen={screen} />
      } else {
        return <LoggedOutNativeNavigator />
      }
    } else if (name && !isValidName(name)) {
      if (Platform.OS == 'web') {
        return <SetNameScreen />
      } else {
        return <SetNameNativeNavigator />
      }
    } else if (notifsGranted == false && Platform.OS != 'web' && !notifsSkipped) {
      // return <EnableNotifsScreen />
      return <EnableNotifsNativeNavigator />
    } else {
      return this.renderNavigator();
    }
  }
}
