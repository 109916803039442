import React from 'react';
import {Platform, TextInput, StyleSheet, View, TouchableOpacity, Text} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Button } from '../slowgroup/ui';
import { FixedTouchable } from './basics';
import { PublicationToggle } from '../slowgroup/Post';
import { HelpUserChoosePosts } from '../slowgroup/HelpPrompt';



export class MessageEntryBox extends React.Component {
  state = {text: null, textKey: 0, focussed: false}

  handleKeyDown(e) {
    if (Platform.OS == 'web' && e.nativeEvent.key == 'Enter') {
      if (!e.nativeEvent.shiftKey && !e.nativeEvent.ctrlKey && !e.nativeEvent.metaKey){
        this.submitText();    
      }
    }
  }

  submitText() {
    const {onTextEntered} = this.props;
    const {textKey, text} = this.state;
    onTextEntered(text); 
    // onCancelReply();
    if (Platform.OS == 'web') {
      this.setState({text:null, focussed: false})
      this.textinput.clear();
    } else {
      this.setState({textKey:textKey+1, text: null, focussed: false})    
    }
  }

  changeText(text) {
    if (Platform.OS == 'web' && text == '\n') {
      this.setState({text: null});
      this.textinput.clear();      
    } else {
      this.setState({text});
    }
  }

  render (){
    const {style, help, userName, placeholder, user} = this.props;
    const {text, focussed} = this.state;

    // console.log('focussed', focussed);

    return (
      <View>
        {/* {focussed ? null :
          <HelpUserChoosePosts help={help} userName={userName} />
        } */}
        <View style={style || {backgroundColor: 'white', borderTopColor: '#ddd', borderTopWidth: StyleSheet.hairlineWidth}} >
          <View style={styles.horizBox}>
            {/* {focussed ? null :
              <Button navAction='choosePosts' navProps={{user}} style={{marginLeft: 8}}>Choose Posts</Button>
            } */}
            <TextInput key={this.state.textKey}
              ref={r => this.textinput = r}
              style={[styles.textInput, {height: focussed ? 100 : null, flexShrink: 0}]}
              underlineColorAndroid='transparent'
              multiline={true}
              blurOnSubmit={false}
              onFocus={() => this.setState({focussed:true})}
              onBlur={() => this.setState({focussed:false})}
              onChangeText={text=>this.changeText(text)}
              placeholder={placeholder} 
              onKeyPress={e => this.handleKeyDown(e)}
            />
            <TouchableOpacity onPress={() => this.submitText()} >
              <Ionicons style={styles.sendIcon} name='md-send' size={30} color='#0084ff' />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  horizBox: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  sendIcon: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
    // paddingTop: 10,
    borderColor: '#bbb',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 8,
    marginRight: 0,
    fontSize: 16,
    backgroundColor: "#eee",
    textAlignVertical: 'center'
  },
  fakeInput: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderColor: '#bbb',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 8,
    marginRight: 0,
    backgroundColor: "#eee"
  },
  fakeText: {
    fontSize: 16,
    color: 'gray',
  },
  buttonHolder: {
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  doneButton: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginLeft: 8,
    backgroundColor: '#eee',
    borderColor: '#bbb',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 16,
    marginRight: 4,
  }
})

export default MessageEntryBox;
