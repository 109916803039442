import React from 'react';
import { View, Text, KeyboardAvoidingView, TextInput } from 'react-native';
import { ToggleGroup, ToggleButton } from '../components/Toggle';
import { Button } from './ui';
import { submitAbuseReport } from './data';
import { TextBox } from '../components/testable';

const abuseOptions = [
  'Uncivil Behavior',
  'Objectionable Content',
  'Impersonation',
  'Copyright Violation',
  'Other'
]

function getThingType(props) {
  if (props.reply) {
    return 'reply';
  } else if (props.post) {
    return 'post';
  } else if (props.user) {
    return 'user';
  } else if (props.group) {
    return 'group';
  } else {
    return 'thing';
  }
}

export class ReportAbuseScreen extends React.Component {
  state = {options: {}, extraInfo: '', submitted: false}

  render() {
    const {user, group, post} = this.props;
    const {options, extraInfo} = this.state;
    // const thingType = user ? 'user' : (group ? 'group' : (post ? 'post' : 'thing'));
    const thingType = getThingType(this.props);
    if (this.state.submitted) {
      return (
        <View>
          <Text style={{fontSize: 18, margin: 8}}>Thank you for your report</Text>
          <Text style={{margin: 8, paddingTop: 16}}>
              {"We'll evaluate your report within 24 hours, "}
              and suspend this user if we find that they have violated our policies.
          </Text>
        </View>
      )
    } else {
      return (
        <KeyboardAvoidingView
          behavior='position'  
          keyboardVerticalOffset={80} >
            <Text style={{fontSize: 18, margin: 8}}>What is bad about this {thingType}?</Text>
            <ToggleGroup toggledItems={this.state.options} onToggledChanged={(options) => this.setState({options})} >
              {abuseOptions.map((option) =>
                <ToggleButton part={option} key={option} value={option} text={option} color={'hsl(0,0%,20%)'} />
              )}
            </ToggleGroup>
            <TextBox part='extra'
              style={{height: 100, backgroundColor: 'white', margin: 8, padding: 16, borderBottomColor: '#bbb', borderBottomWidth: 1}}
              multiline={true} 
              placeholder='Is there anything else you would like to tell us?'
              value={this.state.extraInfo} 
              onChangeText={(text) => this.setState({extraInfo: text})} />
            <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
              <Button part='submit' onPress={()=>{
                this.setState({submitted: true});
                submitAbuseReport({user, group, post, options, extraInfo});
              }}>
                Submit
              </Button>

            </View>
        </KeyboardAvoidingView>
      )
    }
  }
}
