import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { KeyboardSafeView } from '../components/KeyboardSafeView';
import { Button, Agreements, WideButton, baseColor } from './ui';
import { FixedTouchable } from '../components/basics';
import { signOut } from '../basics/fbutil';
import { setMyName } from './data';
import { ScreenContentScroll } from './ui';
import { TextBox } from '../components/testable';

const nameRegex = /^\s*([A-Za-z]{1,}([.,] |[-']| ))+[A-Za-z]+\.?\s*$/

export function isValidName(name) {
  return name.match(nameRegex);
}

export class SetNameScreen extends React.Component {
  state = {name: null, nameError: false}

  async onSignupPressed() {
    const {name} = this.state;
    if (name && isValidName(name)) {
      await setMyName({name});
    } else {
      this.setState({nameError: true});
    }
  }

  render () {
    const {name, nameError} = this.state;
    return (
      <ScreenContentScroll>
        <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
          <View>
            <View style={{marginTop: 32, maxWidth:400, backgroundColor: 'white', borderColor: '#ddd', padding: 16, borderWidth: 1, flex: 1}}>
              <Text style={{fontSize: 18, marginBottom: 16}}>What is your name?</Text>
              <TextBox part='input' style={{padding: 8, marginVertical: 8, borderColor: '#ddd', borderWidth: 1}} 
                  onChangeText={name => this.setState({name, nameError: false})}
                  placeholder='Full name' />
              {!nameError ? null : 
                <Text style={{color: 'red', fontSize: 12, marginBottom: 8}}>Please enter your full real name.</Text>
              }
              {/* <TextInput style={{padding: 8, marginVertical: 8, borderColor: '#ddd', borderWidth: 1}} 
                  onChangeText={bio => this.setState({bio});
                  placeholder='One line bio' /> */}
              <WideButton part='submit' onPress={()=>this.onSignupPressed()}   
                  style={{marginTop: 8, marginBottom: 16, marginHorizontal: 0}}>
                Set Name
              </WideButton>
              {/* <Agreements /> */}
            </View>
            <FixedTouchable part='signout' onPress={()=>signOut()} >
              <View style={{backgroundColor:'white', padding: 16, marginTop: 32, flex: 1, maxWidth: 400}}>
                <Text>Want to log in with a different email address?
                  <Text style={{fontWeight:'bold', color: baseColor}}> Sign out</Text>
                </Text>
              </View>
            </FixedTouchable>
          </View>
        </View>
      </ScreenContentScroll>
    )
  }
}