import React from 'react';
import { Text, View, TextInput } from 'react-native';
import { Button, ScreenContentScroll, LoadingScreen } from './ui';
import { plural, getFirstName } from './util';
import { addPost, askToJoinGroup, getCurrentUserName, watchGroupInfo, markGroupRead } from './data';

export class NonMemberGroupScreen extends React.Component {
  state = {text: null, groupInfo: null}

  componentDidMount() {
    const {group} = this.props;
    watchGroupInfo(this, group, groupInfo => this.setState({groupInfo: groupInfo || {}}));
  }

  async requestJoin() {
    const {group, groupInfo, navigation} = this.props;
    const {text} = this.state;
    // const name = await getCurrentUserName();
    await askToJoinGroup({group, groupInfo, text: text || ''});
    navigation.goBack()
  }

  render () {
    const {group, requested} = this.props;
    const {groupInfo} = this.state;

    if (groupInfo == null) {
      return <LoadingScreen />
    }
    
    // const memberList = Object.keys(users);
    return (
      <ScreenContentScroll>
        <View style={{backgroundColor: 'white', margin: 4}}>
          <View style={{padding: 10}}>
            <Text style={{fontSize: 18}}>{groupInfo.name}</Text>
            {/* <Text>{memberList.length} {plural(memberList, 'member')}</Text> */}
            <Text style={{marginTop: 8, color: '#666'}}>{groupInfo.intro}</Text>
          </View>
        {requested ? 
          <Text style={{fontWeight: 'bold'}}>You asked to join this group</Text>
        : 
          <View style={{marginTop: 8, padding: 10, borderTopWidth: 1, borderTopColor: '#ddd'}}>
            <TextInput part='intro' multiline onChangeText={text => this.setState({text})}
              style={{height: 100, backgroundColor: '#eee', borderColor: '#ddd', borderRadius: 16,
                paddingHorizontal: 12, paddingVertical: 10, margin: 8, borderWidth: 1}}
              placeholder='Optional: Introduce yourself to other members'
            />
            <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginRight: 10}}>
              <Button part='submit' onPress={()=>this.requestJoin()}>Ask to Join Group</Button>
            </View>
          </View>
        }
        </View>
      </ScreenContentScroll>
    )
  }
}