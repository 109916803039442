import React from 'react';
import { View, Text, TextInput, Platform} from 'react-native';
import { KeyboardSafeView } from '../components/KeyboardSafeView';
import { Button, HelpBubble, linkColor, LoadingScreen } from './ui';
import { addPost, getSingleGroup, createGroup, editGroup, chooseGroupId, watchGroupInfo } from './data';
import { FixedTouchable } from '../components/basics';
import { TextBox } from '../components/testable';
import { PublicationToggle } from './Post';


export class NewGroupScreen extends React.Component {
  state = {name: null, intro: null, id: null, idedited: false, isPublic: false}

  async createGroup() {
    const {navigation, group} = this.props;
    const {name,intro, isPublic} = this.state;
    if (group) {
      await editGroup({group, name, intro, isPublic});
    } else {
      await createGroup({name, intro, isPublic});
    }
    navigation.goBack();
  }

  async componentDidMount() {
    const {group} = this.props;
    if (group) {
      watchGroupInfo(this, group, groupInfo => 
        this.setState({name: groupInfo.name, intro: groupInfo.intro, isPublic: groupInfo.isPublic}));
    } else {
      this.setState({name: '', intro: '', isPublic: false});      
    }
  }

  render() {
    const {group} = this.props;
    const {name, intro, isPublic} = this.state;
    if (name == null) {
      return <LoadingScreen />
    }
    return (
      <KeyboardSafeView>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
          <View style={{flex: 1, backgroundColor: 'white', maxWidth: 500, alignItems: 'stretch'}}>
            <View style={{flexDirection: 'row', marginTop: 8, marginRight: 8, alignItems: 'center', justifyContent: 'space-between'}}>
              <PublicationToggle global allowed={isPublic} onAllowed={isPublic => this.setState({isPublic})} />
              <Button part='submit' onPress={()=>this.createGroup()} disabled={!name}>
                {group ? 'Save Changes' : 'Create Group'}
              </Button>
            </View>
            <TextBox part='name' autoFocus placeholder='Enter a name for your group'
              onChangeText={name=>this.setState({name})}
              value={name}
              style={{fontSize: 16, backgroundColor: '#eee', borderColor: '#ddd', 
                borderRadius: 16, 
                paddingHorizontal: 12, paddingVertical: 8, marginHorizontal: 10, 
                marginTop: 16, borderWidth: 1}} 
            />
            <TextBox part='description' multiline placeholder='Enter a description for your group' 
              onChangeText={intro=>this.setState({intro})}
              value={intro}
              style={{height: 100, backgroundColor: '#eee', borderColor: '#ddd', 
                borderRadius: 16, 
                paddingHorizontal: 12, paddingVertical: 10, margin: 10, borderWidth: 1}} 
            />
            <HelpBubble style={{marginTop: 16}}>
              You will be able to invite other users to join your group after you create it.
            </HelpBubble>
          </View>
        </View>
      </KeyboardSafeView>
    )
  }
}