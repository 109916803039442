import React from 'react';
import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import {Text, View, Platform, Linking, StyleSheet} from 'react-native';
import { FixedTouchable, NavClickable } from '../components/basics';
import { getNotifToken, setNotifToken, markNotifsSkipped, logMessage } from './data';
import IonIcons from 'react-native-vector-icons/Ionicons';
import _ from 'lodash';
import { Button, ScreenContentScroll } from './ui';
import { isTest } from '../components/testable';

export async function getNotifPermissionStatusAsync() {
  return await Permissions.getAsync(Permissions.NOTIFICATIONS);
}

export async function getExpoNotifToken() {
  return await Notifications.getExpoPushTokenAsync();
}

export async function requestNotifPermission() {
  return await Permissions.askAsync(Permissions.NOTIFICATIONS);
}

export async function checkIfNotifsGranted() {
  logMessage('checkIfNotifsGranted');
  // return false;
  if (Platform.OS == 'web') {
    logMessage('web - skipping');
    return true;
  }
  
  const notifStatus = await getNotifPermissionStatusAsync();
  // console.log('notif status: ', notifStatus);
  if (_.get(notifStatus, 'status') != 'granted') {
    logMessage('notifs NOT Granted');
    return false;
  } else {    
    logMessage('notifs Granted');
    return true;
  }   
}

export async function refreshNotifToken() {
  logMessage('refreshNotifToken');
  if (Platform.OS == 'web') {
    logMessage('platform is web - skipping');
    return true;
  }

  try {
    const notifToken = await getNotifToken();
    logMessage('notifToken', {notifToken});
    const expoNotifToken = await getExpoNotifToken();
    logMessage('token: ', {notifToken, expoNotifToken});
    if (notifToken != expoNotifToken) {
      logMessage('stored token different to expo token, updating');
      await setNotifToken(expoNotifToken);
      logMessage('token update DONE');
    }
  } catch (e) {
    logMessage('error while refreshing notif token');
    logMessage('notif error', e);
  }
  logMessage('finishing refresh token');
}

var notifEnabledHooks = [];
export function watchNotifsGranted(func) {
  notifEnabledHooks.push(func);
}
function triggerNotifsGranted() {
  // console.log('trigger', notifEnabledHooks)
  notifEnabledHooks.forEach(func => {
    func()
  })
}

export class EnableNotifsBanner extends React.Component {
  state = {notifsEnabled: true, denied: false}
  async componentDidMount() {
    this.setState({notifsEnabled: await checkIfNotifsGranted()});
  }

  async setupNotifToken() {
    logMessage('setupNotifToken');
    const notifStatus = await requestNotifPermission();
    logMessage('result:', notifStatus);
    if (_.get(notifStatus, 'status') == 'granted') {
      await refreshNotifToken();
      this.setState({notifsEnabled: true});
      logMessage('enabled');
    } else {
      this.setState({denied: true});
      logMessage('denied');
    }
  }

  async pollForNotifsGranted() {
    const granted = await checkIfNotifsGranted();
    if (granted) {
      this.setState({notifsEnabled: true});
    } else {
      if (!isTest) {
        setTimeout(() => this.pollForNotifsGranted(), 500);
      }
    }
  }


  render () {
    const {notifsEnabled, denied} = this.state;
    if (notifsEnabled) return null;
    return (
      <FixedTouchable onPress={()=>this.setupNotifToken()}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', 
            backgroundColor: 'white', borderColor: '#ddd', borderWidth: StyleSheet.hairlineWidth,  padding: 8, margin: 4}}>
          <IonIcons name='ios-notifications' size={30}/>
          <Text style={{marginHorizontal: 8, flex: 1}}>
            <Text style={{fontWeight: 'bold'}}>Enable notifications</Text> to know when someone messages you.
          </Text>
          {denied 
          ? <Button onPress={()=>{Linking.openURL('app-settings://'); this.pollForNotifsGranted()}}
              >Open Settings
            </Button>
          : <Button onPress={()=>this.setupNotifToken()}>Enable</Button>
          }
        </View>
      </FixedTouchable>
    )
  }
}

export class EnableNotifsScreen extends React.Component {
  state = {denied: false}
  async setupNotifToken() {
    const notifStatus = await requestNotifPermission();
    if (_.get(notifStatus, 'status') == 'granted') {
      await refreshNotifToken();
      triggerNotifsGranted();
    } else {
      // console.log('notifs denied');
      this.setState({denied: true});
    }
  }

  async componentDidMount() {
    await this.pollForNotifsGranted();
  }

  async pollForNotifsGranted() {
    // console.log('pollNotifs');
    const granted = await checkIfNotifsGranted();
    // console.log('granted: ', granted);
    if (granted) {
      triggerNotifsGranted();
    } else {
      if (!isTest) {
        setTimeout(() => this.pollForNotifsGranted(), 500);
      }
    }
  }

  render() {
    const {denied} = this.state;
    return (
      <ScreenContentScroll statusdark innerStyle={{backgroundColor: 'white'}}>
          <View style={{backgroundColor: 'white', padding: 16, marginTop: 32}}>
            <Text style={{textAlign: 'center', fontWeight: 'bold', fontSize: 18}}>Almost Finished</Text>          

            <Text style={{paddingVertical: 16, textAlign: 'center', color: '#666'}}>
              Enable notifications so we can tell you when someone sends you a message. 
              We promise not to spam you with any other notifications.
            </Text>

            {!denied ? null : 
              <Text style={{paddingVertical: 10, textAlign: 'center', color: '#666'}}>
                You previously denied notification permission, so you will need to enable
                notifications in the settings app
              </Text>
            }

            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}} >
              {denied ? 
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  <Button onPress={()=>Linking.openURL('app-settings://')}>Open Settings</Button>
                </View>        
              :
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  <Button part='enable' onPress={()=>this.setupNotifToken()}>Enable Notifications</Button>
                </View>
              }
              <FixedTouchable onPress={()=>markNotifsSkipped()}>
                <Text style={{color: '#666'}}>Maybe Later</Text>
              </FixedTouchable>
            </View>
        </View>
      </ScreenContentScroll>
    )
  }
}


