import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { NavClickable, FixedTouchable } from '../components/basics';
import { Button, baseColor, darkBaseColor } from './ui';
import { watchHelpCleared, markHelpCleared } from './data';
import Svg, { Circle, Rect, Polygon } from 'react-native-svg';
import Entypo from 'react-native-vector-icons/Entypo';
import _ from 'lodash';

export function chooseFirstHelpPrompt(cleared, prompts) {
  // console.log('helpCleared', cleared, prompts);
  const first = _.find(prompts, p => p && !cleared[p]);
  // const first = _.find(prompts, p => {
  //   console.log('p', p, cleared[p]);
  //   return !cleared[p]
  // });

  // console.log('first', first);
  return first;
}

function TopArrow({style}){
  return (
    <Svg height={10} width={20} viewBox="0 0 20 10" style={style}>
      <Polygon
        points="0,10 10,0 20,10"
        fill={baseColor}
        // stroke="purple"
        // strokeWidth="1"
      />
    </Svg>
  )
}

function BottomArrow({style}){
  return (
    <Svg height={10} width={20} viewBox="0 0 20 10" style={style}>
      <Polygon
        points="0,0 10,10 20,0"
        fill={baseColor}
        // stroke="purple"
        // strokeWidth="1"
      />
    </Svg>
  )
}


export function HelpPrompt({id, arrow, help, title, children, post, style}) {
  if (id != help) {
    return null;
  }
  return (
    <View>
      {arrow != 'top' ? null : 
        // <Entypo name='triangle-up' color={baseColor} size={40} />         
        <TopArrow style={{marginLeft: 50}} />
      }
      {arrow != 'topMiddle' ? null : 
        // <Entypo name='triangle-up' color={baseColor} size={40} />         
        <TopArrow style={{marginHorizontal: 16, alignSelf: 'center'}} />
      }
      {arrow != 'topRight' ? null : 
        // <Entypo name='triangle-up' color={baseColor} size={40} />         
        <TopArrow style={{marginRight: 16, alignSelf: 'flex-end'}} />
      }
      <View style={{backgroundColor: baseColor, borderRadius: 4, paddingHorizontal: 10, paddingVertical: 10, marginHorizontal: 4}}>
        {!title ? null : 
          <Text style={{fontWeight: 'bold', color: 'white', fontSize: 16}}>
            {title}
          </Text>
        }
        {children}
        <View style={{marginTop: 10, flexDirection: 'row', alignItems: 'center', justifyContent: post ? 'space-between' : 'flex-end'}}>
          {!post ? null : 
            <NavClickable navAction='post' navProps={{post, author: 'rob'}}>
              <View style={{borderColor: 'white', borderWidth: StyleSheet.hairlineWidth, padding: 8, borderRadius: 4}}>  
                <Text style={{color: 'white', marginHorizontal: 8}}>
                  Learn More
                </Text>
              </View>
            </NavClickable>
          }
          <FixedTouchable onPress={() => markHelpCleared(id)}>
            <View style={{backgroundColor: darkBaseColor, padding: 10, borderRadius: 4}}>
              <Text style={{color: 'white'}}>Got it</Text>
            </View>
          </FixedTouchable>
        </View>
      </View>
      {arrow != 'bottomLeft' ? null : 
        // <Entypo name='triangle-up' color={baseColor} size={40} />         
        <BottomArrow style={{marginLeft: 50}} />
      }

    </View>
  )
}

export const Help = {
  Welcome: 'welcome',
  SearchPeople: 'searchPeople',
  WritePost: 'writePost',
  UserChoosePosts: 'userChoosePosts',
  ReplyPrivately: 'replyPrivately',
  FollowPost: 'followPost',
  PublishedHere: 'publishedHere',
}

const styles = StyleSheet.create({
  text: {
    color: 'white',
    fontSize: 14,
    marginTop: 8,
  }
})

export function HelpSearchPeople({help}) {
  return (
    <HelpPrompt help={help} arrow='top' title='Search for Friends' id='searchPeople' >
      <Text style={styles.text} >
        Did a friend suggest you use Talkful? You can use the search box to find them.
      </Text>
    </HelpPrompt>
  )
}

export const HelpWelcome = ({help}) =>
  <HelpPrompt help={help} title="We Connect Best in Private" id='welcome' post='-LhcSjseVwy0ly-D92ax'>
    {/* <Text style={styles.text}>
      Wecome to Talkful!
    </Text> */}
    <Text style={styles.text}>
      Talkful is a social platform that is designed to bring people together rather than make 
      them outraged.
    </Text>
    <Text style={styles.text}>
      Unlike other platforms, you can only reply to someone privately. 
      If they like what you said, then they can publish it.
      If they disagree then you can resolve your differences privately.
    </Text>
  </HelpPrompt>

export const HelpUserChoosePosts = ({help, userName}) => 
  <HelpPrompt help={help} arrow='bottomLeft' title={'Choose posts to share with ' + userName} id='userChoosePosts' >
    <Text style={styles.text}>
      Click this button to choose posts to share with {userName}.
    </Text>
  </HelpPrompt>

export const HelpWritePost = ({help}) => 
  <HelpPrompt help={help} arrow='topRight' title='Write a Post' id='writePost'>
    <Text style={styles.text}>
      This can be a thought, experience, question, thing you read, or anything else you might want to talk with others about.      
    </Text>
    <Text style={styles.text}>
      Once {"you've"} written a post, {"you'll"} be able to choose who to share it with.
    </Text>
  </HelpPrompt>

export const HelpReplyPrivately = ({help, userName}) => 
  <HelpPrompt help={help} arrow='topMiddle' title='Reply Privately' id='replyPrivately'>
    <Text style={styles.text}>
      Only {userName} will be able to see your reply, 
      but they can publish replies they like to be seen by everyone who can
      see the post.
    </Text>
  </HelpPrompt>

export const HelpFollowPost = ({help}) => 
  <HelpPrompt help={help} arrow='topRight' title='Follow a Post' id='followPost'>
    <Text style={styles.text}>
      Click here to follow this post.
      If you follow a post then Talkful will let you know if any new replies get published.
    </Text>
    <Text style={styles.text}>
      You automatically follow posts you wrote on that your replied to.
    </Text>
  </HelpPrompt>


export const HelpPublishedAppearHere = ({help}) =>
  <HelpPrompt help={help} title='Published Replies will Appear Here' id='publishedHere'>
    <Text style={styles.text}>
      You can only reply to a post privately, but the post author can publish replies to 
      appear here if they like them.
    </Text>
  </HelpPrompt>
